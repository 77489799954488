import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { ShortRadio } from '@app/core/models/Radio';
import { Observable } from 'rxjs';
import { UploadState } from '@app/core/states/upload.state';
import { slideInOutBlock } from '@radioking/ui/animations';
import { TryActionUnlessPanelIsBlocking } from '@radioking/shared/pannel-manager';

@Component({
  selector: 'rk-change-radio-modal',
  templateUrl: './change-radio-modal.component.html',
  styleUrls: ['./change-radio-modal.component.scss'],
  animations: [slideInOutBlock],
})
export class ChangeRadioModalComponent implements OnInit {
  @Select(RadioState.radios)
  radios$: Observable<ShortRadio[]>;

  @Select(RadioState.currentRadioId)
  currentRadioId$: Observable<number>;

  @Select(UploadState.remainingFiles)
  filesUploading$: Observable<boolean>;

  constructor(
    public readonly dialogRef: MatDialogRef<ChangeRadioModalComponent>,
    private readonly store: Store,
  ) {}

  ngOnInit() {}

  goToRadio(radioId: number) {
    this.store.dispatch(
      new TryActionUnlessPanelIsBlocking(new SwitchToRadioRequest(radioId)),
    );
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
