import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeGlueComponent } from './iframe-glue/iframe-glue.component';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [IframeGlueComponent],
  exports: [IframeGlueComponent],
})
export class HotglueModule {}
