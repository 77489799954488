<rk-input-with-suggestions
  [label]="label"
  [debounceTime]="0"
  [canAddNew]="canAddNew"
  [minLengthTrigger]="0"
  [dataFetched]="filteredData"
  (change)="searchData($event)"
  (submit)="setData($event)"
  [currentValue]="value"
  [addNewCaseSensitive]="false"
  [renderLineTemplate]="dataIncludedForWarning ? renderItemTmpl : null"
  [shouldClearOnSubmit]="false"
  [inModal]="modal"
  [hintQuestionMark]="hintQuestionMark"
></rk-input-with-suggestions>

<ng-template let-playlist="item" #renderItemTmpl>
  <div class="playlist-line">
    <div class="playlist-color">
      <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            transform="translate(-10.000000, -42.000000)"
            stroke="#FF6B81"
            stroke-width="2"
          >
            <g transform="translate(0.000000, 26.000000)">
              <g>
                <g>
                  <g transform="translate(0.000000, 2.000000)">
                    <g transform="translate(10.000000, 10.000000)">
                      <g>
                        <circle
                          cx="5"
                          cy="9"
                          r="4"
                          [attr.stroke]="playlist.color"
                        ></circle>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <span class="title">{{ playlist.name }}</span>
    <span
      [style.left.px]="dropdownWidth - 30"
      class="right"
      *ngIf="shouldShowWarning(playlist.id)"
      [matTooltip]="'GLOBAL.TOOLTIP.DANGER' | translate"
      [matTooltipPosition]="'above'"
    >
      <mat-icon>error_outline</mat-icon>
    </span>
  </div>
</ng-template>
