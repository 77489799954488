<div class="root" [class.wide]="wide">
  <div class="header">
    <span data-cy="modal-title">{{ title || ('MODAL.DEFAULT.TITLE' | translate) }}</span>
    <div class="button-container">
      <rk-icon-button
        faIcon="fa-times"
        (click)="onAbort()"
        [class.hide-button]="!showCancelButon()"
      ></rk-icon-button>
    </div>
  </div>
  <div class="body"><ng-content></ng-content></div>
  <div class="error-wrapper" *ngIf="errorMessage">
    <mat-icon fontSet="fa" fontIcon="fa-exclamation-circle"></mat-icon>
    <span>{{ errorMessage || ('MODAL.DEFAULT.ERROR_MESSAGE' | translate) }}}</span>
  </div>
  <div class="footer" *ngIf="showSubmitBlock">
    <rk-button
      data-cy="modal-cancel-button"
      *ngIf="!loading"
      (click)="onCancel()"
      [btnStyle]="'flat'"
      [label]="cancelText || ('MODAL.DEFAULT.CANCEL' | translate)"
    ></rk-button>
    <rk-loader [isLoading]="loading" [size]="25"></rk-loader>
    <rk-button
      data-cy="modal-submit-button"
      [disabled]="loading || isSubmitDisabled"
      (click)="!loading ? onSubmit() : null"
      [label]="submitText || ('MODAL.DEFAULT.SUBMIT' | translate)"
    ></rk-button>
  </div>
</div>
