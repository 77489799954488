import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UploadFileState, UploadTrack } from '@app/core/states/upload.state';
import { formatBytesToMB } from '@app/shared/utils';
import { Store } from '@ngxs/store';
import {
  CancelUploadFile,
  RemoveUploadFile,
  RetryUploadFile,
} from '@app/core/states/upload.actions';
import { BacsState } from '@app/library/states/bacs.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-header-upload-line',
  templateUrl: './header-upload-line.component.html',
  styleUrls: ['./header-upload-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderUploadLineComponent implements OnInit {
  @Input()
  upload: UploadTrack;

  constructor(private readonly store: Store) {}

  ngOnInit() {}

  getTranslatedBytes(bytes: number): {} {
    return formatBytesToMB(bytes);
  }

  isError() {
    return this.upload.state === UploadFileState.ERROR;
  }

  isCanceled() {
    return this.upload.state === UploadFileState.CANCELED;
  }

  isProcessing() {
    return this.upload.state === UploadFileState.PROCESSING;
  }

  cancelUpload() {
    if (
      this.upload.state === UploadFileState.READY ||
      this.upload.state === UploadFileState.UPLOADING
    ) {
      this.store.dispatch(new CancelUploadFile(this.upload.id));
    } else {
      this.store.dispatch(new RemoveUploadFile(this.upload.id));
    }
  }

  retryUpload() {
    this.store.dispatch(
      new RetryUploadFile(
        this.upload.file,
        this.upload.box,
        this.upload.id,
        this.upload.playlist,
      ),
    );
  }

  getDuplicateBacName(id: UploadTrack): Observable<string> {
    const targetBac = this.store.selectSnapshot(BacsState.bacById)(id.duplicateBox);
    return targetBac ? targetBac.translated$ : null;
  }
}
