import { Track } from '@app/core/models/Track';
import { Criterion } from '@app/library/models/criterias.model';
import { AbstractControl, ValidatorFn } from '@angular/forms';
export const uniqFilter = (x: any, i: number, a: any[]) => a.indexOf(x) === i;

export interface TimeSplited {
  hours: number;
  minutes: number;
  seconds: number;
}

export const exportSecMin = (value: number): TimeSplited => {
  const rounded = Math.floor(value);
  let totalSeconds = rounded;

  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return {
    hours,
    minutes,
    seconds,
  };
};

export const formatBytesToMB = (bytes: number): number => {
  const toMo = parseFloat((bytes / 1048576).toFixed(1));
  return toMo;
};

export function getEllipsisTitle(track: Track): string {
  const fullTitle = track.title + (track.artist ? ` - ${track.artist}` : '');
  const shouldTruncFileName = fullTitle.length > 140;
  return fullTitle.substr(0, 140) + (shouldTruncFileName ? '...' : '');
}

export function hexToRGBA(hex, opacity): string {
  if (!hex) {
    return;
  }
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function bacNameFromDbName(name: string): string {
  switch (name) {
    case '__MUSIC__':
      return 'music';
    case '__IDENTIFICATION__':
      return 'identification';
    case '__PODCAST__':
      return 'podcast';
    case '__AD__':
      return 'ad';
    case '__CHRONIC__':
      return 'chronic';
    case '__DEDICATION__':
      return 'dedication';
  }
}

/**
 * Undefined-safe function to access the property given by path parameter
 * @param object The object to read from
 * @param path The path to the property
 */
export function elvis(object: any, path: string): any | undefined {
  return path
    ? path.split('.').reduce((value, key) => value && value[key], object)
    : object;
}
