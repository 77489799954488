import { Injectable } from '@angular/core';
import { Intercom } from 'ng-intercom';

@Injectable({
  providedIn: 'root',
})
export class IntercomHiderService {
  intercomContainer: HTMLElement;

  isIntercomShown = true;

  constructor(private readonly intercom: Intercom) {}

  initService() {
    if (this.intercomContainer) {
      return;
    }
    this.intercomContainer = document.querySelector(
      '#intercom-container .intercom-launcher-frame',
    );
    if (this.intercomContainer) {
      this.intercomContainer.style['transform-origin'] = 'center';
      this.intercomContainer.style.transition = 'all .2s ease-in-out';
    }
  }

  hideIntercom() {
    if (!this.isIntercomShown) {
      return;
    }
    this.makeSureIsInit();
    if (!this.intercomContainer) {
      return;
    }
    this.intercomContainer.style.transform = 'scale(0)';
    this.intercom.hide();
    const intercomDiscovery: HTMLElement = document.querySelector(
      '#intercom-container .intercom-launcher-discovery-frame',
    );
    if (intercomDiscovery) {
      intercomDiscovery.style.display = 'none';
    }
    const intercomBadge: HTMLElement = document.querySelector(
      '#intercom-container .intercom-launcher-badge-frame',
    );
    if (intercomBadge) {
      intercomBadge.style.display = 'none';
    }

    this.isIntercomShown = false;
  }

  showIntercom() {
    if (this.isIntercomShown) {
      return;
    }
    this.makeSureIsInit();
    if (this.intercomContainer) {
      this.intercomContainer.style.transform = 'none';
      const intercomDiscovery: HTMLElement = document.querySelector(
        '#intercom-container .intercom-launcher-discovery-frame',
      );
      if (intercomDiscovery) {
        intercomDiscovery.style.display = 'block';
      }
      const intercomBadge: HTMLElement = document.querySelector(
        '#intercom-container .intercom-launcher-badge-frame',
      );
      if (intercomBadge) {
        intercomBadge.style.display = 'block';
      }
    }
    this.isIntercomShown = true;
  }

  makeSureIsInit() {
    if (!this.intercomContainer) {
      this.initService();
      if (!this.intercomContainer) {
        return;
      }
    }
  }
}
