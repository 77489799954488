import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rk-panel-outside-content',
  template: `
    <ng-content></ng-content>
  `,
})
export class PanelOutsideContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
