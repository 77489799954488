import { ShoutOut } from '@app/core/models/ShoutOut';

const tag = '[ShoutOuts]';

export class ShoutOutsRequest {
  static readonly type = `${tag} request shoutouts config`;
  constructor() {}
}

export class ShoutOutsSuccess {
  static readonly type = `${tag} success shoutouts config`;
  constructor(public shoutOuts: ShoutOut) {}
}

export class ShoutOutsFailure {
  static readonly type = `${tag} error shoutouts config`;
  constructor(public error: Error) {}
}

export class EnableShoutOutRequest {
  static readonly type = `${tag} request enable shoutouts`;
  constructor() {}
}

export class EnableShoutOutSuccess {
  static readonly type = `${tag} success enable shoutouts`;
  constructor(public shoutOuts: ShoutOut) {}
}

export class EnableShoutOutsFailure {
  static readonly type = `${tag} error enable shoutouts`;
  constructor(public error: Error) {}
}

export class DisableShoutOutRequest {
  static readonly type = `${tag} request disable shoutouts`;
  constructor() {}
}

export class DisableShoutOutSuccess {
  static readonly type = `${tag} success disable shoutouts`;
  constructor(public shoutOuts: ShoutOut) {}
}

export class DisableShoutOutsFailure {
  static readonly type = `${tag} error disable shoutouts`;
  constructor(public error: Error) {}
}

export class UpdateShoutOutRequest {
  static readonly type = `${tag} request update shoutouts config`;
  constructor(public payload: ShoutOut, public showModal = true) {}
}

export class UpdateShoutOutsSuccess {
  static readonly type = `${tag} success update shoutouts config`;
  constructor(public shoutOuts: ShoutOut, public showModal = true) {}
}

export class UpdateShoutOutsFailure {
  static readonly type = `${tag} error update shoutouts config`;
  constructor(public error: Error) {}
}
