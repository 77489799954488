import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Genre } from '@app/core/models/Genre';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { Timezone } from '@app/core/models/Timezone';
import { Country } from '@app/core/models/Country';

export interface ApiGenre {
  idgenre: number;
  name: string;
  slug: string;
  parent_id: number;
}

export interface ApiTimezone {
  code: string;
  label: string;
}

export interface ApiCountry {
  code: string;
  label: string;
}

const genericURL = {
  allGenres: () => `${environment.urls.MAIN_API_V2}/misc/radio/genre`,
  allTimezones: () => `${environment.urls.MAIN_API_V2}/misc/timezone`,
  allCountries: () => `${environment.urls.MAIN_API_V2}/misc/country`,
};

@Injectable({
  providedIn: 'root',
})
export class GenericDataService {
  static convertToGenre(genre: ApiGenre[]): Genre[] {
    return genre.map(g => {
      return {
        id: g.idgenre,
        name: g.name,
        slug: g.slug,
        parentId: g.parent_id,
      };
    });
  }

  static convertToTimezone(timezone: ApiTimezone[]): Timezone[] {
    return timezone.map(t => {
      return { code: t.code, name: t.label };
    });
  }

  static convertToCountry(country: ApiCountry[]): Country[] {
    return country.map(t => {
      return { code: t.code, name: t.label };
    });
  }

  constructor(private readonly httpClient: HttpClient) {}

  getAllGenres(): Observable<Genre[]> {
    return this.httpClient
      .get<ApiGenre[]>(genericURL.allGenres())
      .pipe(map(data => GenericDataService.convertToGenre(data)));
  }

  getTimezones(): Observable<Timezone[]> {
    return this.httpClient
      .get<ApiTimezone[]>(genericURL.allTimezones())
      .pipe(map(data => GenericDataService.convertToTimezone(data)));
  }

  getCountries(): Observable<Country[]> {
    return this.httpClient
      .get<ApiCountry[]>(genericURL.allCountries())
      .pipe(map(data => GenericDataService.convertToCountry(data)));
  }
}
