import { Directive, HostListener } from '@angular/core';

/* tslint:disable */
@Directive({
  selector: '[routerLink]',
})
export class LinkWithoutDragDirective {
  constructor() {}

  @HostListener('mousedown')
  onMouseDown() {
    return false;
  }
}
