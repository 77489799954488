import { ErrorHandler, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@app/material.module';
import { ShellComponent } from './shell/shell.component';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { I18nService } from './services/i18n.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { SharedModule } from '@app/shared';
import { BearerInterceptor } from '@app/core/http/bearer.interceptor';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { AuthState } from '@app/core/states/auth.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '@env/environment';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NGXS_PLUGINS, NgxsModule } from '@ngxs/store';
import { AppState } from '@app/core/states/app.state';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { StorageEngineNgxs } from '@app/core/StorageEngineNgxs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AudioState } from '@app/core/states/audio.state';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { I18nState } from '@app/core/states/i18n.state';
import { RadioState } from '@app/core/states/radio.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { ToastrModule } from 'ngx-toastr';
import { GenreState } from '@app/core/states/genre.state';
import { CountryState } from '@app/core/states/country.state';
import { TimezoneState } from '@app/core/states/timezone.state';
import { AuthorizationState } from '@app/core/states/authorization.state';
import { SavedToLocalstorage } from '@app/core/ngxs/Constantes';
import { ShoutOutsState } from '@app/core/states/shout-outs.state';
import { UploadState } from '@app/core/states/upload.state';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { SentryErrorHandler } from '@app/core/SentryError';
import { SharedUnsavedChangesModule } from '@radioking/shared/unsaved-changes';
import { UiNoMobileModule } from '@radioking/ui/no-mobile';
import { SettingsState } from '@app/settings/states/settings.state';

export function TranslateMessageFormatCompilerFactory() {
  return new TranslateMessageFormatCompiler();
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    SharedUnsavedChangesModule,
    UiNoMobileModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useFactory: TranslateMessageFormatCompilerFactory,
      },
    }),
    SharedModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      preventDuplicates: true,
    }),
    ServiceWorkerModule,
    NgxsModule.forRoot([
      AppState,
      AuthState,
      AudioState,
      I18nState,
      RadioState,
      LiveTrackingState,
      GenreState,
      CountryState,
      TimezoneState,
      AuthorizationState,
      ShoutOutsState,
      UploadState,
      SettingsState,
    ]),
    NgxsStoragePluginModule.forRoot({
      key: SavedToLocalstorage,
    }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    environment.production
      ? []
      : NgxsLoggerPluginModule.forRoot({ disabled: environment.disableLogs }),
  ],
  declarations: [ShellComponent],
  providers: [
    // AuthenticationService,
    AuthenticationGuard,
    // I18nService,
    // HttpCacheService,
    ApiPrefixInterceptor,
    BearerInterceptor,
    ErrorHandlerInterceptor,
    // ApiHelperService,
    // ConfigService,
    // MatchMediaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: StorageEngineNgxs,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    {
      provide: LOCALE_ID,
      deps: [I18nService],
      useFactory: (settingsService: I18nService) => settingsService.language,
    },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`,
      );
    }
  }
}
