<rk-form-field [label]="label" [hintQuestionMark]="hintQuestionMark">
  <input type="text" rkInput disabled [value]="value" />
  <rk-button
    data-cy="input-copy-copy"
    faIcon="fa-copy"
    btnStyle="orange"
    class="ml-8"
    (click)="copyLink()"
  ></rk-button>
  <rk-button
    data-cy="input-copy-open"
    faIcon="fa-external-link-alt"
    btnStyle="orange"
    class="ml-8"
    (click)="openURL()"
    *ngIf="!disableOpen"
  ></rk-button>
</rk-form-field>
