import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
/* tslint:disable:max-line-length */
import { MaterialModule } from '@app/material.module';
import { AudioLoaderComponent } from './components/audio-loader/audio-loader.component';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { ContextMenuModule } from 'ngx-contextmenu';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from '@app/shared/components/shell/header/header.component';
import { RadioSidebarComponent } from '@app/shared/components/shell/radio-sidebar/radio-sidebar.component';
import { RadioSidebarHeaderComponent } from '@app/shared/components/shell/radio-sidebar-header/radio-sidebar-header.component';
import { RadioSidebarMainComponent } from '@app/shared/components/shell/radio-sidebar-main/radio-sidebar-main.component';
import { HeaderPlayerComponent } from '@app/shared/components/shell/header-player/header-player.component';
import { RouterModule } from '@angular/router';
import { HasPlanDirective } from './directives/has-plan.directive';
import { ChangeRadioModalComponent } from './modals/change-radio-modal/change-radio-modal.component';
import { HasRoleDirective } from './directives/has-role.directive';
import { MatIconModule } from '@angular/material/icon';
import { FuseNavigationComponent } from '@app/shared/modules/navigation/navigation.component';
import { RkNavVerticalItemComponent } from '@app/shared/modules/navigation/vertical/nav-item/nav-vertical-item.component';
import { RkNavVerticalCollapseComponent } from '@app/shared/modules/navigation/vertical/nav-collapse/nav-vertical-collapse.component';
import { FuseNavigationService } from '@app/shared/modules/navigation/navigation.service';
import { NoAccessContainerComponent } from './components/no-access-container/no-access-container.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { ExceededListeningBarComponent } from './components/exceeded-listening-bar/exceeded-listening-bar.component';
import { UpdateAvailableComponent } from './components/update-available/update-available.component';
import { IsOwnerDirective } from './directives/is-owner.directive';
import { HeaderUploadComponent } from './components/shell/header-upload/header-upload.component';
import { HeaderUploadLineComponent } from './components/shell/header-upload-line/header-upload-line.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { LinkWithoutDragDirective } from './directives/link-without-drag.directive';
import { IsAdminDirective } from './directives/is-admin.directive';
import { UiLoaderModule } from '@radioking/ui/loader';
import { UiButtonModule } from '@radioking/ui/button';
import { UiSortByModule } from '@radioking/ui/sort-by';
import { UiFoldItemModule } from '@radioking/ui/fold-item';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UiInputModule } from '@radioking/ui/input';
import { UiHintStarModule } from '@radioking/ui/hint-star';
import { MomentModule } from 'ngx-moment';
import { ToRadioTzPipe } from './pipes/to-radio-tz.pipe';
import { CustomShowHideDirective } from './directives/custom-show-hide.directive';
import { FoldContainerSidebarComponent } from './components/shell/fold-container-sidebar/fold-container-sidebar.component';
import { UiModalWrapperModule } from '@radioking/ui/modal-wrapper';
import { SharedPannelManagerModule } from '@radioking/shared/pannel-manager';
import { UiPerfectScrollBarModule } from '@radioking/ui/perfect-scroll-bar';
import { UiAdvancedTooltipModule } from '@radioking/ui/advanced-tooltip';
import { UiHelpLinkModule } from '@radioking/ui/help-link';
import { SafePipe } from './pipes/safe.pipe';
import { UiColoredHintModule } from '@radioking/ui/colored-hint';
import { TimeFromDatePipe } from './pipes/time-from-date.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { AvatarModule } from 'ngx-avatar';

/* tslint:enable:max-line-length */

const uiModules = [
  UiFoldItemModule,
  UiButtonModule,
  UiLoaderModule,
  UiSortByModule,
  UiHelpLinkModule,
  UiModalWrapperModule,
  UiInputModule,
  UiHintStarModule,
  UiPerfectScrollBarModule,
  UiAdvancedTooltipModule,
  UiColoredHintModule,
];

@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    MatIconModule,
    ContextMenuModule.forRoot(),
    TranslateModule.forChild(),
    SharedPannelManagerModule,
    ColorPickerModule,
    ScrollingModule,
    MomentModule,
    ...uiModules,
    AvatarModule,
  ],
  providers: [FuseNavigationService],
  declarations: [
    AudioLoaderComponent,
    TimeFormatPipe,
    HeaderComponent,
    RadioSidebarComponent,
    RadioSidebarHeaderComponent,
    RadioSidebarMainComponent,
    HeaderPlayerComponent,
    HasPlanDirective,
    ChangeRadioModalComponent,
    HasRoleDirective,
    FuseNavigationComponent,
    RkNavVerticalItemComponent,
    RkNavVerticalCollapseComponent,
    NoAccessContainerComponent,
    LocalizedDatePipe,
    ExceededListeningBarComponent,
    UpdateAvailableComponent,
    IsOwnerDirective,
    HeaderUploadComponent,
    HeaderUploadLineComponent,
    LinkWithoutDragDirective,
    IsAdminDirective,
    ToRadioTzPipe,
    CustomShowHideDirective,
    FoldContainerSidebarComponent,
    SafePipe,
    TimeFromDatePipe,
    ShortNumberPipe,
  ],
  exports: [
    AudioLoaderComponent,
    TimeFormatPipe,
    ContextMenuModule,
    HeaderComponent,
    RadioSidebarComponent,
    RadioSidebarHeaderComponent,
    RadioSidebarMainComponent,
    HeaderPlayerComponent,
    HasPlanDirective,
    HasRoleDirective,
    FuseNavigationComponent,
    NoAccessContainerComponent,
    LocalizedDatePipe,
    ExceededListeningBarComponent,
    UpdateAvailableComponent,
    IsOwnerDirective,
    SharedPannelManagerModule,
    LinkWithoutDragDirective,
    IsAdminDirective,
    MomentModule,
    ToRadioTzPipe,
    CustomShowHideDirective,
    FoldContainerSidebarComponent,
    ...uiModules,
    SafePipe,
    TimeFromDatePipe,
  ],
  entryComponents: [ChangeRadioModalComponent],
})
export class SharedModule {}
