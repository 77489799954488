export const PANELS_KEYS = {
  EDIT_TRACK_INFO: 'edit-track-info',
  CREATE_PLAYLIST: 'create-playlist',
  EDIT_PLAYLIST: 'edit-playlist',
  FILTER_EDIT: 'filter-edit-panel',
  ADD_TO_PLAYLIST: 'add-to-playlist',
  NEW_ANIMATOR: 'new-animator',
  EDIT_ANIMATOR: 'edit-animator',
  EDIT_TAG: 'edit-tag',
};
