import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Logger } from '@radioking/shared/logger';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { TrackService } from '@app/library/services/track.service';
import { RadioState } from '@app/core/states/radio.state';
import { PlayAudio } from '@app/core/states/audio.actions';
import { IframeHelperService } from '@app/core/services/iframe-helper.service';
import { ForceRefethingData, SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { ToasterService } from '@radioking/shared/common-services';
import {
  IframeStartedRadio,
  IframeStartingRadio,
  IframeStoppedRadio,
  IframeStoppingRadio,
} from '@app/hotglue/states/iframe.actions';
import { Navigate } from '@ngxs/router-plugin';
import { AuthenticationService } from '@app/core';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';

export interface MessageFromIframe {
  type:
    | 'loaded'
    | 'error404'
    | 'navigateTo'
    | 'refreshToken'
    | 'setModalStatus'
    | 'toast'
    | 'radioState'
    | 'adminSpecificRadio'
    | 'playTrack';
  source: 'iframe-legacy';
  payload?: any;
}

const log = new Logger('iframe component');

@Component({
  selector: 'rk-iframe-glue',
  templateUrl: './iframe-glue.component.html',
  styleUrls: ['./iframe-glue.component.scss'],
  animations: [
    trigger('modalBackDrop', [
      state(
        '0',
        style({
          'z-index': 0,
          opacity: 0,
        }),
      ),
      state(
        '1',
        style({
          opacity: 0.5,
          'z-index': 3,
        }),
      ),
      transition(
        '0 => 1',
        animate('150ms linear', keyframes([style({ 'z-index': 3, offset: 0 })])),
      ),
      transition('1 => 0', animate('150ms linear')),
    ]),
    trigger('iframeZdx', [
      state(
        '0',
        style({
          'z-index': 0,
        }),
      ),
      state(
        '1',
        style({
          'z-index': 5,
        }),
      ),
      transition(
        '0 => 1',
        animate('150ms linear', keyframes([style({ 'z-index': 5, offset: 0 })])),
      ),
      transition('1 => 0', animate('150ms linear')),
    ]),
  ],
})
export class IframeGlueComponent implements OnInit, OnDestroy {
  get isModalOpen(): boolean {
    return this._isModalOpen;
  }

  set isModalOpen(value: boolean) {
    this._isModalOpen = value;
    if (this.renderer) {
      if (value) {
        this.renderer.addClass(this.document.body, 'iframe-modal-opened');
      } else {
        this.renderer.removeClass(this.document.body, 'iframe-modal-opened');
      }
    }
  }
  private counter = 0;

  @ViewChild('iframe')
  iframe: ElementRef<HTMLIFrameElement>;

  private messages = new Subject<MessageFromIframe>();
  private urlSubject = new BehaviorSubject<string>('');

  // Private
  private unsubscribeAll = new Subject();

  url$: Observable<SafeResourceUrl>;
  isLoading = true;
  private _isModalOpen = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sanitizer: DomSanitizer,
    private readonly router: Router,
    private readonly store: Store,
    private readonly trackService: TrackService,
    private readonly iframeHelperSerivce: IframeHelperService,
    private readonly action$: Actions,
    private readonly authenticationService: AuthenticationService,
    private readonly toaster: ToasterService,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    // Angular by default sanitises a URL, we need to bypass that so the full URL is rendered
    // NOTE: Need to look into security considerations of this
    const source$ = merge(
      this.urlSubject.asObservable().pipe(distinctUntilChanged()),
      this.action$.pipe(ofActionSuccessful(ForceRefethingData)),
    );
    this.url$ = source$.pipe(
      map(data => {
        this.counter += 1;
        const pathWithoutPrefix = location.pathname
          .split('/')
          .slice(3)
          .join('/');
        return `${location.href.replace(
          location.pathname,
          '',
        )}/legacy/index.html?counter=${this.counter}#/${pathWithoutPrefix}`;
      }),
      map(str => this.sanitizer.bypassSecurityTrustResourceUrl(str)),
      tap(() => {
        this.isModalOpen = false;
        this.isLoading = true;
      }),
      // tap((data) => log.debug('iframe to url:', data) ),
    );

    // this.url$.subscribe((data) => log.debug('db data', data));
  }

  ngOnInit() {
    this.iframeHelperSerivce.setIframeOpen();
    this.listenForIFrameOutEvents();
    this.listenForRouteChange();
    this.listenForFallbackRoutingEvents();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.iframeHelperSerivce.setIframeClosed();
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.isModalOpen = false;
  }

  getMessages(): Observable<MessageFromIframe> {
    return this.messages.pipe(
      takeUntil(this.unsubscribeAll),
      filter(obj => obj.source === 'iframe-legacy'),
    );
  }

  fakeEvent(num: number) {
    if (num === 0) {
      this.messages.next({
        type: 'navigateTo',
        payload: 'widgets/listening_links',
        source: 'iframe-legacy',
      });
    }
    if (num === 1) {
      this.messages.next({
        type: 'setModalStatus',
        payload: true,
        source: 'iframe-legacy',
      });
    }
    if (num === 2) {
      this.messages.next({
        type: 'setModalStatus',
        payload: false,
        source: 'iframe-legacy',
      });
    }
    if (num === 3) {
      this.messages.next({
        type: 'loaded',
        source: 'iframe-legacy',
      });
    }
    parent.postMessage(
      {
        payload: { type: 'success', message: 'hello world' },
        type: 'toast',
        source: 'iframe-legacy',
      },
      '*',
    );
    parent.postMessage(
      {
        payload: { type: 'error', message: 'hello wolrd' },
        type: 'toast',
        source: 'iframe-legacy',
      },
      '*',
    );
  }

  /*
   If the iframed-in app can't resolve a URL itself it will post a message to the parent
   iframe (this app). Listen to those messages and attempt to navigate to that URL.
   */

  listenForFallbackRoutingEvents() {
    // Create IE + others compatible event handler
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(
      messageEvent,
      (e: any) => {
        if (e.data) {
          this.messages.next(e.data);
        }
      },
      false,
    );
  }

  listenForRouteChange() {
    this.nextRoute(this.router.url.substring(1));

    this.route.url.subscribe(urlSegments => {
      this.nextRoute(urlSegments.join('/'));
    });
  }

  nextRoute(url: string) {
    this.urlSubject.next(url);
  }

  getMessageTagged(tag: string) {
    return this.getMessages().pipe(
      filter(data => data.type === tag),
      map(data => data.payload),
    );
  }

  listenForIFrameOutEvents() {
    this.getMessages().subscribe(data => log.debug('Received from iframe : ', data));

    this.getMessageTagged('navigateTo').subscribe(payload => {
      const radioId = this.store.selectSnapshot(RadioState.currentRadioId);
      const url = `/radio/${radioId}${payload}`;
      log.debug('Redirecting to', url);
      this.router.navigateByUrl(url);
    });

    this.getMessageTagged('refreshToken')
      .pipe(
        flatMap(() => {
          log.debug('Requested to refresh the token');
          return this.authenticationService.getMe();
        }),
      )
      .subscribe();

    this.getMessageTagged('setModalStatus').subscribe(payload => {
      log.debug('Setting modal state to ', payload);
      this.isModalOpen = payload;
    });

    this.getMessageTagged('error404').subscribe(() => {
      log.debug('redirecting to 404');
      const startUrl = this.router.url
        .split('/')
        .slice(0, 3)
        .join('/');

      this.router.navigateByUrl(startUrl + '/404');
    });

    this.getMessageTagged('loaded').subscribe(() => {
      this.isLoading = false;
    });

    this.getMessageTagged('adminSpecificRadio').subscribe(payload => {
      this.store.dispatch(new Navigate(['radio', payload]));
    });

    this.getMessageTagged('radioState').subscribe(payload => {
      switch (payload) {
        case 'stopping':
          this.store.dispatch(new IframeStoppingRadio());
          break;
        case 'stopped':
          this.store.dispatch(new IframeStoppedRadio());
          break;
        case 'starting':
          this.store.dispatch(new IframeStartingRadio());
          break;
        case 'started':
          this.store.dispatch(new IframeStartedRadio());
          break;
      }
    });

    this.getMessageTagged('playTrack')
      .pipe(
        flatMap(trackId => {
          const radioId = this.store.selectSnapshot(RadioState.currentRadioId);
          return this.trackService.getTrackById(radioId, trackId);
        }),
        flatMap(track => this.store.dispatch(new PlayAudio(track))),
      )
      .subscribe();

    this.getMessageTagged('toast').subscribe(
      (payload: { type: 'success' | 'error'; message: string }) => {
        if (payload.type === 'success') {
          this.toaster.successNoTranslate(payload.message);
        } else if (payload.type === 'error') {
          this.toaster.errorNoTranslate(payload.message);
        }
      },
    );
  }
}
