import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { DataApiContainer } from '@app/core/models/DataApiContainer';
import { Observable } from 'rxjs';
import {
  convertToRadio,
  convertToSettings,
  Radio,
  RadioAndSettings,
  RadioNameTimezone,
  ShortRadio,
} from '@app/core/models/Radio';
import { map } from 'rxjs/operators';
import { LightApiTrack } from '@app/core/models/LightTrack';
import { ApiGenre } from '@app/core/services/generic-data.service';
import { PresetApi, Preset, presetApiToPreset } from '@app/core/models/Preset';
import {
  ApiConstraint,
  ApiGeoblock,
  ApiStream,
} from '@app/core/services/settings.service';
import { ProcessApiService } from '@app/core/services/process-api.service';
import * as moment from 'moment';
import { unwrapData } from '@app/core/rxjs/rxOperators';
import { PLACEHOLDER } from '@app/shared/constants';
import { Intercom } from 'ng-intercom';
import * as amplitude from 'amplitude-js';

export interface RadioApiModel {
  idradio: number;
  idfile_logo: string;
  logo: string;
  demo: {
    is_demo: boolean;
    expire_days?: number;
    expire_date?: moment.Moment;
  };
  is_broadcasting: boolean;
  lang: string;
  name: string;
  description: string;
  plan: {
    description: string;
    disk: number;
    download_chronics: number;
    idplan: number;
    listening_time: number;
  };
  limits: {
    disk: number;
    download_chronics: number;
    listening_time: number;
  };
  directory: {
    idradio: number;
    icecast: number;
    orange: number;
    radioline: number;
    sonos: number;
  };
  public: number;
  header: string;
  comments_enabled: number;
  rang: number;
  followers: number;
  serviceid: number;
  slogan?: string;
  slug: string;
  website?: string;
  exceeded_listening: number;
  country: string;
  timezone: string;
  streams: ApiStream[];
  genres: ApiGenre[];
  constraint: ApiConstraint;
  fadeout_default: number;
  mix_threshold: number;
  norm_threshold: number;
  auto_cue_enabled: number;
  disconnect_listeners_after?: number;
  geoblock: ApiGeoblock;
  status: 'active' | 'inactive' | 'deleted';
  stream_status: 'started' | 'stopped';
  role?: 'user' | 'customer';
  apps?: {
    twitter: {
      oauth_token: string;
      username: string;
    };
  };
  facebook?: string;
  twitter?: string;
  livefeed_settings: {
    facebook_enabled: number;
    twitter_enabled: number;
  };
  tweet: {
    enabled: number;
    cover: number;
    message: string;
    repeat: number;
  };
  itunes_affiliate_id: string;
  cover_default: string;
  coverconfig: string;
  live_title: {
    artist: string;
    buy_link: string;
    idfile_cover: string;
    idradio: number;
    status: 'inactive' | 'active';
    title: string;
    cover: string;
  };
}

export interface RadioStatus {
  status: 'active' | 'inactive' | 'deleted';
  stream_status: 'started' | 'stopped';
  can_upload: boolean;
  live?: {
    started_at: string;
    duration: number;
    broadcaster: {
      username: string;
      slug: string;
      firstname: string;
      lastname: string;
      idfile_avatar: string;
    };
  };
}

interface Countable {
  count: number;
}

export interface Readyable {
  ready: boolean;
}

export const radiosUrl = {
  allMyRadios: (userId: number) => `${environment.urls.MAIN_API_V2}/user/${userId}/radio`,
  getById: (id: number) => `${environment.urls.MAIN_API}/radio/${id}`,
  stop: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/stop`,
  start: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/start`,
  restart: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/restart`,
  ready: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/ready`,
  process: (id: number, processId: string) =>
    `${environment.urls.MAIN_API}/radio/${id}/process/${processId}/status`,
  getStatusById: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/status`,
  getListenersById: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/statistics/session/count`,
  getCurrentPlayingTrack: (slug: string) =>
    `${environment.urls.WIDGET_API_V2}/radio/${slug}/track/current`,
  getIncomingsTracks: (slug: string) =>
    `${environment.urls.WIDGET_API_V2}/radio/${slug}/track/next?limit=5`,
  getPreviousTracks: (slug: string) =>
    `${environment.urls.WIDGET_API_V2}/radio/${slug}/track/history?limit=5`,
  getPresets: (idRadio: number, idUser: number) =>
    `${environment.urls.MAIN_API_V2}/me/radio/${idRadio}/rights`,
};

@Injectable({
  providedIn: 'root',
})
export class RadioService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly processApiService: ProcessApiService,
    private readonly intercom: Intercom,
  ) {}

  getAllMyRadios(userId: number): Observable<ShortRadio[]> {
    return this.httpClient.get<RadioApiModel[]>(radiosUrl.allMyRadios(userId)).pipe(
      map(data => {
        return data.map(item => this.convertToShortRadio(item));
      }),
    );
  }

  getSpecificRadio(idRadio: number): Observable<RadioAndSettings> {
    return this.httpClient
      .get<DataApiContainer<RadioApiModel>>(radiosUrl.getById(idRadio))
      .pipe(
        unwrapData(),
        map(data => ({
          settings: convertToSettings(data),
          radio: convertToRadio(data),
        })),
      );
  }

  updateStartupRadio(idRadio: number, radio: RadioNameTimezone): Observable<Radio> {
    const requestPayload = {
      name: radio.name,
      timezone: radio.timezone.code,
    };
    return this.httpClient
      .put<DataApiContainer<RadioApiModel>>(radiosUrl.getById(idRadio), requestPayload)
      .pipe(
        unwrapData(),
        map(data => convertToRadio(data)),
      );
  }

  getPresetForRadio(idRadio: number, idUser: number): Observable<Preset[]> {
    return this.httpClient.get<PresetApi[]>(radiosUrl.getPresets(idRadio, idUser)).pipe(
      map(data => {
        if (!data) {
          return null;
        }
        return data.map(d => presetApiToPreset(d));
      }),
    );
  }

  stopRadio(idRadio: number): Observable<any> {
    this.intercom.trackEvent('stop radio');
    amplitude.getInstance().logEvent('stop radio');
    return this.httpClient.post(radiosUrl.stop(idRadio), {}, { responseType: 'text' });
  }

  startRadio(idRadio: number): Observable<any> {
    this.intercom.trackEvent('start radio');
    amplitude.getInstance().logEvent('start radio');
    return this.httpClient.post(radiosUrl.start(idRadio), {}, { responseType: 'text' });
  }

  restartRadio(idRadio: number): Observable<any> {
    this.intercom.trackEvent('restart radio');
    amplitude.getInstance().logEvent('restart radio');
    return this.httpClient.post(radiosUrl.restart(idRadio), {}, { responseType: 'text' });
  }

  isRadioReady(idRadio: number): Observable<Readyable> {
    return this.httpClient.get<Readyable>(radiosUrl.ready(idRadio));
  }

  getRadioStatus(idRadio: number): Observable<RadioStatus> {
    return this.httpClient.get<RadioStatus>(radiosUrl.getStatusById(idRadio)).pipe(
      map(data => {
        return data;
      }),
    );
  }

  getListenerOfRadio(idRadio: number): Observable<number> {
    return this.httpClient
      .get<Countable>(radiosUrl.getListenersById(idRadio))
      .pipe(map(data => data.count));
  }

  getCurrentPlayingTrack(slug: string): Observable<LightApiTrack> {
    return this.httpClient
      .get<LightApiTrack>(radiosUrl.getCurrentPlayingTrack(slug))
      .pipe(map(track => this.mapToLightTrack(track)));
  }

  getIncomingTracks(slug: string): Observable<LightApiTrack[]> {
    return this.httpClient
      .get<LightApiTrack[]>(radiosUrl.getIncomingsTracks(slug))
      .pipe(map(tracks => tracks.map(track => this.mapToLightTrack(track))));
  }

  getPreviousTracks(slug: string): Observable<LightApiTrack[]> {
    return this.httpClient
      .get<LightApiTrack[]>(radiosUrl.getPreviousTracks(slug))
      .pipe(map(tracks => tracks.map(track => this.mapToLightTrack(track))));
  }

  mapToLightTrack(lightApiTrack: LightApiTrack): LightApiTrack {
    return {
      album: lightApiTrack.album,
      artist: lightApiTrack.artist,
      buy_link: lightApiTrack.buy_link,
      cover: lightApiTrack.cover || PLACEHOLDER,
      duration: lightApiTrack.duration,
      end_at: lightApiTrack.end_at,
      forced_title: lightApiTrack.forced_title,
      id: lightApiTrack.id,
      is_live: lightApiTrack.is_live,
      next_track: lightApiTrack.next_track,
      started_at: lightApiTrack.started_at,
      title: lightApiTrack.title,
    };
  }

  convertToShortRadio(apiRadio: RadioApiModel): ShortRadio {
    return new ShortRadio(
      apiRadio.idradio,
      apiRadio.name,
      apiRadio.status,
      apiRadio.logo,
      apiRadio.role,
    );
  }
}
