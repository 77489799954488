<rk-modal-wrapper
  (cancel)="onCancel()"
  (close)="onCancel()"
  [showSubmitBlock]="false"
  [title]="'GLOBAL.MODAL.CHANGE_RADIO.TITLE' | translate"
>
  <div
    [@slideInOutBlock]
    *ngIf="(filesUploading$ | async) !== undefined && (filesUploading$ | async)"
    class="mb-16"
  >
    <rk-colored-hint>
      <div>{{ 'GLOBAL.MODAL.CHANGE_RADIO.WARNING_UPLOAD' | translate }}</div>
    </rk-colored-hint>
  </div>

  <div rkPerfectScrollbar class="container">
    <ng-container *ngFor="let radio of (radios$ | async)">
      <ng-container *ngIf="(currentRadioId$ | async) as currentRadioId">
        <div
          class="item-change"
          (click)="goToRadio(radio.id)"
          [ngClass]="{ current: currentRadioId === radio.id }"
        >
          <img [src]="radio.logo" alt="radio logo" />
          <span>{{
            radio.name || ('GLOBAL.MODAL.CHANGE_RADIO.NONAME' | translate)
          }}</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</rk-modal-wrapper>
