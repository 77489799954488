const tag = '[app]';

export class UpdateAvailable {
  static readonly type = `${tag} service worker update available`;
  constructor() {}
}

export class Error500 {
  static readonly type = `${tag} error 500`;
  constructor() {}
}
