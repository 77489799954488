import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TooltipHelpComponent } from './tooltip-help.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [TooltipHelpComponent],
  exports: [TooltipHelpComponent],
})
export class UiTooltipHelpModule {}
