import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, of } from 'rxjs';
import { filter, flatMap, map, startWith, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { RequestUser } from '@app/core/states/auth.actions';
import { ToasterService } from '@radioking/shared/common-services';
import { I18nService } from '@app/core/services/i18n.service';
import { UploadState } from '@app/core/states/upload.state';
import { Logger } from '@radioking/shared/logger';
import { appToasts } from '@app/app.toasts';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

const log = new Logger('App');

@Component({
  selector: 'rk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler() {
    if (this.store.selectSnapshot(UploadState.remainingFiles)) {
      return false;
    }
  }

  constructor(
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private toasterService: ToasterService,
  ) {}

  ngOnInit() {
    // Setup logger
    if (environment.disableLogs) {
      Logger.enableProductionMode();
    }
    this.toasterService.setupToasters();
    this.toasterService.registerToasts(appToasts);
    this.store.dispatch(new RequestUser());

    log.debug('init');

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd$)
      .pipe(
        flatMap(() => {
          let route = this.activatedRoute;
          let obs = [];
          obs = [...obs, route.data];
          while (route.firstChild) {
            route = route.firstChild;
            if (route.outlet === 'primary') {
              obs = [...obs, route.data];
            }
          }
          obs = [...obs, route.data];
          return combineLatest(obs);
        }),
        map(arrs => {
          let ret = {};
          arrs.forEach(item => {
            ret = { ...ret, ...(item as {}) };
          });
          return ret;
        }),
        map((val: any) => val.title || ''),
        switchMap((title: string) => {
          const globalKey = marker('GLOBAL.WINDOW_TITLE.GLOBAL_SUFFIX');
          const globalTitleStream$ = this.translateService.stream(globalKey);
          if (!title) {
            return combineLatest([of(''), globalTitleStream$]);
          }
          return combineLatest([
            this.translateService.stream(title).pipe(startWith('')),
            globalTitleStream$,
          ]);
        }),
      )
      .subscribe(([title, suffix]) => {
        if (!title) {
          this.titleService.setTitle(suffix);
        } else {
          this.titleService.setTitle(`${title} | ${suffix}`);
        }
      });
  }
}
