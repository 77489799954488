const tag = '[Panels]';

export class OpenSpecificPanel {
  static readonly type = `${tag} open a panel`;

  constructor(public name: string, public actionsAfter?: any) {}
}

export class CloseCurrentPanel {
  static readonly type = `${tag} close a panel`;

  constructor(public animate?: boolean) {}
}

export class TryActionUnlessPanelIsBlocking {
  static readonly type = `${tag} try to dispatch an action unless the panels blocks it`;
  constructor(public action: any) {}
}

export class FailTryActionUnlessPanelIsBlocking {
  static readonly type = `${tag} rejected due to modal output`;
  constructor() {}
}

export class EmptyActionForGuard {
  static readonly type = `${tag} empty action for deactivate guard`;
  constructor() {}
}

export class DisableAnimation {
  static readonly type = `${tag} disable panel animation`;
  constructor() {}
}

export class EnableAnimation {
  static readonly type = `${tag} enable panel animation`;
  constructor() {}
}

export class SetPanelActive {
  static readonly type = `${tag} set panel active`;

  constructor() {}
}

export class SetPanelUnactivated {
  static readonly type = `${tag} set panel unactivated`;

  constructor() {}
}
