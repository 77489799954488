import {
  Action,
  Actions,
  ofActionDispatched,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  IPBlacklistRequest,
  IPBlacklistSuccess,
  LoginTwitterRequest,
  LogoutTwitterFailure,
  LogoutTwitterRequest,
  LogoutTwitterSuccess,
  SettingsFailure,
  SettingsRequest,
  SettingsSuccess,
  SettingsV2Failure,
  SettingsV2Request,
  SettingsV2Success,
  UpdateSettingsBroadcastingRequest,
  UpdateSettingsCoverRequest,
  UpdateSettingsDirectoryRequest,
  UpdateSettingsFailure,
  UpdateSettingsGeneralRequest,
  UpdateSettingsPlatformRequest,
  UpdateSettingsSecurityRequest,
  UpdateSettingsSocialRequest,
  UpdateSettingsStreamRequest,
  UpdateSettingsSuccess,
  UpdateSettingsV2Request,
  UpdateSettingsV2Success,
} from './settings.actions';
import { Logger } from '@radioking/shared/logger';
import { RadioState } from '@app/core/states/radio.state';
import { catchError, filter, flatMap, map, take } from 'rxjs/operators';
import { merge, Observable, of } from 'rxjs';
import { RadioSettings } from '@app/core/models/Radio';
import { SettingsService } from '@app/core/services/settings.service';
import { CoversSettings, IPBlacklist, SettingsV2 } from '@app/core/models/Settings';
import { AuthState } from '@app/core/states/auth.state';
import { patch } from '@ngxs/store/operators';
import { SpecificRadioSuccess } from '@app/core/states/authorization.actions';
import { Injectable } from '@angular/core';

const log = new Logger('settings store');

export class SettingsStateModel {
  settings: RadioSettings;
  settingsV2: SettingsV2;
  ipBlacklist: IPBlacklist[];
  isFetching: boolean;
  isFetchingV2: boolean;
}

@State<SettingsStateModel>({
  name: 'settings',
  defaults: {
    settings: undefined,
    settingsV2: undefined,
    ipBlacklist: [],
    isFetching: false,
    isFetchingV2: false,
  },
})
@Injectable()
export class SettingsState {
  @Selector()
  static radioSettings(state: SettingsStateModel): RadioSettings {
    return state.settings;
  }

  @Selector()
  static radioSettingsV2(state: SettingsStateModel): SettingsV2 {
    return state.settingsV2;
  }

  @Selector()
  static ipBlacklist(state: SettingsStateModel): IPBlacklist[] {
    return state.ipBlacklist;
  }

  @Selector()
  static iTunesAffiliateID(state: SettingsStateModel): string {
    return state.settings ? state.settings.itunesAffiliateID : null;
  }

  @Selector()
  static coverConfig(state: SettingsStateModel): CoversSettings {
    return {
      coverConfig: state.settings.coverConfig,
      coverDefault: state.settings.coverDefault,
      liveTitle: {
        ...state.settings.liveTitle,
      },
    };
  }

  constructor(
    private readonly settingsService: SettingsService,
    private readonly store: Store,
    private readonly action$: Actions,
  ) {}

  @Action(SettingsRequest)
  getSettings(ctx: StateContext<SettingsStateModel>) {
    if (ctx.getState().isFetching) {
      return this.action$.pipe(ofActionDispatched(SettingsFailure, SettingsSuccess));
    }
    ctx.patchState({ isFetching: true });
    return this.getCurrentRadioId().pipe(
      flatMap(id => this.settingsService.getRadioSettings(id)),

      flatMap(data =>
        merge(
          ctx.dispatch(new SpecificRadioSuccess(data.radio)),
          ctx.dispatch(new SettingsSuccess(data.settings)),
        ),
      ),
      catchError(err => ctx.dispatch(new SettingsFailure(err))),
    );
  }

  @Action(SettingsV2Request)
  getSettingsV2(ctx: StateContext<SettingsStateModel>, action: SettingsV2Request) {
    if (ctx.getState().isFetchingV2) {
      return this.action$.pipe(ofActionDispatched(SettingsV2Failure, SettingsV2Success));
    }
    ctx.patchState({ isFetchingV2: true });
    return this.getCurrentRadioId().pipe(
      flatMap(id => this.settingsService.getSettingsV2(id)),
      flatMap(data => {
        return ctx.dispatch(new SettingsV2Success(data));
      }),
      catchError(err => ctx.dispatch(new SettingsV2Failure(err))),
    );
  }

  @Action(SettingsSuccess)
  getSettingsSuccess(
    ctx: StateContext<SettingsStateModel>,
    { settings }: SettingsSuccess,
  ) {
    ctx.patchState({
      settings,
      isFetching: false,
    });
  }

  @Action(SettingsV2Success)
  getSettingsV2Success(
    ctx: StateContext<SettingsStateModel>,
    { settingsV2 }: SettingsV2Success,
  ) {
    ctx.patchState({
      settingsV2,
      isFetchingV2: false,
    });
  }

  @Action(SettingsFailure)
  getSettingsFailure(ctx: StateContext<SettingsStateModel>) {
    ctx.patchState({ isFetching: false });
  }

  @Action(IPBlacklistRequest)
  getIPBlacklist(ctx: StateContext<SettingsStateModel>, action: IPBlacklistRequest) {
    return this.getCurrentRadioId().pipe(
      flatMap(id => this.settingsService.getIPBlacklist(id)),
      flatMap(data => {
        return ctx.dispatch(new IPBlacklistSuccess(data));
      }),
      catchError(err => ctx.dispatch(new SettingsFailure(err))),
    );
  }

  @Action(IPBlacklistSuccess)
  getIPBlacklistSuccess(
    ctx: StateContext<SettingsStateModel>,
    { ipBlacklist }: IPBlacklistSuccess,
  ) {
    ctx.patchState({
      ipBlacklist,
    });
  }

  @Action(UpdateSettingsGeneralRequest)
  updateGeneralSettings(
    ctx: StateContext<SettingsStateModel>,
    { settings, file }: UpdateSettingsGeneralRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      flatMap(id => {
        if (!file) {
          return of(id);
        }
        return this.settingsService.updateRadioLogo(id, file).pipe(map(() => id));
      }),
      flatMap(id => this.settingsService.updateRadioGeneralSettings(id, settings)),
      flatMap(data =>
        merge(
          ctx.dispatch(new SpecificRadioSuccess(data.radio)),
          ctx.dispatch(new SettingsSuccess(data.settings)),
          ctx.dispatch(new UpdateSettingsSuccess()),
        ),
      ),
      catchError(err => ctx.dispatch(new UpdateSettingsFailure(err))),
    );
  }

  @Action(UpdateSettingsBroadcastingRequest)
  updateBroadcastingSettings(
    ctx: StateContext<SettingsStateModel>,
    { settings }: UpdateSettingsBroadcastingRequest,
  ) {
    return this.dispatchAndHandle(ctx, (id: number) =>
      this.settingsService.updateRadioBroadcastingSettings(id, settings),
    );
  }

  @Action(UpdateSettingsStreamRequest)
  updateStreamSettings(
    ctx: StateContext<SettingsStateModel>,
    { settings }: UpdateSettingsStreamRequest,
  ) {
    return this.dispatchAndHandle(ctx, (id: number) =>
      this.settingsService.updateRadioStreamSettings(id, settings),
    );
  }

  @Action(UpdateSettingsPlatformRequest)
  updatePlatformSettings(
    ctx: StateContext<SettingsStateModel>,
    { settings, file }: UpdateSettingsPlatformRequest,
  ) {
    return this.dispatchAndHandle(ctx, (idRadio: number) => {
      return of(idRadio).pipe(
        flatMap((id: number) => {
          if (file === false) {
            return this.settingsService.deleteRadioPlatformHeader(id).pipe(map(() => id));
          }
          if (file) {
            return this.settingsService
              .updateRadioPlatformHeader(id, file)
              .pipe(map(() => id));
          }
          return of(id);
        }),
        flatMap((id: number) =>
          this.settingsService.updateRadioPlatformSettings(id, settings),
        ),
      );
    });
  }

  @Action(UpdateSettingsCoverRequest)
  updateCoverSettings(
    ctx: StateContext<SettingsStateModel>,
    { settings }: UpdateSettingsCoverRequest,
  ) {
    return this.dispatchAndHandle(ctx, (idRadio: number) => {
      return of(idRadio).pipe(
        flatMap((id: number) => {
          if (settings.fileCoverRemoved) {
            return this.settingsService.deleteRadioDefaultCover(id).pipe(map(() => id));
          }
          if (settings.fileCover) {
            return this.settingsService
              .updateRadioDefaultCover(id, settings.fileCover)
              .pipe(map(() => id));
          }
          return of(id);
        }),
        flatMap((id: number) => {
          if (settings.fileDefaultLiveRemoved) {
            return this.settingsService
              .deleteRadioLiveDefaultCover(id)
              .pipe(map(() => id));
          }
          if (settings.fileDefaultLive) {
            return this.settingsService
              .updateRadioLiveDefaultCover(id, settings.fileDefaultLive)
              .pipe(map(() => id));
          }
          return of(id);
        }),
        flatMap((id: number) =>
          this.settingsService.updateRadioCoverAndLiveSettings(id, settings),
        ),
      );
    });
  }

  @Action(UpdateSettingsDirectoryRequest)
  updateDirectorySettings(
    ctx: StateContext<SettingsStateModel>,
    { settings }: UpdateSettingsDirectoryRequest,
  ) {
    return this.dispatchAndHandle(ctx, (id: number) =>
      this.settingsService.updateRadioDirectorySettings(id, settings),
    );
  }

  @Action(UpdateSettingsSocialRequest)
  updateSocialSettings(
    ctx: StateContext<SettingsStateModel>,
    { settings }: UpdateSettingsSocialRequest,
  ) {
    return this.dispatchAndHandle(ctx, (idRadio: number) =>
      this.settingsService.updateRadioSocialSettings(idRadio, settings),
    );
  }

  private dispatchAndHandle(
    ctx: StateContext<SettingsStateModel>,
    fn: any,
  ): Observable<any> {
    return this.getCurrentRadioId().pipe(
      flatMap(idRadio => fn(idRadio)),
      flatMap((data: any) =>
        merge(
          ctx.dispatch(new SettingsSuccess(data)),
          ctx.dispatch(new UpdateSettingsSuccess()),
        ),
      ),
      catchError(err => ctx.dispatch(new UpdateSettingsFailure(err))),
    );
  }

  @Action(LoginTwitterRequest)
  loginTwitterRequest(ctx: StateContext<SettingsStateModel>) {
    const bearer = this.store.selectSnapshot(AuthState.getAccessToken);
    return this.getCurrentRadioId().pipe(
      flatMap(idRadio => {
        return this.settingsService.loginTwitter(idRadio, bearer);
      }),
    );
  }

  @Action(LogoutTwitterRequest)
  logoutTwitterRequest(ctx: StateContext<SettingsStateModel>) {
    return this.getCurrentRadioId().pipe(
      flatMap(idRadio => this.settingsService.logoutTwitter(idRadio)),
      flatMap(data => ctx.dispatch(new LogoutTwitterSuccess())),
      catchError(err => ctx.dispatch(new LogoutTwitterFailure(err))),
    );
  }

  @Action(LogoutTwitterSuccess)
  logoutTwitterSuccess(ctx: StateContext<SettingsStateModel>) {
    const settings = ctx.getState().settings;
    settings.apps.twitter = {
      oauthToken: null,
      username: null,
    };
    ctx.patchState({
      settings,
    });
  }

  @Action(UpdateSettingsSecurityRequest)
  updateSecuritySettings(
    ctx: StateContext<SettingsStateModel>,
    { settings }: UpdateSettingsSecurityRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      flatMap(idRadio =>
        this.settingsService
          .updateRadioIPBlacklistSettings(idRadio, settings.blacklist)
          .pipe(map(() => idRadio)),
      ),
      flatMap(idRadio =>
        this.settingsService.updateRadioSecuritySettings(idRadio, settings),
      ),
      flatMap(data => {
        ctx.patchState({ ipBlacklist: settings.blacklist });
        return merge(
          ctx.dispatch(new SettingsSuccess(data)),
          ctx.dispatch(new UpdateSettingsSuccess()),
        );
      }),
      catchError(err => ctx.dispatch(new UpdateSettingsFailure(err))),
    );
  }

  @Action(UpdateSettingsV2Request)
  updateSettingsV2Request(
    ctx: StateContext<SettingsStateModel>,
    { settings }: UpdateSettingsV2Request,
  ) {
    return this.getCurrentRadioId().pipe(
      flatMap(idRadio => this.settingsService.updateSettingsV2(idRadio, settings)),
      flatMap(data => {
        return merge(
          ctx.dispatch(new UpdateSettingsV2Success(data)),
          ctx.dispatch(new UpdateSettingsSuccess()),
        );
      }),
      catchError(err => ctx.dispatch(new UpdateSettingsFailure(err))),
    );
  }

  @Action(UpdateSettingsV2Success)
  updateSettingsV2Success(
    ctx: StateContext<SettingsStateModel>,
    { settings }: UpdateSettingsV2Success,
  ) {
    ctx.setState(
      patch({
        settingsV2: settings,
      }),
    );
  }

  getCurrentRadioId(): Observable<number> {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
    );
  }

  @Action(UpdateSettingsFailure)
  catchGlobalErrors(
    ctx: StateContext<SettingsStateModel>,
    { error }: UpdateSettingsFailure,
  ) {
    log.error(error);
  }
}
