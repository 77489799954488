<div class="form-input rk-input-wrapper">
  <div class="input-block">
    <div>
      <span class="label">{{ label }}</span>
      <rk-tooltip-help
        *ngIf="hintQuestionMark"
        [text]="hintQuestionMark"
      ></rk-tooltip-help>
      <span class="hint-max-char" *ngIf="hintLabel">({{ hintLabel }})</span>
      <span class="hint-max-char" *ngIf="chars"
        >({{ chars }} {{ 'SETTINGS.FORM.MAX_CHARS' | translate }})</span
      >
    </div>
    <div class="input-container">
      <ng-content></ng-content>
      <mat-icon
        fontSet="fa"
        fontIcon="fa-exclamation-circle"
        class="error-icon"
      ></mat-icon>
      <span *ngIf="hintRight && hintRight.length > 0" class="hint-right">{{
        hintRight
      }}</span>
    </div>
  </div>
  <div class="error-container"><ng-content select="rk-form-error"></ng-content></div>
</div>
