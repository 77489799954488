import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'rk-no-radio',
  templateUrl: './no-radio.component.html',
  styleUrls: ['./no-radio.component.scss'],
})
export class NoRadioComponent implements OnInit {
  translate: TranslateService;
  constructor(translate: TranslateService) {
    this.translate = translate;
  }

  ngOnInit() {}

  backToHome() {
    location.href = 'https://www.radioking.com';
  }

  createRadio() {
    this.translate
      .stream(marker('GLOBAL.LINK.CREATE_RADIO'))
      .pipe(filter(trad => !!trad))
      .subscribe(trad => (location.href = trad));
  }
}
