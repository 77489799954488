import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SugestionInput } from '../../models/sugestion-input.model';

@Component({
  selector: 'rk-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true,
    },
  ],
})
export class ChipsInputComponent implements OnInit, ControlValueAccessor {
  @Input()
  label: string;

  @Input()
  addNewCaseSensitive = false;

  @Input()
  debounceTime = 0;

  @Input()
  minLengthTrigger = 0;

  @Input()
  isLoadingData = false;

  @Input()
  modal = false;

  @Input()
  isTag = false;

  @Input()
  hint: string;

  private _data: SugestionInput[];

  @Input()
  set dataFetched(data: SugestionInput[]) {
    this._data = data;
    this.storeFiltered();
    if (this.inputVal) {
      this.searchData(this.inputVal);
    } else {
      this.filteredData = data;
    }
  }

  filteredData: SugestionInput[] = [];

  inputVal: string;

  get data() {
    return this._data;
  }

  @Input()
  canAddNew = true;

  @Input()
  shouldFilterWithData = true;

  @Input()
  placeholder: string;

  @Input()
  maxChips: number;

  @Input()
  chipMaxLength = 524288;

  @Output()
  submit = new EventEmitter<SugestionInput>();

  @Output()
  change = new EventEmitter<string>();

  @HostBinding('class.disabled')
  isDisabled = false;

  chips: SugestionInput[] = [];

  dataWithoutSeleced: SugestionInput[] = [];

  constructor() {}

  ngOnInit() {}

  searchData(val: string | Event) {
    // Fix for en event, I don't know where it's from
    if (val instanceof Event) {
      return;
    }
    this.inputVal = val;
    this.filteredData = this.dataWithoutSeleced.filter(data =>
      data.name.toLowerCase().includes(val.toLowerCase()),
    );
    this.change.emit(val);
  }

  storeFiltered() {
    this.dataWithoutSeleced = this.data.filter(
      data => !this.chips.map(chip => chip.name).includes(data.name),
    );
  }

  canAddNewChips() {
    if (this.maxChips && this.maxChips > 0) {
      return this.chips.length < this.maxChips;
    }
    return true;
  }

  propagateChange = (_: any) => {};

  addTag(txt: SugestionInput) {
    this.chips = [...this.chips, txt];
    if (this.addNewCaseSensitive) {
      this.chips = this.chips.filter(
        (x: SugestionInput, i: number, a: SugestionInput[]) =>
          a.findIndex(item => item.name === x.name) === i,
      );
    } else {
      this.chips = this.chips.filter(
        (x: SugestionInput, i: number, a: SugestionInput[]) =>
          a.findIndex(item => item.name.toLowerCase() === x.name.toLowerCase()) === i,
      );
    }
    this.propagateChange(this.chips);
    this.storeFiltered();
    this.filteredData = this.dataWithoutSeleced;
  }

  removeTag(tag: SugestionInput) {
    this.chips = this.chips.filter(val => val !== tag);
    this.propagateChange(this.chips);
    this.storeFiltered();
    this.searchData(this.inputVal || '');
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        this.chips = value;
      } else {
        this.chips = [value];
      }
    }
    this.storeFiltered();
    this.filteredData = this.dataWithoutSeleced;
  }
}
