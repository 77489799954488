import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../../../../core/services/player.service';
import { AudioState } from '../../../../core/states/audio.state';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Resume, Stop } from '../../../../core/states/audio.actions';
import { flatMap, map, take, tap } from 'rxjs/operators';
import { Track } from '@app/core/models/Track';

@Component({
  selector: 'rk-header-player',
  templateUrl: './header-player.component.html',
  styleUrls: ['./header-player.component.scss'],
})
export class HeaderPlayerComponent implements OnInit {
  @Select(AudioState.isAudioPlayerCurrent)
  isAudioPlayerCurrent$: Observable<boolean>;

  @Select(AudioState.isPlaying)
  isListening$: Observable<boolean>;

  @Select(AudioState.isAudioLoading)
  isAudioLoading$: Observable<boolean>;

  @Select(AudioState.currentTrackPlaying)
  currentTrack$: Observable<Track>;

  seekInputSubject$: BehaviorSubject<number>;

  isSeeking: boolean;

  volumeBeforeMute: number;

  isMuted: boolean;

  percentagePlayed$: Observable<number>;
  percentagePlayedRenderBars$: Observable<string>;

  constructor(
    public readonly playerService: PlayerService,
    private readonly store: Store,
  ) {
    this.seekInputSubject$ = new BehaviorSubject(0);
    this.isSeeking = false;
    this.volumeBeforeMute = 1;
    this.isMuted = false;
    this.percentagePlayed$ = combineLatest([
      this.playerService.onDuration(),
      this.playerService.onMaxDuration(),
    ]).pipe(
      map(([dur, max]) => {
        if (isNaN(max)) {
          return 0;
        }
        const nmb = (100 * dur) / max / 100;
        if (isNaN(nmb)) {
          return 0;
        }
        if (!nmb) {
          return 0;
        }
        if (nmb === Infinity) {
          return 0;
        }
        return nmb;
      }),
    );

    this.percentagePlayedRenderBars$ = this.percentagePlayed$.pipe(
      flatMap(percentage => {
        if (this.isSeeking) {
          return this.seekInputSubject$.asObservable();
        }
        return of(percentage);
      }),
      map(t => `${Math.round(t * 10000) / 100}%`),
    );
  }

  ngOnInit() {}

  pause(): void {
    this.store.dispatch(new Stop());
  }

  resume(): void {
    this.store.dispatch(new Resume());
  }

  getVolume(): number {
    return this.playerService.getVolume();
  }

  setVolme(vol: number): void {
    this.playerService.setVolume(vol);
    if (vol > 0) {
      this.isMuted = false;
    } else if (vol === 0) {
      this.isMuted = true;
    }
  }

  toggleMute(): void {
    if (!this.isMuted) {
      this.volumeBeforeMute = this.getVolume() || 1;
      this.setVolme(0);
      this.isMuted = true;
    } else {
      this.setVolme(this.volumeBeforeMute || 1);
      this.isMuted = false;
    }
  }

  getIconAcordingToVolume(): string | false {
    const v = this.getVolume();
    if (v === 0) {
      return 'fa-volume-off';
    }
    if (v <= 0.4) {
      return 'fa-volume-down';
    }
    if (v <= 0.7) {
      return false;
    }
    return 'fa-volume-up';
  }

  changeSeekUpdate(e: number) {
    this.seekInputSubject$.next(e);
  }

  startSeek() {
    this.isSeeking = true;
  }

  endSeek() {
    this.isSeeking = false;
    combineLatest([
      this.playerService.onMaxDuration(),
      this.seekInputSubject$.asObservable(),
    ])
      .pipe(take(1))
      .subscribe(([max, percentage]) => {
        this.playerService.seekTo(max * percentage);
      });
  }
}
