import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-exceeded-listening-bar',
  templateUrl: './exceeded-listening-bar.component.html',
  styleUrls: ['./exceeded-listening-bar.component.scss'],
})
export class ExceededListeningBarComponent implements OnInit {
  @Select(RadioState.increaseDiskSpaceUrl)
  increaseDiskSpaceUrl$: Observable<string>;

  constructor() {}

  ngOnInit() {}
}
