import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InputDirective } from './directive/input.directive';
import { TagComponent } from './component/tag/tag.component';
import { SearchBarComponent } from './component/search-bar/search-bar.component';
import { InputWithSuggestionsComponent } from './component/input-with-suggestions/input-with-suggestions.component';
import { InputWithCopyComponent } from './component/input-with-copy/input-with-copy.component';
import { FormFieldComponent } from './component/form-field/form-field.component';
import { FormErrorComponent } from './component/form-error/form-error.component';
import { DropdownAutocompleteComponent } from './component/dropdown-autocomplete/dropdown-autocomplete.component';
import { ChipsInputComponent } from './component/chips-input/chips-input.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UiButtonModule } from '@radioking/ui/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UiTooltipHelpModule } from '@radioking/ui/tooltip-help';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ScrollingModule,
    UiButtonModule,
    MatTooltipModule,
    MatIconModule,
    UiTooltipHelpModule,
  ],
  declarations: [
    InputDirective,
    TagComponent,
    SearchBarComponent,
    InputWithSuggestionsComponent,
    InputWithCopyComponent,
    FormFieldComponent,
    FormErrorComponent,
    DropdownAutocompleteComponent,
    ChipsInputComponent,
  ],
  exports: [
    InputDirective,
    TagComponent,
    SearchBarComponent,
    InputWithSuggestionsComponent,
    InputWithCopyComponent,
    FormFieldComponent,
    FormErrorComponent,
    DropdownAutocompleteComponent,
    ChipsInputComponent,
  ],
})
export class UiInputModule {}
