import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TimezonesFailure, TimezonesRequest, TimezonesSuccess } from './timezone.actions';
import { Logger } from '@radioking/shared/logger';
import { Timezone } from '@app/core/models/Timezone';
import { catchError, map } from 'rxjs/operators';
import { GenericDataService } from '@app/core/services/generic-data.service';
import { Injectable } from '@angular/core';

const log = new Logger('timezone store');

export class TimezoneStateModel {
  timezones: Timezone[];
}

@State<TimezoneStateModel>({
  name: 'timezone',
  defaults: {
    timezones: undefined,
  },
})
@Injectable()
export class TimezoneState {
  constructor(private readonly genericDataService: GenericDataService) {}

  @Selector()
  static timezoneList(state: TimezoneStateModel): Timezone[] {
    return state.timezones;
  }

  @Action(TimezonesRequest)
  getAllCountries(ctx: StateContext<TimezoneStateModel>, action: TimezonesRequest) {
    const timezones = ctx.getState().timezones;
    if (timezones) {
      return;
    }
    return this.genericDataService.getTimezones().pipe(
      map(data => ctx.dispatch(new TimezonesSuccess(data))),
      catchError(err => ctx.dispatch(new TimezonesFailure(err))),
    );
  }

  @Action(TimezonesSuccess)
  add(ctx: StateContext<TimezoneStateModel>, { timezones }: TimezonesSuccess) {
    const state = ctx.getState();
    ctx.patchState({
      timezones,
    });
  }
}
