import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DynamicPanelAnchorDirective } from '../../directive/dynamic-panel-anchor.directive';
import {
  DynamicEnabledPanel,
  PanelContainerInput,
} from '../../types/dynamic-panel-types';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { PanelsState } from '../../states/panels.state';
import { IntercomHiderService } from '@radioking/shared/common-services';
import {
  CloseCurrentPanel,
  FailTryActionUnlessPanelIsBlocking,
  SetPanelActive,
  SetPanelUnactivated,
  TryActionUnlessPanelIsBlocking,
} from '../../states/panels.actions';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, flatMap, map, take } from 'rxjs/operators';
import { UnsavedChangesModalComponent } from '@radioking/shared/unsaved-changes';
import { PanelRegistryService } from '../../services/panel-registry.service';

@Component({
  selector: 'rk-panel-container',
  template: `
    <mat-sidenav-container class="container-full" [hasBackdrop]="false">
      <mat-sidenav
        #sidenav
        mode="over"
        [opened]="isShowingSomething"
        position="end"
        disableClose="true"
      >
        <ng-template rkDynamicPanelAnchor #container></ng-template>
      </mat-sidenav>
      <mat-sidenav-content>
        <ng-content select="rk-panel-outside-content"></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styles: [``],
})
export class PanelContainerComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(DynamicPanelAnchorDirective, { static: true })
  dynamicTabPlaceholder: DynamicPanelAnchorDirective;

  @Select(PanelsState.currentPanel)
  currentPanel$: Observable<string>;

  isSideOpenedSubject = new BehaviorSubject<boolean>(false);

  subscription = new Subscription();

  isShowingSomething = true;

  // @Input()
  // items: PanelContainerInput[] = [];

  currentShown: DynamicEnabledPanel;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly intercomHiderService: IntercomHiderService,
    private readonly action$: Actions,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly panelRegistry: PanelRegistryService,
  ) {}

  ngOnInit() {
    this.store.dispatch(new SetPanelActive());
    this.setupConfirmModalSystem();
    this.setupIntercomHidingSystem();
  }

  // contentChildren are set
  ngAfterContentInit() {
    this.setupPanelShowingStrategy();
  }

  setupIntercomHidingSystem() {
    const subjectSubscription = this.isSideOpenedSubject
      .asObservable()
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.intercomHiderService.hideIntercom();
        } else {
          this.intercomHiderService.showIntercom();
        }
      });
    this.subscription.add(subjectSubscription);
  }

  setupPanelShowingStrategy() {
    const currentPanelSubscription = this.currentPanel$.subscribe(val => {
      if (val === '') {
        this.hideTab();
        return;
      }
      const found = this.panelRegistry.getPanelByKey(val);
      if (!found) {
        return;
      }
      this.showTab(found);
    });
    this.subscription.add(currentPanelSubscription);
  }

  setupConfirmModalSystem() {
    // [action, shouldFire, hasToCloseCurrent, shouldShowConfirmModal]
    const tryingActionSubscription = this.action$
      .pipe(ofActionDispatched(TryActionUnlessPanelIsBlocking))
      .pipe(
        map(({ action }) => {
          if (!this.currentShown) {
            return [action, true, false, false];
          }
          if (
            !this.currentShown.hasToShowConfirmOnExit() ||
            !this.currentShown.isDirty()
          ) {
            return [action, true, true, false];
          }
          return [action, false, true, true];
        }),
        flatMap(([action, shouldFire, hasToCloseCurrent, shouldShowConfirmModal]) => {
          if (!shouldShowConfirmModal) {
            return from([[action, shouldFire, hasToCloseCurrent]]);
          }

          return this.dialog
            .open(UnsavedChangesModalComponent)
            .afterClosed()
            .pipe(
              take(1),
              map(res => [action, res, hasToCloseCurrent]),
            );
        }),
        flatMap(([action, shouldFire, hasToCloseCurrent]) => {
          if (!shouldFire) {
            return this.store.dispatch(new FailTryActionUnlessPanelIsBlocking());
          }
          if (hasToCloseCurrent) {
            return this.store
              .dispatch(new CloseCurrentPanel())
              .pipe(flatMap(() => this.store.dispatch(action)));
          }
          return this.store.dispatch(action);
        }),
      )
      .subscribe();
    this.subscription.add(tryingActionSubscription);
  }

  showTab<T extends DynamicEnabledPanel>(key: PanelContainerInput<T>) {
    if (this.isShowingSomething) {
      this.hideTab();
    }
    this.isShowingSomething = true;
    this.isSideOpenedSubject.next(true);
    const componentFactory = key.factory.resolveComponentFactory(key.val);

    const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

    // create a component instance
    const componentRef = viewContainerRef.createComponent(componentFactory);

    // set the according properties on our component instance
    const instance: T = componentRef.instance as T;

    this.currentShown = instance;
  }

  hideTab() {
    if (!this.isShowingSomething) {
      return;
    }
    this.isShowingSomething = false;
    this.isSideOpenedSubject.next(false);
    this.intercomHiderService.hideIntercom();
    const closeAnimation = this.store.selectSnapshot(PanelsState.closeAnimation);

    if (closeAnimation) {
      /* tslint:disable align */
      setTimeout(() => {
        this.dynamicTabPlaceholder.viewContainer.remove();
      }, 300);
      /* tslint:enable */
    } else {
      this.dynamicTabPlaceholder.viewContainer.remove();
    }
    this.currentShown = undefined;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetPanelUnactivated());
  }
}
