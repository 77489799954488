import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoMobileComponent } from './no-mobile.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@app/shared';
import { MomentModule } from 'ngx-moment';
import { MaterialModule } from '@app/material.module';
import { AvatarModule } from 'ngx-avatar';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    SharedModule,
    MomentModule,
    MaterialModule,
    AvatarModule,
  ],
  declarations: [NoMobileComponent],
  exports: [NoMobileComponent],
})
export class UiNoMobileModule {}
