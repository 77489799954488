import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rk-colored-hint',
  templateUrl: './colored-hint.component.html',
  styleUrls: ['./colored-hint.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColoredHintComponent implements OnInit {
  @Input()
  type: 'warning' | 'info' = 'warning';

  @Input()
  inline = false;

  constructor() {}

  ngOnInit() {}
}
