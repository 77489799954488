import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rk-tooltip-help',
  templateUrl: './tooltip-help.component.html',
  styleUrls: ['./tooltip-help.component.scss'],
})
export class TooltipHelpComponent implements OnInit {
  @Input()
  text: string;

  @Input()
  aroundContent = false;

  @Input()
  customMarginTop = 0;

  @Input()
  disabled = false;

  constructor() {}

  ngOnInit() {}
}
