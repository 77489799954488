import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { Store } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';

@Pipe({
  name: 'timeFromDate',
})
export class TimeFromDatePipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(value: string): any {
    const timezoneRadio = this.store.selectSnapshot(RadioState.timezone);
    if (!value) return;
    return moment(value)
      .tz(timezoneRadio)
      .format('HH:mm:ss');
  }
}
