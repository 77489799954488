<mat-toolbar class="mat-elevation-z6 navbar">
  <button
    [class.hidden]="!isMobile"
    class="menu-button"
    mat-icon-button
    (click)="toggleSideNav()"
  >
    <mat-icon fontSet="fa" fontIcon="fa-bars"></mat-icon>
  </button>
  <div><rk-header-player></rk-header-player></div>
  <span fxFlex fxLayoutAlign="center">
    <span class="alert-admin-radio" *ngIf="!(isOneOfMineRadios$ | async)"
      >{{ 'HEADER.ALERT_ADMIN_RADIO' | translate }}
      <span class="link" (click)="goBackToMyRadio()">{{
        'HEADER.ALERT_ADMIN_BACK' | translate
      }}</span>
    </span>
  </span>
  <ng-container *ngIf="canShowI18nChange">
    <button mat-icon-button [matMenuTriggerFor]="languageMenu">
      <mat-icon fontSet="fa" fontIcon="fa-globe"></mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let language of supportedLangs$ | async"
        (click)="setLanguage(language)"
      >
        {{ language }}
      </button>
    </mat-menu>
  </ng-container>

  <div class="right-side" fxLayout="row">
    <rk-header-upload></rk-header-upload>
    <div class="headway-widget">
      <mat-progress-spinner
        [mode]="'determinate'"
        [value]="100"
        [diameter]="35"
        [strokeWidth]="1"
      >
      </mat-progress-spinner>
      <mat-icon fontSet="fas" fontIcon="fa-bolt"></mat-icon>
    </div>
    <button class="upgrade" *rkHasPlan="{ id: 1 }" (click)="updateOffer()">
      <mat-icon fontSet="fa" fontIcon="fa-crown"></mat-icon>
      {{ 'HEADER.UPDGRADE_OFFER' | translate }}
    </button>
    <button class="user-info" [matMenuTriggerFor]="userMenu" data-cy="user-info-button">
      <span data-cy="user-info-button-name">{{ username$ | async }}</span>
      <mat-icon fontSet="fa" fontIcon="fa-chevron-down"></mat-icon>
    </button>
  </div>

  <mat-menu #userMenu="matMenu" class="user-mat-menu" [overlapTrigger]="false">
    <mat-list>
      <mat-list-item>
        <div class="item-menu-info-user">
          <div>
            <img
              *ngIf="profilePic$ | async; else letterAvatar"
              [src]="profilePic$ | async"
              data-cy="user-icon"
            />
          </div>
          <div>
            <span data-cy="user-username">{{ username$ | async }}</span>
            <span data-cy="user-email">{{ email$ | async }}</span>
          </div>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <a target="_blank" [href]="'HEADER.USER.PROFILE_URL' | translate">
      <button mat-menu-item translate>HEADER.USER.PROFILE</button>
    </a>
    <a target="_blank" [href]="clientAreaUrl">
      <button mat-menu-item translate>HEADER.USER.CUSTOMER_AREA</button>
    </a>
    <a
      target="_blank"
      (click)="openKnowledgeBase()"
      href="https://www.radioking.com/on/knowledgebase/9/Radio-Manager"
      ><button mat-menu-item translate>HEADER.USER.HELP</button></a
    >
    <a target="_blank" [href]="'HEADER.USER.CHANGELOG_URL' | translate">
      <button mat-menu-item translate>HEADER.USER.CHANGELOG</button>
    </a>
    <button mat-menu-item (click)="logout()" translate>HEADER.USER.LOGOUT</button>
  </mat-menu>
</mat-toolbar>

<rk-exceeded-listening-bar *ngIf="exceeded$ | async"></rk-exceeded-listening-bar>

<ng-template #letterAvatar>
  <ngx-avatar [name]="username$ | async" [size]="40"></ngx-avatar>
</ng-template>
