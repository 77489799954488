import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicPanelAnchorDirective } from './directive/dynamic-panel-anchor.directive';
import { PanelContainerComponent } from './components/panel-container/panel-container.component';
import { PanelOutsideContentComponent } from './components/panel-outside-content/panel-outside-content.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgxsModule } from '@ngxs/store';
import { PanelsState } from './states/panels.state';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUnsavedChangesModule } from '@radioking/shared/unsaved-changes';
import { CanDeactivatePanelGuard } from '@radioking/shared/pannel-manager';
import { SidenavActionWrapperComponent } from './components/sidenav-action-wrapper/sidenav-action-wrapper.component';
import { UiButtonModule } from '@radioking/ui/button';
import { UiLoaderModule } from '@radioking/ui/loader';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UiPerfectScrollBarModule } from '@radioking/ui/perfect-scroll-bar';

@NgModule({
  declarations: [
    DynamicPanelAnchorDirective,
    PanelContainerComponent,
    PanelOutsideContentComponent,
    SidenavActionWrapperComponent,
  ],
  imports: [
    CommonModule,
    SharedUnsavedChangesModule,
    TranslateModule,
    FlexLayoutModule,
    UiPerfectScrollBarModule,
    MatSidenavModule,
    MatIconModule,
    UiButtonModule,
    UiLoaderModule,
    NgxsModule.forFeature([PanelsState]),
  ],
  exports: [
    DynamicPanelAnchorDirective,
    PanelContainerComponent,
    PanelOutsideContentComponent,
    SidenavActionWrapperComponent,
  ],
})
export class SharedPannelManagerModule {}
