import { Title } from '@angular/platform-browser';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Logout } from '@app/core/states/auth.actions';
import { Observable } from 'rxjs';
import { AuthState } from '@app/core/states/auth.state';
import { PlayerService } from '@app/core/services/player.service';
import { environment } from '@env/environment';
import { MainSideNavService } from '@app/core/services/main-side-nav.service';
import { I18nState } from '@app/core/states/i18n.state';
import { SetLang } from '@app/core/states/i18n.actions';
import { RadioState } from '@app/core/states/radio.state';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { distinctUntilChanged, first, take } from 'rxjs/operators';
import { Intercom } from 'ng-intercom';
import * as amplitude from 'amplitude-js';
import { TranslateService } from '@ngx-translate/core';
import { ShortRadio } from '@app/core/models/Radio';
import { Navigate } from '@ngxs/router-plugin';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { TryActionUnlessPanelIsBlocking } from '@radioking/shared/pannel-manager';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';

@Component({
  selector: 'rk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  canShowI18nChange = environment.show18nSwitch;

  @Select(RadioState.currentRadioExceeded)
  exceeded$: Observable<boolean>;

  @Select(RadioState.isOneOfMineRadios)
  isOneOfMineRadios$: Observable<boolean>;

  @Select(AuthState.currentUsername)
  username$: Observable<string>;

  @Select(AuthState.currentEmail)
  email$: Observable<string>;

  @Select(AuthState.currentProfilePic)
  profilePic$: Observable<string>;

  @Select(AuthState.userLang)
  userLang$: Observable<string>;

  @Select(I18nState.supportedLang)
  supportedLangs$: Observable<string[]>;

  clientAreaUrl = `${environment.urls.WHMCS}/clientarea.php`;
  isMobile = false;
  headwayEn = '7N2LA7';
  headwayFr = 'xYOvAJ';

  constructor(
    private readonly router: Router,
    private readonly titleService: Title,
    private readonly store: Store,
    private readonly playerService: PlayerService,
    private readonly mainSideNavService: MainSideNavService,
    private readonly media: MediaObserver,
    private readonly intercom: Intercom,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit() {
    if (this.media.isActive('lt.ipad')) {
      this.isMobile = true;
    }
    this.media
      .asObservable()
      .pipe(distinctUntilChanged())
      .subscribe((changes: MediaChange[]) => {
        let mobile = false;
        changes.forEach(change => {
          if (change.mqAlias === 'lt.ipad') {
            mobile = true;
          }
        });
        this.isMobile = mobile;
      });
  }

  setLanguage(language: string) {
    this.store.dispatch(new SetLang(language));
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  toggleSideNav() {
    this.mainSideNavService.toggleSideNav();
  }

  openKnowledgeBase() {
    this.intercom.trackEvent('show knowledge center');
    amplitude.getInstance().logEvent('show knowledge center');
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  updateOffer(): void {
    this.intercom.trackEvent('click update demo');
    amplitude.getInstance().logEvent('click update demo');
    const link = this.store.selectSnapshot(RadioState.updateOfferUrl);
    window.open(link, '_blank');
  }

  ngAfterViewInit(): void {
    this.userLang$.subscribe(lang => {
      const config = {
        selector: '.headway-widget', // CSS selector where to inject the badge
        account: lang !== 'fr' ? this.headwayEn : this.headwayFr,
        translations: {
          title: this.translateService.instant('GLOBAL.CHANGELOG.TITLE'),
          readMore: this.translateService.instant('GLOBAL.CHANGELOG.READ_MORE'),
          labels: {
            new: this.translateService.instant('GLOBAL.CHANGELOG.NEW'),
            improvement: this.translateService.instant('GLOBAL.CHANGELOG.IMPROVEMENT'),
            fix: this.translateService.instant('GLOBAL.CHANGELOG.FIXES'),
          },
          footer: this.translateService.instant('GLOBAL.CHANGELOG.READ_MORE'),
        },
      };
      // @ts-ignore
      Headway.init(config);
    });
  }

  @Dispatch()
  goBackToMyRadio() {
    const myRadio = this.store.selectSnapshot(RadioState.firstRadioOwned);
    return new TryActionUnlessPanelIsBlocking(new SwitchToRadioRequest(myRadio.id));
  }
}
