<rk-form-field
  [label]="label"
  [hintLabel]="hintLabel"
  [hintQuestionMark]="hintQuestionMark"
>
  <div class="main">
    <input
      type="text"
      #inputRef
      [maxLength]="maxInputLength"
      [placeholder]="placeholder"
      (keydown.enter)="addCurrentInput($event)"
      (keydown.arrowup)="onArrowUp()"
      (keydown.arrowdown)="onArrowDown()"
      [disabled]="disabled"
      (blur)="blur.next($event)"
      (input)="onType()"
      (focus)="focusInput()"
    />
    <span *ngIf="!isTrack" class="caret-icon" (click)="forceFocusInput()">
      <mat-icon fontSet="fa" fontIcon="fa-caret-down"></mat-icon
    ></span>
    <span
      *ngIf="displayClearButton && inputValue"
      class="clear-icon"
      (click)="clearInput()"
    >
      <rk-icon-button
        (click)="clearInput()"
        faIcon="fa-times"
        color="gray-darkest"
      ></rk-icon-button>
    </span>
    <div
      #dropdownRef
      class="dropdown"
      [hidden]="canShowDropdown(inputRef.value)"
      [ngClass]="{
        'should-be-hidden': !canShowDropdown(inputRef.value),
        'in-modal': inModal
      }"
      [style.width]="inputWidth$ | async"
    >
      <ng-container *ngIf="canShowDropdown(inputRef.value)">
        <ul
          *ngIf="dataFetched && dataFetched.length > 0"
          #dropdownUlRef
          [class.track]="isTrack"
        >
          <cdk-virtual-scroll-viewport
            itemSize="25"
            minBufferPx="300"
            maxBufferPx="400"
            class="scroll-viewport"
            [ngStyle]="{ minHeight: minHeight }"
          >
            <li
              *cdkVirtualFor="let item of dataFetched; let idx = index"
              (click)="addSpecificItem(item)"
              (mouseenter)="focusToSpecific(idx)"
              class="item-drop"
              [ngClass]="{ selected: idx === currentSelected }"
            >
              <ng-container *ngIf="!renderLineTemplate; else temp">
                {{ !item.translation ? item.name : (item.translation | translate) }}
              </ng-container>
              <ng-template #temp>
                <ng-template
                  *ngTemplateOutlet="
                    renderLineTemplate;
                    context: { item: item, warning: true }
                  "
                ></ng-template>
              </ng-template>
            </li>
          </cdk-virtual-scroll-viewport>
        </ul>
        <div
          *ngIf="canAddNew && showAddNewBlock(inputRef.value)"
          class="add-new-item item-drop"
          (mouseenter)="focusToSpecific(-1)"
          [ngClass]="{
            'hide-top-bar': !(dataFetched && dataFetched.length > 0),
            selected: currentSelected === -1
          }"
        >
          <div (click)="addFromPlusButton()">
            <mat-icon fontSet="fa" fontIcon="fa-plus"></mat-icon>
            <span>{{
              'SETTINGS.FORM.INPUT_SUGGESTION.ADD'
                | translate: { value: inputRef.value.trim() }
            }}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-content select="rk-form-error"></ng-content>
  </div>
</rk-form-field>
