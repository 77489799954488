import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { AuthState } from '@app/core/states/auth.state';
import { map } from 'rxjs/operators';
import { isBoolean } from 'lodash';

@Directive({
  selector: '[rkIsAdmin]',
})
export class IsAdminDirective implements OnInit {
  isViewDisplayed = false;

  @Input('rkIsAdmin')
  set rkIsAdmin(val: boolean) {
    if (isBoolean(val)) {
      this._showIfAdmin = val;
    }
    this.setupObservable();
  }

  _showIfAdmin = true;

  subscription: Subscription;

  @Select(AuthState.isSuperUser)
  isSuperUser$: Observable<boolean>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this.setupObservable();
  }

  setupObservable() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.isSuperUser$
      .pipe(
        map(isSuperUser => {
          if (this._showIfAdmin) {
            return isSuperUser;
          }
          if (!this._showIfAdmin) {
            return true;
          }
        }),
      )
      .subscribe(out => {
        if (out) {
          this.showContent();
        } else {
          this.hideContent();
        }
      });
  }

  ngOnInit(): void {}

  showContent() {
    if (this.isViewDisplayed) {
      return;
    }
    this.isViewDisplayed = true;
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  hideContent() {
    if (!this.isViewDisplayed) {
      return;
    }
    this.isViewDisplayed = false;
    this.viewContainer.clear();
  }
}
