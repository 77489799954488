import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material/sidenav';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  mergeMap,
} from 'rxjs/operators';
import { Logger } from '@radioking/shared/logger';
import { baseNav } from '@app/shared/modules/navigation/navigation.model';
import { MainSideNavService } from '@app/core/services/main-side-nav.service';
import { IframeHelperService } from '@app/core/services/iframe-helper.service';
import { NavigationEnd, Router } from '@angular/router';
import {
  FoldContainerSidebarComponent,
  LOCAL_STORAGE_FOLD_KEY,
} from '@app/shared/components/shell/fold-container-sidebar/fold-container-sidebar.component';
import { Select, Store } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { Observable, of } from 'rxjs';
import { Radio } from '@app/core/models/Radio';
import {
  RoleHelperService,
  RoleOperatorActions,
} from '@app/core/services/role-helper.service';
import { AuthorizationState } from '@app/core/states/authorization.state';
import { Preset, Roles } from '@app/core/models/Preset';
import { ModelNav } from '@app/shared/modules/navigation/navigation.service';

const log = new Logger('ShellComponent');

@Component({
  selector: 'rk-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  @HostBinding('class.is-mobile')
  isOnMobile = false;

  navigation = baseNav;

  @HostBinding('class.hack-iframe')
  showIframeHack = false;

  @HostBinding('class.folded-side')
  folded = false;

  @ViewChild('sidebar', { static: true })
  sidebar: FoldContainerSidebarComponent;

  menuClosed = false;

  @Select(RadioState.currentPlanId)
  radioPlan$: Observable<number>;

  @Select(RadioState.currentRadio)
  currentRadio$: Observable<Radio>;

  @Select(RadioState.updateOfferUrl)
  updateOfferUrl$: Observable<string>;

  @Select(AuthorizationState.roles)
  userRoles$: Observable<Preset[]>;

  constructor(
    private readonly router: Router,
    private readonly media: MediaObserver,
    private readonly sideNavService: MainSideNavService,
    private readonly iframeService: IframeHelperService,
    private readonly mainSideNavService: MainSideNavService,
    private readonly _rolesService: RoleHelperService,
    private readonly _store: Store,
  ) {}

  ngOnInit() {
    if (window.location.href.startsWith('https://app.radioking.com')) {
      window.location.href = 'https://manager.radioking.com';
    }

    this.iframeService.getIframeStatus().subscribe(val => {
      setTimeout(() => {
        this.showIframeHack = val;
      });
    });
    this.sideNavService.setSideNav(this.sidenav);
    // Automatically close side menu on screens > sm breakpoint

    if (this.media.isActive('lt.ipad')) {
      this.menuClosed = true;
      this.setMobile();
    } else {
      this.setDesktop();
    }
    this.menuClosed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FOLD_KEY));

    this.media
      .asObservable()
      .pipe(distinctUntilChanged())
      .subscribe((changes: MediaChange[]) => {
        let mobile = false;
        changes.forEach(change => {
          if (change.mqAlias === 'lt.ipad') {
            mobile = true;
          }
        });
        if (this.isOnMobile && !mobile) {
          this.menuClosed = false;
        }
        if (!this.isOnMobile && mobile) {
          this.menuClosed = true;
        }
        this.isOnMobile = mobile;
        if (mobile) {
          this.setMobile();
        } else {
          this.setDesktop();
        }
      });

    let startUrl = this.router.url
      .split('/')
      .slice(0, 3)
      .join('/');

    let finalUrl =
      '/' +
      this.router.url
        .split('/')
        .slice(3)
        .join('/');

    this.currentRadio$
      .pipe(
        filter(val => !!val),
        distinctUntilChanged(),
        mergeMap(radio => {
          if (!radio.name) {
            this.router.navigateByUrl('/radio/' + radio.id + '/configure-radio');
            return of(null);
          } else {
            return this.userRoles$;
          }
        }),
        filter(roles => roles && roles.length > 0),
        debounceTime(1),
      )
      .subscribe(() => {
        startUrl = this.router.url
          .split('/')
          .slice(0, 3)
          .join('/');

        finalUrl =
          '/' +
          this.router.url
            .split('/')
            .slice(3)
            .join('/');

        this.processRouteRights(startUrl, finalUrl);
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        mergeMap(() => this.userRoles$),
        filter(roles => roles && roles.length > 0),
        debounceTime(1),
      )
      .subscribe(data => {
        startUrl = this.router.url
          .split('/')
          .slice(0, 3)
          .join('/');

        finalUrl =
          '/' +
          this.router.url
            .split('/')
            .slice(3)
            .join('/');

        this.processRouteRights(startUrl, finalUrl);

        if (this.isOnMobile) {
          this.sidenav.close();
        }
      });
  }

  processRouteRights(startUrl: string, finalUrl: string) {
    if (finalUrl === '/' || finalUrl === '/configure-radio') return;

    if (finalUrl.indexOf('/settings') > -1) {
      this._rolesService
        .hasRole({
          type: RoleOperatorActions.SPECIFIC,
          roleId: Roles.SETTINGS,
        })
        .pipe(first())
        .subscribe(hasRole => {
          if (!hasRole) {
            return this.router.navigateByUrl(startUrl + '/404');
          }
        });
    } else {
      const nav = this.findByUrl(finalUrl);

      if (!nav) {
        return this.router.navigateByUrl(startUrl + '/404');
      }

      let roles;

      if (nav.roles) {
        roles = nav.roles['type']
          ? nav.roles
          : {
              type: RoleOperatorActions.SPECIFIC,
              roleId: nav.roles,
            };

        this._rolesService
          .hasRole(roles)
          .pipe(first())
          .subscribe(hasRole => {
            if (!hasRole) {
              return this.router.navigateByUrl(startUrl + '/404');
            }
          });
      }
    }
  }

  findByUrl(finalURL: string): ModelNav {
    for (let i = 0; i < baseNav.length; ++i) {
      if (finalURL.indexOf(baseNav[i].url) > -1) {
        return baseNav[i];
      }
      if (baseNav[i].children) {
        for (let j = 0; j < baseNav[i].children.length; ++j) {
          if (!baseNav[i].children[j].roles) {
            baseNav[i].children[j].roles = baseNav[i].roles;
          }
          if (finalURL.indexOf(baseNav[i].children[j].url) > -1) {
            return baseNav[i].children[j];
          }
        }
      }
    }
    return null;
  }

  @ViewChild('sidenav', { static: true })
  set sidenav(nav: MatSidenav) {
    this.sideNavService.setSideNav(nav);
  }

  @ViewChild(FoldContainerSidebarComponent, { static: true })
  set foldContainer(nav: FoldContainerSidebarComponent) {
    this.sideNavService.setFoldContener(nav);
  }

  get sidenav(): MatSidenav {
    return this.sideNavService.getSideNav();
  }

  changeFold(value: boolean) {
    setTimeout(() => {
      this.folded = value;
    }, 1);
  }

  private setMobile() {
    this.sidenav.close();
    this.isOnMobile = true;
    this.sideNavService.getFoldContener().unfold();
    log.debug('Closing sidenav');
  }

  private setDesktop() {
    this.sidenav.open();
    this.isOnMobile = false;
    log.debug('Opening sidenav');
  }

  sidenavStateChanged(opened: boolean) {
    this.iframeService.setSidenavOpen(this.isOnMobile ? false : opened);
  }

  onTouchFold() {
    this.sidebar.onTouchFold();
  }

  toggleSideNav(e: MouseEvent) {
    this.menuClosed = this.isOnMobile ? true : !this.menuClosed;
    if (this.isOnMobile) {
      this.mainSideNavService.toggleSideNav();
    } else {
      this.mainSideNavService.toggleFold();
    }
  }
}
