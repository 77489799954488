<ng-container *ngIf="!item.hidden">
  <ng-container *rkHasRole="item.roles || false">
    <a
      class="nav-link"
      *ngIf="item.url"
      routerLink="/radio/{{ radioId }}{{ item.url }}"
      routerLinkActive="active"
      attr.data-navkey="{{ item.id }}"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
      <mat-icon
        class="nav-link-icon"
        *ngIf="item.icon"
        fontSet="fa"
        [fontIcon]="item.icon"
      ></mat-icon>
      <span class="nav-link-title">{{
        item.translate ? (item.translate | translate) : item.title
      }}</span>
    </a>

    <span class="nav-link" *ngIf="item.function" (click)="item.function()">
      <mat-icon
        class="nav-link-icon"
        *ngIf="item.icon"
        fontSet="fa"
        [fontIcon]="item.icon"
      ></mat-icon>
      <span class="nav-link-title">{{
        item.translate ? (item.translate | translate) : item.title
      }}</span>
    </span>
  </ng-container>
</ng-container>
