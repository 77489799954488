// `.env.ts` is generated by the `npm run env` command

import { Env } from '@env/model';

export const environment: Env = {
  production: true,
  disableLogs: true,
  show18nSwitch: false,
  version: 'prod',
  listenUrl: 'https://www.radioking.com/play',
  urls: {
    MAIN_API: 'https://www.radioking.com/api',
    MAIN_API_V2: 'https://api.radioking.io',
    WIDGET_API: 'https://www.radioking.com/widgets/api/v1',
    WIDGET_API_V2: 'https://api.radioking.io/widget',
    WHMCS: 'https://www.radioking.com/on',
    RADIOKING: 'https://www.radioking.com',
    WIDGETS: 'https://www.radioking.com/widgets',
    WIDGETS_V2: 'https://widget.radioking.io',
  },
  auth: {
    isOnManagerLoginEnable: false,
    redirectUrl:
      'https://www.radioking.com/signin?redirect=https:%2F%2Fmanager.radioking.com',
  },
  sentry: {
    url: 'https://5755836ebc9947999085e76c383e406f@sentry.io/1487411',
    env: 'prod',
    isEnabled: true,
  },
  helphero: {
    id: 'jG4KWAC9cc2',
    userIdPrefix: '',
  },
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  cookies: {
    config: {
      domain: '.radioking.com',
      expires: 365,
      secure: true,
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
  intercomId: 'qvkt6qma',
  facebookPlayerAppId: '561167923986789',
  amplitudeApiKey: '3c70d2708b3dfd47859127f15a2f734c',
};
