import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Radio, ShortRadio } from '@app/core/models/Radio';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { ToasterService } from '@radioking/shared/common-services';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'rk-disabled-radio',
  templateUrl: './disabled-radio.component.html',
  styleUrls: ['./disabled-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisabledRadioComponent implements OnInit, OnDestroy {
  @Select(RadioState.currentRadio)
  currentRadio$: Observable<Radio>;

  @Select(RadioState.radios)
  radios$: Observable<ShortRadio[]>;

  @Select(RadioState.currentPlanId)
  radioPlan$: Observable<number>;

  @Select(RadioState.updateOfferUrl)
  updateUrl$: Observable<string>;

  isLoading$: Observable<boolean>;

  swiperConfig: SwiperConfigInterface;

  @ViewChild(SwiperComponent)
  componentRef?: SwiperComponent;

  subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly toaster: ToasterService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.swiperConfig = {
      direction: 'horizontal',
      slidesPerView: 4,
      navigation: {
        prevEl: '.arrow-left',
        nextEl: '.arrow-right',
      },
    };
    const datas$ = combineLatest([
      this.currentRadio$.pipe(filter(data => !!data)),
      this.radios$.pipe(filter(data => !!data)),
    ]);
    this.isLoading$ = datas$.pipe(
      map(() => false),
      startWith(true),
    );
    this.subscription.add(
      combineLatest([this.isLoading$, this.currentRadio$]).subscribe(
        ([loading, radio]) => {
          if (!loading) {
            this.cdr.detectChanges();
          }
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  indexChange() {}

  updateOffer() {
    this.updateUrl$.subscribe(data => {
      window.open(data, '_blank');
    });
  }

  switchToRadio(radioId: number) {
    this.store.dispatch(new SwitchToRadioRequest(radioId)).subscribe(() => {
      this.toaster.success(marker('TOAST.RADIO.CHANGE'));
    });
  }
}
