import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GenresFailure, GenresRequest, GenresSuccess } from './genre.actions';
import { Logger } from '@radioking/shared/logger';
import { GenericDataService } from '@app/core/services/generic-data.service';
import { catchError, map } from 'rxjs/operators';
import { Genre } from '@app/core/models/Genre';
import { Injectable } from '@angular/core';

const log = new Logger('genre store');

export class GenreStateModel {
  genres: Genre[];
}

@State<GenreStateModel>({
  name: 'genre',
  defaults: {
    genres: undefined,
  },
})
@Injectable()
export class GenreState {
  constructor(private readonly genericDataService: GenericDataService) {}

  @Selector()
  static genreList(state: GenreStateModel): Genre[] {
    return state.genres.filter(genre => genre.parentId !== null);
  }

  @Action(GenresRequest)
  getAllGenres(ctx: StateContext<GenreStateModel>, action: GenresRequest) {
    const genres = ctx.getState().genres;
    if (genres) {
      return;
    }
    return this.genericDataService.getAllGenres().pipe(
      map(data => ctx.dispatch(new GenresSuccess(data))),
      catchError(err => ctx.dispatch(new GenresFailure(err))),
    );
  }

  @Action(GenresSuccess)
  add(ctx: StateContext<GenreStateModel>, { genres }: GenresSuccess) {
    const state = ctx.getState();
    ctx.patchState({
      genres,
    });
  }
}
