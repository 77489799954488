<div
  fxLayout="column"
  fxLayoutAlign="space-between"
  class="main"
  [ngClass]="{ 'top-bar-shrinked': (radioPlan$ | async) === 1 }"
>
  <div fxFlex="50px" class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex data-cy="sidenav-title">{{
      title || ('MODAL.DEFAULT.TITLE' | translate)
    }}</span>
    <rk-icon-button
      (click)="close.emit($event)"
      color="white"
      faIcon="fa-times"
      [size]="24"
    ></rk-icon-button>
  </div>

  <div
    class="content"
    [ngClass]="{ 'no-scroll': !enableScrollbar }"
    rkPerfectScrollbar
    #mainContent
    fxFlex
  >
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="!showLinkForCancel; else linkCancel">
    <div
      fxFlex="60px"
      fxLayout="row"
      fxLayoutAlign="flex-end center"
      class="footer"
      *ngIf="!hideBottom"
    >
      <rk-button
        data-cy="sidenav-cancel-button"
        *ngIf="!(isSaving$ | async)"
        btnStyle="flat"
        [label]="cancelText || ('MODAL.DEFAULT.CANCEL' | translate)"
        (click)="cancel.emit($event)"
      ></rk-button>
      <rk-loader [isLoading]="isSaving$ | async" [size]="25"></rk-loader>
      <rk-button
        data-cy="sidenav-submit-button"
        [type]="'submit'"
        [label]="submitText || ('MODAL.DEFAULT.SAVE' | translate)"
        (click)="submit.emit($event)"
        [disabled]="disabledSubmit || (isSaving$ | async)"
      ></rk-button>
    </div>
  </ng-container>

  <ng-template #linkCancel>
    <div fxFlex="60px" fxLayout="row" fxLayoutAlign="flex-end center" class="footer">
      <rk-button
        data-cy="sidenav-cancel-button"
        *ngIf="!(isSaving$ | async)"
        btnStyle="link"
        [label]="cancelText || ('MODAL.DEFAULT.CANCEL' | translate)"
        (click)="cancel.emit($event)"
      ></rk-button>
      <rk-loader [isLoading]="isSaving$ | async" [size]="25"></rk-loader>
      <rk-button
        data-cy="sidenav-submit-button"
        [type]="'submit'"
        [label]="submitText || ('MODAL.DEFAULT.SAVE' | translate)"
        (click)="submit.emit($event)"
        [disabled]="disabledSubmit || (isSaving$ | async)"
      ></rk-button>
    </div>
  </ng-template>
</div>
