import { Pipe, PipeTransform } from '@angular/core';
import { exportSecMin } from '@app/shared/utils';

@Pipe({
  name: 'timeFormat',
  pure: true,
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: number, forceHour: boolean = false): string {
    const { minutes, hours, seconds } = exportSecMin(value);

    const paddedSeconds = `${seconds}`.padStart(2, '0');
    const paddedMinutes = `${minutes}`.padStart(2, '0');
    const paddedHours = `${hours}`.padStart(2, '0');
    // console.log('Time format pipe : ', { value, rounded, seconds, minutes, hours });
    if (hours || forceHour) {
      return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    }

    return `${paddedMinutes}:${paddedSeconds}`;
  }
}
