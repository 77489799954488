<button
  [disabled]="disabled"
  (click)="onClick($event)"
  (focus)="focus.emit($event)"
  (blur)="blur.emit($event)"
  [ngClass]="{
    bordered: bordered,
    disabled: disabled,
    noHover: disableHover,
    white: color === 'white',
    'gray-darkest': color === 'gray-darkest',
    black: color === 'black',
    brand: color === 'brand'
  }"
  [ngStyle]="style"
  type="button"
>
  <mat-icon
    fontSet="fa"
    [fontIcon]="faIcon"
    *ngIf="faIcon"
    [ngStyle]="getStyleIcon()"
  ></mat-icon>
  <mat-icon
    [svgIcon]="svgIcon"
    *ngIf="svgIcon"
    [ngStyle]="getStyleIcon()"
    [class]="getSvgColorClass()"
  ></mat-icon>
</button>
