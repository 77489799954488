import {
  Action,
  Actions,
  ofActionDispatched,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  GetPreviousTrack,
  StartLiveTrack,
  StartSoftLiveTrack,
  StopLiveTrack,
  StopSoftLiveTrack,
} from './live-tracking.actions';
import { Logger } from '@radioking/shared/logger';
import { LightApiTrack } from '@app/core/models/LightTrack';
import { combineLatest, interval, merge, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { RadioStartSuccess, RadioStopSuccess } from '@app/core/states/radio.actions';
import { RadioService } from '@app/core/services/radio.service';
import * as moment from 'moment';
import { HelpHeroService } from '@radioking/shared/common-services';
import { patch } from '@ngxs/store/operators';
import { RadioState } from '@app/core/states/radio.state';
import { Injectable } from '@angular/core';

const log = new Logger('liveTracking store');

export class LiveTrackingStateModel {
  currentTrack: LightApiTrack;
  nextTracks: LightApiTrack[];
  previousTracks: LightApiTrack[];
  numberListeners: number;
  status?: 'active' | 'inactive' | 'deleted';
  streamStatus?: 'started' | 'stopped';
  canUpload: boolean;
  live?: {
    started_at: string;
    duration: number;
    broadcaster: {
      username: string;
      slug: string;
      firstname: string;
      lastname: string;
      idfile_avatar: string;
    };
  };
}

@State<LiveTrackingStateModel>({
  name: 'liveTracking',
  defaults: {
    currentTrack: undefined,
    nextTracks: [],
    previousTracks: [],
    numberListeners: 0,
    canUpload: true,
    live: undefined,
  },
})
@Injectable()
export class LiveTrackingState {
  constructor(
    private actions$: Actions,
    private readonly radioService: RadioService,
    private readonly tourService: HelpHeroService,
    private readonly store: Store,
  ) {}

  @Selector()
  static listeners(state: LiveTrackingStateModel): number {
    return state.numberListeners;
  }

  @Selector()
  static isLive(state: LiveTrackingStateModel): boolean {
    return !!state.live;
  }

  @Selector()
  static durationLive(state: LiveTrackingStateModel): number {
    if (!state.live) {
      return 0;
    }
    return moment(state.live.started_at).valueOf();
  }

  @Selector()
  static isBroadcasting(state: LiveTrackingStateModel): boolean {
    return state.streamStatus === 'started';
  }

  @Selector()
  static status(state: LiveTrackingStateModel): string {
    return state.streamStatus;
  }

  @Selector()
  static broadcaster(state: LiveTrackingStateModel): object {
    return state.live ? state.live.broadcaster : null;
  }

  @Selector()
  static currentTrack(state: LiveTrackingStateModel): LightApiTrack {
    return (
      state.currentTrack || {
        duration: 0,
        artist: '',
        title: '',
        cover: '',
        album: '',
        buy_link: '',
        id: 0,
      }
    );
  }

  @Selector()
  static nextTracks(state: LiveTrackingStateModel): LightApiTrack[] {
    return state.nextTracks || [];
  }

  @Selector()
  static canUpload(state: LiveTrackingStateModel): boolean {
    return state.canUpload;
  }

  @Selector()
  static previousTrack(state: LiveTrackingStateModel): LightApiTrack {
    return state.previousTracks.length > 1 ? state.previousTracks[1] : null;
  }

  @Selector()
  static nextTrack(state: LiveTrackingStateModel): LightApiTrack {
    return state.nextTracks.length ? state.nextTracks[0] : null;
  }

  @Action(StartSoftLiveTrack)
  softTrackLive(
    ctx: StateContext<LiveTrackingStateModel>,
    { idRadio }: StartSoftLiveTrack,
  ) {
    const stopingConditions$ = merge(
      this.actions$.pipe(
        ofActionDispatched(
          RadioStartSuccess,
          StopSoftLiveTrack,
          StartSoftLiveTrack,
          StartLiveTrack,
        ),
      ),
    );

    return merge(interval(15000), of('')).pipe(
      takeUntil(stopingConditions$),
      mergeMap(() => this.trackRadioStatus(ctx, idRadio)),
      tap(status => {
        if (status && LiveTrackingState.isBroadcasting(ctx.getState())) {
          ctx.dispatch(new StopSoftLiveTrack());
          ctx.dispatch(new StartLiveTrack(idRadio));
        }
      }),
    );
  }

  @Action(StartLiveTrack)
  trackLive(ctx: StateContext<LiveTrackingStateModel>, { idRadio }: StartLiveTrack) {
    const stopingConditions$ = this.actions$.pipe(
      ofActionDispatched(
        RadioStopSuccess,
        StopLiveTrack,
        StartLiveTrack,
        StartSoftLiveTrack,
      ),
    );

    const smallPeriods$ = merge(interval(15000), of('')).pipe(
      takeUntil(stopingConditions$),
      mergeMap(() => {
        return combineLatest([
          this.trackListeners(ctx, idRadio),
          this.trackRadioStatus(ctx, idRadio),
        ]);
      }),
    );

    const longerPeriods$ = merge(interval(30000), of('')).pipe(
      takeUntil(stopingConditions$),
      mergeMap(() => {
        const slug = this.store.selectSnapshot(RadioState.currentSlug);
        return this.incomingTracks(ctx, slug);
      }),
    );

    const trackPeriod$ = merge(interval(10000), of('')).pipe(
      takeUntil(stopingConditions$),
      mergeMap(() => {
        const slug = this.store.selectSnapshot(RadioState.currentSlug);
        return this.currentTrack(ctx, slug);
      }),
    );

    return combineLatest([smallPeriods$, longerPeriods$, trackPeriod$]).pipe(
      tap(([[listeners, status], incoming, current]) => {
        log.debug('LIVE TRACK STATUS : ', {
          listeners,
          status,
          current,
          incoming,
        });
        if (!LiveTrackingState.isBroadcasting(ctx.getState()) && status) {
          ctx.dispatch(new StopLiveTrack());
          ctx.dispatch(new StartSoftLiveTrack(idRadio));
        }
      }),
    );
  }

  @Action(GetPreviousTrack)
  getPreviousTrack(ctx: StateContext<LiveTrackingStateModel>) {
    const slug = this.store.selectSnapshot(RadioState.currentSlug);
    return this.previousTracks(ctx, slug);
  }

  currentTrack(ctx: StateContext<LiveTrackingStateModel>, slug: string): Observable<any> {
    return this.radioService.getCurrentPlayingTrack(slug).pipe(
      tap(track => {
        ctx.patchState({ currentTrack: track });
      }),
      map(() => true),
      catchError(err => of(false)),
    );
  }

  incomingTracks(
    ctx: StateContext<LiveTrackingStateModel>,
    slug: string,
  ): Observable<any> {
    return this.radioService.getIncomingTracks(slug).pipe(
      tap(tracks => {
        ctx.patchState({ nextTracks: tracks });
      }),
      map(() => true),
      catchError(err => of(false)),
    );
  }

  previousTracks(
    ctx: StateContext<LiveTrackingStateModel>,
    slug: string,
  ): Observable<any> {
    return this.radioService.getPreviousTracks(slug).pipe(
      tap(tracks => {
        ctx.setState(
          patch({
            previousTracks: tracks,
          }),
        );
      }),
      map(() => true),
      catchError(err => of(false)),
    );
  }

  trackListeners(
    ctx: StateContext<LiveTrackingStateModel>,
    idRadio: number,
  ): Observable<any> {
    return this.radioService.getListenerOfRadio(idRadio).pipe(
      tap(num => {
        ctx.patchState({ numberListeners: num });
      }),
      map(() => true),
      catchError(err => of(false)),
    );
  }

  trackRadioStatus(
    ctx: StateContext<LiveTrackingStateModel>,
    idRadio: number,
  ): Observable<any> {
    return this.radioService.getRadioStatus(idRadio).pipe(
      tap(data => {
        ctx.patchState({
          live: data.live,
          status: data.status,
          streamStatus: data.stream_status,
          canUpload: data.can_upload,
        });
        if (data.stream_status === 'started') {
          this.tourService.registerAsRadioStarted();
        }
      }),
      map(() => true),
      catchError(err => of(false)),
    );
  }
}
