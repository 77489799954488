import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CountriesFailure, CountriesRequest, CountriesSuccess } from './country.actions';
import { Logger } from '@radioking/shared/logger';
import { Country } from '@app/core/models/Country';
import { catchError, map } from 'rxjs/operators';
import { GenericDataService } from '@app/core/services/generic-data.service';
import { SugestionInput } from '@radioking/ui/input';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Injectable } from '@angular/core';

const log = new Logger('country store');

export class CountryStateModel {
  countries: Country[];
}

@State<CountryStateModel>({
  name: 'country',
  defaults: {
    countries: undefined,
  },
})
@Injectable()
export class CountryState {
  constructor(private readonly genericDataService: GenericDataService) {}

  @Selector()
  static countryListWihoutUnknown(state: CountryStateModel): Country[] {
    return state.countries.filter(country => country.code !== 'O1');
  }

  @Selector()
  static countryList(state: CountryStateModel): Country[] {
    return state.countries;
  }

  @Selector()
  static countryListForChips(state: CountryStateModel): SugestionInput[] {
    return state.countries.map(country => {
      if (country.code === 'O1') {
        return {
          id: country.code,
          name: country.name,
          translation: marker('SETTINGS.FORM.UNKNOWN_LOCATION'),
        };
      }
      return {
        id: country.code,
        name: country.name,
      };
    });
  }

  @Action(CountriesRequest)
  getAllCountries(ctx: StateContext<CountryStateModel>, action: CountriesRequest) {
    const countries = ctx.getState().countries;
    if (countries) {
      return;
    }
    return this.genericDataService.getCountries().pipe(
      map(data => ctx.dispatch(new CountriesSuccess(data))),
      catchError(err => ctx.dispatch(new CountriesFailure(err))),
    );
  }

  @Action(CountriesSuccess)
  add(ctx: StateContext<CountryStateModel>, { countries }: CountriesSuccess) {
    ctx.patchState({
      countries,
    });
  }
}
