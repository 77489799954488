import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rk-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss'],
})
export class UnsavedChangesModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UnsavedChangesModalComponent>) {}

  ngOnInit() {}

  onSubmit() {
    this.dialogRef.close(true);
  }
  onClose() {
    this.dialogRef.close(false);
  }
}
