import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { Store } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';

@Pipe({
  name: 'toRadioTz',
  pure: false,
})
export class ToRadioTzPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(value: string | moment.Moment, alreadyMoment?: boolean): moment.Moment {
    const timezoneRadio = this.store.selectSnapshot(RadioState.timezone);
    if (alreadyMoment) {
      const momentValue = <moment.Moment>value;
      return momentValue.tz(timezoneRadio);
    }
    return moment(value).tz(timezoneRadio);
  }
}
