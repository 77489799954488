<div class="top-radio-sidebar" (click)="placeMatMenu()" [matMenuTriggerFor]="appMenu">
  <div class="background"></div>
  <div fxLayout="row" fxLayoutAlign="start center" class="top-radio-bar">
    <div><img [src]="logoUrl$ | async" data-cy="radio-sidebar-logo-img" /></div>
    <div class="radio-name-elipsis">
      <span data-cy="radio-sidebar-radio-name"> {{ name$ | async }} </span>
    </div>
    <div>
      <rk-icon-button
        svgIcon="dot-standing"
        color="white"
        [size]="26"
        [style]="{ 'pointer-events': 'none' }"
      ></rk-icon-button>
    </div>
  </div>
</div>

<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="myRadioPage()">
    <mat-icon fontSet="fas" fontIcon="fa-compass"></mat-icon>
    <span translate>SIDEBAR.MENU.MY_RADIO_PAGE</span>
  </button>
  <ng-container *rkHasRole="'__radio__'">
    <button *ngIf="(isRadioStarted$ | async)" mat-menu-item (click)="restartRadio()">
      <mat-icon fontSet="fa" fontIcon="fa-sync-alt"></mat-icon>
      <span translate>SIDEBAR.MENU.RESTART_RADIO</span>
    </button>
    <button *ngIf="(isRadioStarted$ | async)" mat-menu-item (click)="stopRadio()">
      <mat-icon fontSet="fa" fontIcon="fa-power-off"></mat-icon>
      <span translate>SIDEBAR.MENU.STOP_RADIO</span>
    </button>
  </ng-container>

  <ng-container *rkHasRole="'__settings__'">
    <ng-container *ngIf="(currentRadioId$ | async) as radioId">
      <button mat-menu-item (click)="goToSettings(radioId)">
        <mat-icon fontSet="fa" fontIcon="fa-wrench"></mat-icon>
        <span translate>SIDEBAR.MENU.SETTINGS</span>
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="(hasMoreThanOneRadio$ | async)">
    <div class="menu-separator"></div>
    <button mat-menu-item (click)="openDialogChangeRadio()">
      <mat-icon fontSet="fa" fontIcon="fa-exchange-alt"></mat-icon>
      <span translate>SIDEBAR.MENU.CHANGE_RADIO</span>
    </button>
  </ng-container>
</mat-menu>
