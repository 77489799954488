import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { AppState } from '@app/core/states/app.state';
import { Select, Store } from '@ngxs/store';
import {
  catchError,
  filter,
  finalize,
  flatMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Logger } from '@radioking/shared/logger';
import {
  AuthenticationService,
  TokenItem,
} from '@app/core/authentication/authentication.service';
import { AuthState } from '@app/core/states/auth.state';
import { Logout, RedirectToLogin } from '@app/core/states/auth.actions';
import * as Cookies from 'js-cookie';

const log = new Logger('BearerInterceptor');

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;

  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  @Select(AuthState.getAccessToken)
  token$: Observable<string>;

  constructor(private readonly store: Store, private readonly injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.token$.pipe(
      take(1),
      flatMap(_ => {
        const token = Cookies.get(environment.cookies.tokenKey) || '';
        if (!token) {
          log.debug('No token found');
          return next.handle(request);
        }
        // log.debug('Token found, added to the request ');
        return next.handle(this.addToken(request, token));
      }),
      catchError(error => {
        log.debug('Entering catchError');
        if (error instanceof HttpErrorResponse) {
          if ((<HttpErrorResponse>error).status === 401) {
            if (
              !error.url.endsWith('oauth/refresh') &&
              !error.url.endsWith('oauth/logout')
            ) {
              return this.handle401Error(request, next);
            } else if (error.url.endsWith('oauth/logout')) {
              return this.store.dispatch(new RedirectToLogin());
            } else {
              const authService = this.injector.get(AuthenticationService);

              return authService.logoutFromInterceptor();
            }
          }
        }
        log.debug('Returning throwError');
        return throwError(error);
      }),
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (req.url.endsWith('oauth/refresh')) {
      return req;
    }
    return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    log.debug('Entering handle401Error');
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      const refreshToken = Cookies.get(environment.cookies.refreshTokenKey) || '';

      const authService = this.injector.get(AuthenticationService);

      return authService.refreshTokenWithDispatch(refreshToken).pipe(
        switchMap((newToken: TokenItem) => {
          if (newToken && newToken.access_token) {
            this.tokenSubject.next(newToken.access_token);
            return next.handle(this.addToken(req, newToken.access_token));
          }

          // If we don't get a new token, we are in trouble so logout.
          return authService.logoutFromInterceptor();
        }),
        catchError(error => {
          // test if error is only 401
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 401:
                return authService
                  .logoutFromInterceptor()
                  .pipe(switchMap(() => throwError(new Error('Loggout'))));
            }
          }
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
      /*
        .catch(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.authService.logoutFromInterceptor();
        })
        .finally(() => {
          this.isRefreshingToken = false;
        });
        */
    }
    return this.tokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(token => next.handle(this.addToken(req, token))),
    );
  }

  /*
  handle400Error(error) {é
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.authService.logoutFromInterceptor();
    }

    return Observable.throw(error);
  }

  */
}
