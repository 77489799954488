import { RootBac } from '@app/library/models/bac.model';
import { HttpErrorResponse } from '@angular/common/http';
import { UploadedTrackApiModel } from '@app/core/states/upload.state';
import { Playlist } from '@app/library/models/playlist.model';

const tag = '[Upload]';

export class UploadFileList {
  static readonly type = `${tag} upload file list`;
  constructor(
    public fileList: FileList,
    public box: RootBac,
    public playlist: Playlist = null,
  ) {}
}

export class UploadFileRequest {
  static readonly type = `${tag} upload file request`;
  constructor(
    public file: File,
    public id: number,
    public box: RootBac,
    public playlist: Playlist = null,
  ) {}
}

export class UploadFileSuccess {
  static readonly type = `${tag} upload file success`;
  constructor(
    public track: UploadedTrackApiModel,
    public fileID: string,
    public box: string,
    public playlist: Playlist,
  ) {}
}

export class UploadRequestTracking {
  static readonly type = `${tag} request tracking upload state`;
  constructor(
    public track: UploadedTrackApiModel,
    public fileID: string,
    public box: string,
    public playlist: Playlist,
  ) {}
}

export class UploadTracking {
  static readonly type = `${tag} tracking upload state`;
  constructor(
    public track: UploadedTrackApiModel,
    public fileID: string,
    public box: string,
    public playlist: Playlist,
  ) {}
}

export class UploadFileFailure {
  static readonly type = `${tag} upload file error`;
  constructor(public error: HttpErrorResponse, public fileID: string) {}
}

export class UploadFileWrongFormat {
  static readonly type = `${tag} upload file wrong format`;
  constructor(public translateParams: object) {}
}

export class CancelUploadFile {
  static readonly type = `${tag} cancel upload file `;
  constructor(public id: number) {}
}

export class RetryUploadFile {
  static readonly type = `${tag} retry upload file`;
  constructor(
    public file: File,
    public box: RootBac,
    public id: number,
    public playlist: Playlist = null,
  ) {}
}

export class RemoveUploadFile {
  static readonly type = `${tag} remove upload file`;
  constructor(public id: number) {}
}

export class ClearUploadList {
  static readonly type = `${tag} clear upload list`;
  constructor() {}
}

export class PendingUploadFile {
  static readonly type = `${tag} upload but pending item`;
  constructor(
    public file: File,
    public box: RootBac,
    public id: number,
    public playlist: Playlist = null,
  ) {}
}

export class StartUploadFile {
  static readonly type = `${tag} upload starting item`;
  constructor(
    public file: File,
    public box: RootBac,
    public id: number,
    public playlist: Playlist = null,
  ) {}
}

export class DiskSpaceExceeded {
  static readonly type = `${tag} error disk space exceeded`;
  constructor() {}
}
