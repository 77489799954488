import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { Observable } from 'rxjs';

@Directive({
  selector: '[rkIsOwner]',
})
export class IsOwnerDirective implements OnInit {
  @Select(RadioState.isCustomer)
  isCustomer$: Observable<boolean>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.isCustomer$.subscribe(customer => {
      if (customer) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
