<div class="root" [ngClass]="getClasses()">
  <mat-icon fontSet="fa" fontIcon="fa-search"></mat-icon>
  <input
    type="text"
    #inputRef
    placeholder="{{
      type === 'media'
        ? ('MEDIA.SEARCH_BAR.PLACEHOLDER' | translate)
        : ('PLAYLIST.SEARCH_BAR.PLACEHOLDER' | translate)
    }}"
    (focus)="onFocus($event)"
    (focusout)="onFocusOut()"
    (blur)="onBlur($event)"
    (input)="onChange($event)"
  />
  <rk-icon-button
    *ngIf="showCross || value !== '' || type === 'playlist'"
    (click)="onClear()"
    faIcon="fa-times"
    color="gray-darkest"
  ></rk-icon-button>
</div>
