import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'rk-nav-vertical-item',
  templateUrl: './nav-vertical-item.component.html',
  styleUrls: ['./nav-vertical-item.component.scss'],
})
export class RkNavVerticalItemComponent {
  @HostBinding('class')
  classes = 'nav-item';

  @Input()
  radioId: number;

  @Input()
  item: any;

  constructor() {}
}
