import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { MainSideNavService } from '@app/core/services/main-side-nav.service';

@Component({
  selector: 'rk-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FuseNavigationComponent {
  @Input()
  layout = 'vertical';

  @Input()
  navigation: any;

  currentRadioId$: Observable<number>;

  constructor(
    private readonly store: Store,
    private readonly mainSideNavService: MainSideNavService,
  ) {
    this.currentRadioId$ = this.store.select(RadioState.currentRadioId).pipe(
      filter(val => val !== undefined),
      startWith(0),
    );
  }

  clickNavigation() {
    if (window.innerWidth < 1025) {
      this.mainSideNavService.toggleSideNav();
    }
  }
}
