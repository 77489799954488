import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoRadioComponent } from './components/pages/no-radio/no-radio.component';
import { SharedModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { BlurryBackgroundComponent } from './components/content/blurry-background/blurry-background.component';
import { DisabledRadioComponent } from './components/pages/disabled-radio/disabled-radio.component';
import { MaterialModule } from '@app/material.module';

import { StartupModalComponent } from './components/pages/startup-modal/startup-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    MaterialModule,
    SwiperModule,
  ],
  declarations: [
    NoRadioComponent,
    BlurryBackgroundComponent,
    DisabledRadioComponent,
    StartupModalComponent,
  ],
})
export class ExternalRoutesModule {}
