import { Directive } from '@angular/core';
import { ShowHideDirective } from '@angular/flex-layout';

const selector = `[fxHide.gt.ipad]`;
const inputs = ['fxHide.gt.ipad'];
@Directive({
  selector,
  inputs,
})
export class CustomShowHideDirective extends ShowHideDirective {
  protected inputs = inputs;
}
