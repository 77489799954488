<img src="/assets/logo-rk-white.png" alt="logo-rk" />
<div class="startup-modal">
  <form *ngIf="timezones$ | async; else loading" [formGroup]="startupForm" novalidate>
    <div class="modal-content">
      <h1 translate>GLOBAL.STARTUP.TITLE</h1>
      <div class="panic" translate>GLOBAL.STARTUP.NO_PANIC</div>
      <rk-form-field></rk-form-field>
      <div class="form-input rk-input-wrapper">
        <input
          type="text"
          id="name"
          rkInput
          formControlName="name"
          required
          [placeholder]="'GLOBAL.STARTUP.MY_RADIO' | translate"
          maxlength="20"
        />
        <rk-form-error
          *ngIf="startupErrors.name.required"
          [errorMessage]="'SETTINGS.GENERAL.FORM_ERROR.NAME_RADIO_REQUIRED' | translate"
        ></rk-form-error>
      </div>

      <div
        class="submit-form"
        *ngIf="!(isSaving$ | async); else saving"
        (click)="submit()"
      >
        <span class="start-broadcasting" translate
          >GLOBAL.STARTUP.START_BROADCASTING</span
        >
        <img src="assets/icons/long-arrow-right.svg" />
      </div>

      <ng-template #tzModal>
        <rk-modal-wrapper
          [title]="'GLOBAL.STARTUP.MODAL_TIMEZONE_TITLE' | translate"
          [submitText]="'GLOBAL.STARTUP.EDIT_TIMEZONE' | translate"
          (cancel)="closeDialog()"
          (close)="closeDialog()"
          (submit)="editTimezone()"
        >
          <div class="form-input rk-input-wrapper">
            <rk-dropdown-autocomplete
              [label]="'SETTINGS.GENERAL.TIMEZONE' | translate"
              [placeholder]="'GLOBAL.STARTUP.CHOOSE_TIMEZONE' | translate"
              formControlName="timezone"
              [data]="timezones$ | async"
              [modal]="true"
            ></rk-dropdown-autocomplete>
          </div>
        </rk-modal-wrapper>
      </ng-template>
    </div>
    <div class="modal-footer" *ngIf="userTimezone">
      <span
        [innerHTML]="
          'GLOBAL.STARTUP.CURRENT_TIMEZONE' | translate: { tz: userTimezone.name }
        "
      ></span>
      <span class="edit-timezone" (click)="editTimezoneModal()" translate
        >GLOBAL.STARTUP.EDIT_TIMEZONE</span
      >
    </div>
  </form>
</div>

<ng-template #loading>
  <div class="mt-80">
    <rk-loader [isLoading]="true" [size]="60" [center]="true"></rk-loader>
  </div>
</ng-template>

<ng-template #saving>
  <div>
    <rk-loader [isLoading]="true" [size]="50" [center]="true"></rk-loader>
  </div>
</ng-template>
