import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IframeHelperService {
  private iframeSubject = new BehaviorSubject<boolean>(false);
  private sidenavSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.sidenavSubject.asObservable().subscribe(value => {
      return localStorage.setItem('menuOpen', JSON.stringify(value));
    });
  }

  setIframeOpen() {
    this.iframeSubject.next(true);
  }

  setIframeClosed() {
    this.iframeSubject.next(false);
  }

  getIframeStatus(): Observable<boolean> {
    return this.iframeSubject.asObservable();
  }

  setSidenavOpen(value: boolean) {
    this.sidenavSubject.next(value);
  }

  getSidenavStatus(): Observable<boolean> {
    return this.sidenavSubject.asObservable();
  }
}
