import { Injectable } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { CommandStatus } from '@app/core/models/ApiProcess';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  catchError,
  filter,
  flatMap,
  map,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { DataApiContainer } from '@app/core/models/DataApiContainer';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ProcessApiService {
  constructor(private readonly httpClient: HttpClient) {}

  waitForRadioProcess(uuid: string): Observable<CommandStatus> {
    let isDone = false;
    return interval(1000).pipe(
      takeWhile(() => !isDone),
      switchMap(() =>
        this.httpClient.get<DataApiContainer<CommandStatus>>(
          `${environment.urls.MAIN_API}/process/${uuid}/status`,
        ),
      ),
      map(dta => dta.data),
      tap(cmd => {
        if (cmd.status === 'error') {
          throw cmd;
        }
      }),
      filter(cmd => cmd.status === 'done'),
      tap(() => {
        isDone = true;
      }),
    );
  }

  waitForTrackProcess(
    uuid: string,
    radioId: number,
    trackId: number,
  ): Observable<CommandStatus> {
    let isDone = false;
    let call = 0;
    return interval(4000).pipe(
      takeWhile(() => !isDone),
      tap(() => {
        call = call + 1;
      }),
      switchMap(() =>
        this.httpClient.get<CommandStatus>(
          `${
            environment.urls.MAIN_API_V2
          }/radio/${radioId}/track/${trackId}/process/${uuid}`,
        ),
      ),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          const done: CommandStatus = { status: 'done' };
          return of(done);
        }
        throw err;
      }),
      tap(cmd => {
        if (cmd.status === 'error') {
          throw cmd;
        }
      }),
      map<CommandStatus, CommandStatus>(cmd => {
        if (call >= 5) {
          return { status: 'done' };
        }
        return cmd;
      }),
      filter(cmd => cmd.status === 'done'),
      tap(() => {
        isDone = true;
      }),
    );
  }

  waitForPlaylistProcess(
    idRadio: number,
    idPlaylist: number,
    uuid: string,
  ): Observable<CommandStatus> {
    let isDone = false;
    return interval(1000).pipe(
      takeWhile(() => !isDone),
      switchMap(() =>
        this.httpClient.get<CommandStatus>(
          `${
            environment.urls.MAIN_API_V2
          }/radio/${idRadio}/playlist/${idPlaylist}/process/${uuid}`,
        ),
      ),
      tap(cmd => {
        if (cmd.status === 'error') {
          throw cmd;
        }
      }),
      filter(cmd => cmd.status === 'done'),
      tap(() => {
        isDone = true;
      }),
    );
  }
}
