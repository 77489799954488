import { UserData } from '@app/core';

const tag = '[auth]';

export class SetUser {
  static readonly type = `${tag} set username`;

  constructor(public user: UserData, public isRedirection: boolean) {}
}

export class RequestUser {
  static readonly type = `${tag} request me`;

  constructor() {}
}

export class SetTokens {
  static readonly type = `${tag} set tokens`;

  constructor(public token: string, public expire: number, public refreshToken: string) {}
}

export class LoginRequest {
  static readonly type = `${tag} try login method`;

  constructor(public login: string, public password: string) {}
}

export class LoginError {
  static readonly type = `${tag} error loggin`;

  constructor(public error: Error) {}
}

export class Logout {
  static readonly type = `${tag} set user disconnected status`;

  constructor() {}
}

export class LogoutClear {
  static readonly type = `${tag} set user disconnected status and clear storage`;

  constructor() {}
}

export class RedirectToLogin {
  static readonly type = `${tag} redirect to login page`;

  constructor() {}
}
