<button
  [disabled]="isDisabled()"
  (click)="onClick($event)"
  (focus)="focus.emit($event)"
  (blur)="blur.emit($event)"
  [ngClass]="getBtnClasses()"
  [ngStyle]="style"
  [type]="type"
>
  <mat-icon
    [fontSet]="faFont"
    [fontIcon]="faIcon"
    *ngIf="faIcon"
    [ngStyle]="getStyleIcon()"
  ></mat-icon>
  <mat-icon
    [svgIcon]="svgIcon"
    *ngIf="svgIcon"
    [ngStyle]="getStyleIcon()"
    [class]="getSvgColorClass()"
    [class.hidden]="loading"
  ></mat-icon>
  <span class="txt-button" *ngIf="label">{{ label }}</span>
  <span class="overlay-loading" *ngIf="loading">
    <rk-loader [isLoading]="true" [size]="20"></rk-loader>
  </span>
</button>
