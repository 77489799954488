import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

import { Logger } from '@radioking/shared/logger';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { AuthState } from '@app/core/states/auth.state';
import { RedirectToLogin } from '@app/core/states/auth.actions';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(AuthState.isAuthenticated).pipe(
      map((isAuth: boolean) => {
        if (isAuth) {
          return true;
        }
        log.debug('Not authenticated, redirecting...');
        this.store.dispatch(new RedirectToLogin());
        return false;
      }),
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
