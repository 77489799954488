<div id="main-navigation" class="nav vertical">
  <!-- Vertical Navigation Layout -->
  <ng-container *ngIf="(currentRadioId$ | async) as radioId">
    <ng-container *ngFor="let item of navigation">
      <ng-container *ngIf="!item.hidden">
        <rk-nav-vertical-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
          [radioId]="radioId"
          (clickNavigation)="clickNavigation()"
        ></rk-nav-vertical-collapse>
        <rk-nav-vertical-item
          (click)="clickNavigation()"
          *ngIf="item.type == 'item'"
          [item]="item"
          [radioId]="radioId"
        ></rk-nav-vertical-item>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- / Vertical Navigation Layout -->
</div>
