import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rk-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  cancelText: string;

  @Input()
  submitText: string;

  @Input()
  isSubmitDisabled: boolean;

  @Input()
  loading: boolean;

  @Input()
  errorMessage: string;

  @Input()
  showSubmitBlock = true;

  @Input()
  hideCancelOnLoading = false;

  @Input()
  wide = false;

  @Input()
  dialogRef: MatDialogRef<any>;

  @Output()
  close = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  @Output()
  submit = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onSubmit() {
    if (this.dialogRef) {
      this.dialogRef.close(true);
    }
    this.submit.emit();
  }
  onCancel() {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
    this.cancel.emit();
  }
  onAbort() {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
    this.close.emit();
  }

  showCancelButon() {
    if (!this.hideCancelOnLoading) {
      return true;
    }
    return !this.loading;
  }
}
