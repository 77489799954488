const tag = '[LiveTracking]';

export class StartLiveTrack {
  static readonly type = `${tag} start tracking radio`;

  constructor(public idRadio: number) {}
}

export class StopLiveTrack {
  static readonly type = `${tag} stop tracking radio`;

  constructor() {}
}

export class StartSoftLiveTrack {
  static readonly type = `${tag} start soft tracking radio`;

  constructor(public idRadio: number) {}
}

export class StopSoftLiveTrack {
  static readonly type = `${tag} stop tracking radio`;

  constructor() {}
}

export class GetPreviousTrack {
  static readonly type = `${tag} get previous track`;
  constructor() {}
}
