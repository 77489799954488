import { Genre } from '@app/core/models/Genre';
import { Timezone } from '@app/core/models/Timezone';
import { Geoblock } from '@app/core/models/Geoblock';
import * as moment from 'moment';
import { RadioApiModel } from '@app/core/services/radio.service';
import { GenericDataService } from '@app/core/services/generic-data.service';

export interface DemoStatus {
  isDemo: boolean;
  expireDate?: moment.Moment;
  expireDays?: number;
}

export interface Plan {
  description: string;
  disk: number;
  downloadChronics: number;
  idPlan: number;
  listeningTime: number;
}

export interface Limits {
  diskSpace: number;
  downloadChronics: number;
  listeningTime: number;
}

export interface Directory {
  idRadio: number;
  appearOrange: boolean;
  appearIcecast: boolean;
  appearRadioline: boolean;
}

export interface Stream {
  idStream: number;
  idRadio: number;
  idPrimary: number;
  format: string;
  bitrate: number;
  frequency: number;
  status: string;
  type: number;
}

export interface Constraints {
  titleRepeat: number;
  artistRepeat: number;
}

export class Radio {
  public constructor(
    public id: number,
    public name: string,
    public slug: string,
    public lang: string,
    public logo: string,
    public isBroadcasting: boolean,
    public exceededListening: boolean,
    public ranking: number,
    public followers: number,
    public serviceId: number,
    public status: 'active' | 'inactive' | 'deleted',
    public streamStatus: 'started' | 'stopped',
    public plan: Plan,
    public limits: Limits,
    public demoStatus: DemoStatus,
    public timezone: string,
    public streams: Stream[],
  ) {}
}

export class ShortRadio {
  public constructor(
    public id: number,
    public name: string,
    public status: 'active' | 'inactive' | 'deleted',
    public logo: string,
    public role: 'user' | 'customer',
  ) {}
}

export class RadioSettings {
  public constructor(
    public slogan: string,
    public genres: Genre[],
    public description: string,
    public website: string,
    public lang: string,
    public country: string,
    public timezone: string,
    public streams: Stream[],
    public constraints: Constraints,
    public fadeoutDefault: number,
    public autoCueEnabled: number,
    public mixThreshold: number,
    public normThreshold: number,
    public headerLogo: string,
    public appearOnPlatform: boolean,
    public commentsEnabled: boolean,
    public directory: Directory,
    public geoblock: Geoblock,
    public disconnectAfter: number,
    public apps: {
      twitter: {
        oauthToken: string;
        username: string;
      };
    },
    public facebook: string,
    public twitter: string,
    public livefeedSettings: {
      facebookEnabled: number;
      twitterEnabled: number;
    },
    public tweet: {
      enabled: number;
      cover: number;
      message: string;
      repeat: number;
    },
    public itunesAffiliateID: string,
    public coverDefault: string,
    public coverConfig: string,
    public liveTitle: {
      artist: string;
      isEnabled: boolean;
      title: string;
      buyLink: string;
      cover: string;
    },
  ) {}
}

export interface RadioAndSettings {
  radio: Radio;
  settings: RadioSettings;
}

export class RadioLiveInfo {
  public constructor(
    public serverUrl: string,
    public port: number,
    public mountPoint: string,
  ) {}
}

export interface RadioNameTimezone {
  name: string;
  timezone: Timezone;
}

export function convertToRadio(apiRadio: RadioApiModel): Radio {
  return new Radio(
    apiRadio.idradio,
    apiRadio.name,
    apiRadio.slug,
    apiRadio.lang,
    apiRadio.logo,
    apiRadio.is_broadcasting,
    !!apiRadio.exceeded_listening,
    apiRadio.rang,
    apiRadio.followers,
    apiRadio.serviceid,
    apiRadio.status,
    apiRadio.stream_status,
    {
      description: apiRadio.plan.description,
      disk: apiRadio.plan.disk,
      idPlan: apiRadio.plan.idplan,
      downloadChronics: apiRadio.plan.download_chronics,
      listeningTime: apiRadio.plan.listening_time,
    },
    apiRadio.limits
      ? {
          diskSpace: apiRadio.limits.disk,
          downloadChronics: apiRadio.limits.download_chronics,
          listeningTime: apiRadio.limits.listening_time,
        }
      : null,
    {
      isDemo: apiRadio.demo.is_demo,
      expireDate: moment(apiRadio.demo.expire_date),
      expireDays: apiRadio.demo.expire_days,
    },
    apiRadio.timezone,
    apiRadio.streams.map(data => ({
      idStream: data.idstream,
      idRadio: data.idradio,
      idPrimary: data.idprimary,
      format: data.format,
      bitrate: data.bitrate,
      frequency: data.frequency,
      status: data.status,
      type: data.type,
    })),
  );
}

export function convertToSettings(apiRadio: RadioApiModel): RadioSettings {
  return new RadioSettings(
    apiRadio.slogan,
    apiRadio.genres ? GenericDataService.convertToGenre(apiRadio.genres) : [],
    apiRadio.description,
    apiRadio.website,
    apiRadio.lang,
    apiRadio.country,
    apiRadio.timezone,
    apiRadio.streams.map(data => ({
      idStream: data.idstream,
      idRadio: data.idradio,
      idPrimary: data.idprimary,
      format: data.format,
      bitrate: data.bitrate,
      frequency: data.frequency,
      status: data.status,
      type: data.type,
    })),
    {
      titleRepeat: apiRadio.constraint.title_repeat,
      artistRepeat: apiRadio.constraint.artist_repeat,
    },
    apiRadio.fadeout_default,
    apiRadio.auto_cue_enabled,
    apiRadio.mix_threshold,
    apiRadio.norm_threshold,
    apiRadio.header,
    !!apiRadio.public,
    !!apiRadio.comments_enabled,
    {
      idRadio: apiRadio.directory.idradio,
      appearOrange: !!apiRadio.directory.orange,
      appearIcecast: !!apiRadio.directory.icecast,
      appearRadioline: !!apiRadio.directory.radioline,
    },
    apiRadio.geoblock,
    apiRadio.disconnect_listeners_after,
    {
      twitter: {
        oauthToken: apiRadio.apps.twitter.oauth_token,
        username: apiRadio.apps.twitter.username,
      },
    },
    apiRadio.facebook,
    apiRadio.twitter,
    {
      facebookEnabled: apiRadio.livefeed_settings.facebook_enabled,
      twitterEnabled: apiRadio.livefeed_settings.twitter_enabled,
    },
    apiRadio.tweet,
    apiRadio.itunes_affiliate_id,
    apiRadio.cover_default,
    apiRadio.coverconfig,
    {
      artist: apiRadio.live_title.artist,
      isEnabled: apiRadio.live_title.status === 'active',
      title: apiRadio.live_title.title,
      buyLink: apiRadio.live_title.buy_link,
      cover: apiRadio.live_title.cover,
    },
  );
}
