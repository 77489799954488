import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rk-advanced-tooltip',
  templateUrl: './advanced-tooltip.component.html',
  styleUrls: ['./advanced-tooltip.component.scss'],
})
export class AdvancedTooltipComponent implements OnInit {
  @Input()
  active = true;

  @Select(RadioState.increaseDiskSpaceUrl)
  increaseDiskSpaceUrl$: Observable<string>;

  translate: TranslateService;
  constructor(translate: TranslateService) {
    this.translate = translate;
  }

  ngOnInit() {}

  updateOffer() {
    this.increaseDiskSpaceUrl$.subscribe(data => {
      window.open(data, '_blank');
    });
  }
}
