import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '@env/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.sentry.isEnabled) {
      Sentry.captureException(error.originalError || error);
    }
    throw error;
  }
}
