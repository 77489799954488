import {
  RadioStartFailure,
  RadioStopFailure,
  SwitchToRadioRequest,
} from '@app/core/states/radio.actions';
import { EnableShoutOutSuccess } from '@app/core/states/shout-outs.actions';
import {
  DiskSpaceExceeded,
  UploadFileWrongFormat,
} from '@app/core/states/upload.actions';
import { Error500 } from '@app/core/states/app.actions';
import { PlayError } from '@app/core/states/audio.actions';
import { ToastableItem } from '@radioking/shared/common-services';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const appToasts: ToastableItem[] = [
  {
    action: RadioStartFailure,
    level: 'error',
    translationKey: marker('TOAST.RADIO.FAIL_START'),
  },
  {
    action: RadioStopFailure,
    level: 'error',
    translationKey: marker('TOAST.RADIO.FAIL_STOP'),
  },
  {
    action: SwitchToRadioRequest,
    level: 'info',
    translationKey: marker('TOAST.RADIO.CHANGE'),
  },
  {
    action: EnableShoutOutSuccess,
    level: 'info',
    translationKey: marker('TOAST.SHARE.ENABLE_SHOUTOUTS'),
  },
  {
    action: UploadFileWrongFormat,
    level: 'error',
    translationKey: marker('TOAST.TRACK.UPLOAD_NOT_MP3'),
  },
  {
    action: Error500,
    level: 'error',
    translationKey: marker('TOAST.ERROR.GENERIC_ERROR_500'),
  },
  {
    action: DiskSpaceExceeded,
    level: 'error',
    translationKey: marker('MEDIA.ACTIONS.DISK_SPACE_EXCEEDED'),
  },
  {
    action: PlayError,
    level: 'error',
    translationKey: marker('TOAST.ERROR.COULD_NOT_LISTEN_RADIO'),
  },
];
