import {
  AfterViewInit,
  Directive,
  ElementRef,
  Host,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SortDirective } from './sort.directive';

@Directive({
  selector: '[rkSortBy]',
})
export class SortByDirective implements AfterViewInit, OnDestroy {
  @Input()
  rkSortBy: string;

  sortAscIcon = 'fa-angle-up';
  sortDescIcon = 'fa-angle-down';

  rkSort: SortDirective;

  private subscription: Subscription;

  constructor(
    @Host() rkSort: SortDirective,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.rkSort = rkSort;
  }

  ngAfterViewInit(): void {
    this.subscription = this.rkSort
      .getObservablePredicate()
      .subscribe((predi: string) => {
        if (predi && predi !== '_score' && predi === this.rkSortBy) {
          this.applyClass();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click')
  onClick() {
    if (this.rkSort.predicate && this.rkSort.predicate !== '_score') {
      this.rkSort.sort(this.rkSortBy);
      this.applyClass();
    }
  }

  private applyClass() {
    const childSpan = this.el.nativeElement.children[1];
    let add = this.sortAscIcon;
    if (!this.rkSort.ascending) {
      add = this.sortDescIcon;
    }
    this.renderer.addClass(childSpan, add);
    // this.renderer.setElementClass(childSpan, add, true);
  }
}
