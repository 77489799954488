import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ChangeRadioModalComponent } from '@app/shared/modals/change-radio-modal/change-radio-modal.component';
import {
  RadioRestartRequest,
  RadioStartRequest,
  RadioStopRequest,
} from '@app/core/states/radio.actions';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { environment } from '@env/environment';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'rk-radio-sidebar-header',
  templateUrl: './radio-sidebar-header.component.html',
  styleUrls: ['./radio-sidebar-header.component.scss'],
})
export class RadioSidebarHeaderComponent implements OnInit {
  @Select(RadioState.currentRadioName)
  name$: Observable<string>;

  @Select(RadioState.currentRadioLogo)
  logoUrl$: Observable<string>;

  @Select(RadioState.hasMoreThanOneRadio)
  hasMoreThanOneRadio$: Observable<boolean>;

  @Select(RadioState.currentRadioId)
  currentRadioId$: Observable<number>;

  @Select(LiveTrackingState.isBroadcasting)
  isRadioStarted$: Observable<boolean>;

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {}

  openDialogChangeRadio() {
    this.dialog.open(ChangeRadioModalComponent);
  }

  startBroadcastRadio() {
    this.store.dispatch(new RadioStartRequest());
  }

  stopRadio() {
    this.store.dispatch(new RadioStopRequest());
  }

  restartRadio() {
    this.store.dispatch(new RadioRestartRequest());
  }

  myRadioPage() {
    const slug = this.store.selectSnapshot(RadioState.currentSlug);
    const radioPageUrl = `${environment.urls.RADIOKING}/radio/${slug}`;
    window.open(radioPageUrl, '_blank');
  }

  @Dispatch()
  goToSettings(radioId: number) {
    return new Navigate(['radio', radioId, 'settings']);
  }

  placeMatMenu() {
    const matMenu: HTMLElement = document.querySelector('.mat-menu-panel');
    matMenu.style['position'] = 'absolute';
    matMenu.style.left = '230px';
    matMenu.style.top = '-50px';
  }
}
