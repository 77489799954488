<div
  fxLayout="row"
  fxLayoutAlign=" center"
  *ngIf="(currentTrack$ | async) as currentTrack"
>
  <div class="play-button">
    <button mat-icon-button *ngIf="(isListening$ | async)" (click)="pause()">
      <mat-icon fontSet="fa" fontIcon="fa-pause" color="primary"></mat-icon>
    </button>
    <button mat-icon-button *ngIf="!(isListening$ | async)" (click)="resume()">
      <mat-icon fontSet="fa" fontIcon="fa-play" color="primary"></mat-icon>
    </button>
  </div>

  <div class="main-audio-block" fxLayout="column" fxLayoutAlign="space-around none">
    <div fxLayout="row" fxLayoutAlign="space-between baseline">
      <div class="tracks-info" fxLayout="row" fxLayoutAlign="baseline">
        <span>{{ currentTrack.title }}</span> <span>{{ currentTrack.artist }}</span>
      </div>
      <div class="duration-info" fxLayout="row" fxLayoutAlign="end baseline">
        <span>{{ playerService.onDuration() | async | timeFormat }}</span> <span>/</span>
        <span>{{ playerService.onMaxDuration() | async | timeFormat }}</span>
      </div>
    </div>

    <div class="seek">
      <input
        type="range"
        min="0"
        max="1"
        step="any"
        value="0.45"
        [value]="percentagePlayed$ | async"
        (input)="changeSeekUpdate($event.target.value)"
        (mousedown)="startSeek()"
        (mouseup)="endSeek()"
      />

      <svg width="100%" height="3px">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stop-color="#FF7F50" />
            <stop offset="100%" stop-color="#FF3648" />
          </linearGradient>
        </defs>
        <g>
          <rect fill="#eee" width="100%" height="3" rx="2" ry="2"></rect>
          <rect
            fill="url(#grad1)"
            attr.width="{{ percentagePlayedRenderBars$ | async }}"
            height="3"
            rx="2"
            ry="2"
          ></rect>
        </g>
      </svg>
    </div>
  </div>

  <div fxHide.lt-md fxLayout="row" fxLayoutAlign="center center" class="audio fix-slider">
    <button mat-icon-button (click)="toggleMute()" class="icon-button">
      <mat-icon
        *ngIf="getIconAcordingToVolume()"
        fontSet="fa"
        [fontIcon]="getIconAcordingToVolume()"
      ></mat-icon>
      <mat-icon
        class="svgicon"
        color="primary"
        *ngIf="!getIconAcordingToVolume()"
        svgIcon="volume-mid"
      ></mat-icon>
    </button>
    <mat-slider
      color="primary"
      [value]="getVolume()"
      (input)="setVolme($event.value)"
      [max]="1"
      [min]="0"
      [step]="0.1"
    ></mat-slider>
  </div>
</div>
