import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, Route } from './core';
import { IframeGlueComponent } from './hotglue/iframe-glue/iframe-glue.component';
import { NoRadioComponent } from './external-routes/components/pages/no-radio/no-radio.component';
import { DisabledRadioComponent } from './external-routes/components/pages/disabled-radio/disabled-radio.component';
import { StartupModalComponent } from './external-routes/components/pages/startup-modal/startup-modal.component';
import { RoleOperatorActions } from './core/services/role-helper.service';
import { Roles } from './core/models/Preset';
import { RadioIdResolver } from './core/resolvers/radio-id.resolver';
import { environment } from '@env/environment';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

const loginRoute = environment.auth.isOnManagerLoginEnable
  ? [
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
      },
    ]
  : [];

const routes: Routes = [
  ...loginRoute,
  {
    path: 'no-radio',
    component: NoRadioComponent,
  },
  {
    path: 'radio/:radioId',
    resolve: { radio: RadioIdResolver },
    // component: RadioIdPageComponent,
    canActivate: [AuthenticationGuard], // RolesGuard
    canActivateChild: [AuthenticationGuard], // RolesGuard
    children: [
      {
        path: 'disabled',
        component: DisabledRadioComponent,
      },
      {
        path: 'configure-radio',
        component: StartupModalComponent,
      },
      // protected routes
      Route.withShell([
        {
          path: '',
          loadChildren: () =>
            import('@radioking/rights-router').then(m => m.RightsRouterModule),
          data: {},
        },
        {
          path: 'dashboard',
          loadChildren: () => import('@radioking/dashboard').then(m => m.DashboardModule),
          data: {
            title: marker('SIDENAV.MENU.DASHBOARD'),
            role: {
              type: RoleOperatorActions.ALL,
              roleIds: [Roles.DASHBOARD],
            },
          },
        },
        {
          path: 'about',
          loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
        },
        {
          path: 'media',
          loadChildren: () =>
            import('./library/library.module').then(m => m.LibraryModule),
        },
        {
          path: 'settings',
          loadChildren: () =>
            import('./settings/settings.module').then(m => m.SettingsModule),
          data: {
            title: marker('GLOBAL.WINDOW_TITLE.SETTINGS'),
          },
        },
        {
          path: 'history',
          loadChildren: () => import('@radioking/history').then(m => m.HistoryModule),
          data: {
            title: marker('GLOBAL.WINDOW_TITLE.HISTORY'),
            role: {
              type: RoleOperatorActions.ALL,
              roleIds: [Roles.PLANNING],
            },
          },
        },
        {
          path: 'scheduler',
          data: {
            role: {
              type: RoleOperatorActions.OR,
              operators: [
                {
                  type: RoleOperatorActions.ALL,
                  roleIds: [Roles.PROGRAMS, Roles.LIBRARY],
                },
                {
                  type: RoleOperatorActions.ALL,
                  roleIds: [Roles.PROGRAMS, Roles.LIBRARY, Roles.BREAKS],
                },
                {
                  type: RoleOperatorActions.ALL,
                  roleIds: [Roles.PROGRAMS, Roles.LIBRARY, Roles.SCHEDULE],
                },
                {
                  type: RoleOperatorActions.ALL,
                  roleIds: [Roles.PLANNING, Roles.LIBRARY],
                },
              ],
            },
          },
          children: [
            {
              path: 'programs',
              data: {
                title: marker('SIDENAV.MENU.PROGRAMS'),
                role: {
                  type: RoleOperatorActions.ALL,
                  roleIds: [Roles.PROGRAMS, Roles.LIBRARY],
                },
              },
              component: IframeGlueComponent,
            },
            {
              path: 'break',
              data: {
                title: marker('SIDENAV.MENU.BREAKS'),
                role: {
                  type: RoleOperatorActions.ALL,
                  roleIds: [Roles.PROGRAMS, Roles.LIBRARY, Roles.BREAKS],
                },
              },
              component: IframeGlueComponent,
            },
            {
              path: 'planning',
              data: {
                title: marker('SIDENAV.MENU.PLANNING'),
                roles: {
                  type: RoleOperatorActions.ALL,
                  roleIds: [Roles.PROGRAMS, Roles.LIBRARY, Roles.SCHEDULE],
                },
              },
              component: IframeGlueComponent,
            },
            {
              path: 'generated-planning',
              data: {
                title: marker('SIDENAV.MENU.DAILY_GENERATION'),
                role: {
                  type: RoleOperatorActions.ALL,
                  roleIds: [Roles.PLANNING, Roles.LIBRARY],
                },
              },
              component: IframeGlueComponent,
            },
          ],
        },
        {
          path: 'statistics',
          data: {
            role: Roles.STATISTICS,
            title: marker('SIDENAV.MENU.STATISTICS_ITEM'),
          },
          children: [
            {
              path: '**',
              component: IframeGlueComponent,
            },
          ],
        },
        {
          path: 'live',
          data: {
            role: {
              type: RoleOperatorActions.INCLUDES,
              roleIds: [Roles.LIVE, Roles.LIVE_FEED],
            },
            title: marker('SIDENAV.MENU.DIRECT'),
          },
          loadChildren: () =>
            import('@radioking/live/live-info').then(m => m.LiveLiveInfoModule),
        },
        {
          path: 'team',
          loadChildren: () => import('@radioking/animators').then(m => m.AnimatorsModule),
          data: {
            role: Roles.USERS,
            title: marker('SIDENAV.MENU.DJS'),
          },
        },
        {
          path: 'widgets',
          data: { role: Roles.WIDGETS, title: marker('SIDENAV.MENU.SHARE') },

          loadChildren: () => import('@radioking/share').then(m => m.ShareModule),
          //          component: IframeGlueComponent,
          /*
          children: [
            {
              path: '**',
              component: IframeGlueComponent,
            },
          ],
           */
        },
        {
          path: 'admin/radios',
          component: IframeGlueComponent,
        },
        {
          path: 'admin/users',
          component: IframeGlueComponent,
        },
        {
          path: 'admin/consumption',
          component: IframeGlueComponent,
        },
        {
          path: 'admin/chronics',
          component: IframeGlueComponent,
        },
        {
          path: 'admin/alert',
          component: IframeGlueComponent,
        },
        {
          path: 'admin/changelog',
          component: IframeGlueComponent,
        },
        {
          path: '**',
          loadChildren: () => import('@radioking/page404').then(m => m.Page404Module),
        },
        {
          path: '404',
          loadChildren: () => import('@radioking/page404').then(m => m.Page404Module),
        },
      ]),
    ],
  },
  { path: '', redirectTo: '/radio/0', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
