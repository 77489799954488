import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpLinkComponent } from './help-link.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [HelpLinkComponent],
  exports: [HelpLinkComponent],
})
export class UiHelpLinkModule {}
