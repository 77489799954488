import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
// import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
// import { HomeModule } from './home/home.module';
// import { AboutModule } from './about/about.module';
// import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { HotglueModule } from '@app/hotglue/hotglue.module';
import { ExternalRoutesModule } from '@app/external-routes/external-routes.module';
import { IntercomModule } from 'ng-intercom';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';
import { CustomBreakPointsProvider } from './core/custom-breakpoints';
import { SharedPannelManagerModule } from '@radioking/shared/pannel-manager';

if (environment.sentry.isEnabled) {
  Sentry.init({
    dsn: environment.sentry.url,
    environment: environment.sentry.env,
  });
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

const globalDialogConfig: MatDialogConfig = {
  disableClose: true,
  hasBackdrop: true,
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    // HomeModule,
    HotglueModule,
    // AboutModule,
    // LoginModule,
    AppRoutingModule,
    ExternalRoutesModule,
    SharedPannelManagerModule,
    IntercomModule.forRoot({
      appId: environment.intercomId,
      updateOnRouterChange: true,
    }),
  ],
  declarations: [AppComponent],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: globalDialogConfig },
    CustomBreakPointsProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
