import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { MatIconModule } from '@angular/material/icon';
import { UiLoaderModule } from '@radioking/ui/loader';

@NgModule({
  imports: [CommonModule, MatIconModule, UiLoaderModule],
  declarations: [ButtonComponent, IconButtonComponent],
  exports: [ButtonComponent, IconButtonComponent],
})
export class UiButtonModule {}
