import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Logger } from '@radioking/shared/logger';

export interface ErrorFormFormat {
  [key: string]: ValidationErrors;
}
const log = new Logger('FormHelperService');

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  constructor() {}

  forceValidationTrigger(form: FormGroup): void {
    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        form.controls[i].markAsDirty();
        form.controls[i].updateValueAndValidity();
      }
    }
  }

  updateErrorMapping(form: FormGroup, oldErrors: ErrorFormFormat) {
    for (const field in oldErrors) {
      if (!oldErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      oldErrors[field] = {};

      // Get the control
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        oldErrors[field] = control.errors;
      }
    }
  }
}
