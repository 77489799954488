import { BREAKPOINT } from '@angular/flex-layout';

const PRINT_BREAKPOINTS = [
  {
    alias: 'lt.ipad',
    suffix: 'MdIpad',
    mediaQuery: 'screen and (max-width: 1024px)',
    overlapping: false,
  },
  {
    alias: 'gt.ipad',
    suffix: 'GtIpad',
    mediaQuery: 'screen and (min-width: 1025px)',
    overlapping: false,
  },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: PRINT_BREAKPOINTS,
  multi: true,
};
