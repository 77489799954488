import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalWrapperComponent } from './modal-wrapper.component';
import { UiButtonModule } from '@radioking/ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { UiLoaderModule } from '@radioking/ui/loader';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, UiButtonModule, MatIconModule, TranslateModule, UiLoaderModule],
  declarations: [ModalWrapperComponent],
  exports: [ModalWrapperComponent],
})
export class UiModalWrapperModule {}
