import { Component, Input } from '@angular/core';

@Component({
  selector: 'rk-help-link',
  templateUrl: './help-link.component.html',
  styleUrls: ['./help-link.component.scss'],
})
export class HelpLinkComponent {
  @Input()
  link: string;

  @Input()
  message: string;
}
