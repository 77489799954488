import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-no-access-container',
  templateUrl: './no-access-container.component.html',
  styleUrls: ['./no-access-container.component.scss'],
})
export class NoAccessContainerComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  content: string;

  @Input()
  url: string;

  @Input()
  imageAbove: string;

  @Input()
  imageBelow: string;

  @Select(RadioState.updateOfferUrl)
  updateUrl$: Observable<string>;

  constructor() {}

  ngOnInit() {}

  upgrade() {
    this.updateUrl$.subscribe(data => {
      window.open(data, '_blank');
    });
  }
}
