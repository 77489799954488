<ng-template #radioOffline>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="radio-off"
    *ngIf="(radioStatus$ | async) === 'stopped'; else basicLoader"
  >
    <div class="radio-off-notice" *ngIf="!(isRadioStarting$ | async)" translate>
      SIDEBAR.OFF.NOTICE
    </div>
    <div class="radio-off-notice" *ngIf="(isRadioStarting$ | async)" translate>
      SIDEBAR.OFF.STARTING
    </div>
    <div>
      <rk-audio-loader
        *ngIf="(isRadioStarting$ | async)"
        color="white"
        [size]="30"
      ></rk-audio-loader>
      <rk-advanced-tooltip [active]="exceeded$ | async">
        <button
          mat-button
          (click)="startBroadcastRadio()"
          *ngIf="!(isRadioStarting$ | async)"
          class="disable-mat-button-focus-overlay"
          [ngClass]="{ disabled: exceeded$ | async }"
        >
          <mat-icon fontSet="fa" fontIcon="fa-power-off"></mat-icon>
          <span class="label-button">
            {{ 'SIDEBAR.OFF.START_RADIO' | translate }}
          </span>
        </button>
      </rk-advanced-tooltip>
    </div>
  </div>
</ng-template>

<ng-template #basicLoader>
  <div fxLayout="column" fxLayoutAlign="center center" class="radio-off">
    <div><rk-audio-loader color="white" [size]="30"></rk-audio-loader></div>
  </div>
</ng-template>

<ng-template #radioStopping>
  <div fxLayout="column" fxLayoutAlign="center center" class="radio-off">
    <div translate>SIDEBAR.STOPING.NOTICE</div>
    <div><rk-audio-loader color="white" [size]="30"></rk-audio-loader></div>
  </div>
</ng-template>

<ng-template #radioRestarting>
  <div fxLayout="column" fxLayoutAlign="center center" class="radio-off">
    <div translate>SIDEBAR.OFF.RESTARTING</div>
    <div><rk-audio-loader color="white" [size]="30"></rk-audio-loader></div>
  </div>
</ng-template>

<ng-container
  *ngIf="(isRadioStarted$ | async) && !(isRadioStarting$ | async); else radioOffline"
>
  <ng-container *ngIf="!(isRadioRestarting$ | async); else radioRestarting">
    <ng-container *ngIf="!(isRadioStopping$ | async); else radioStopping">
      <div>
        <div *ngIf="(isListening$ | async)" class="duotone">
          <svg class="svg" viewBox="0 0 400 400">
            <defs>
              <filter id="duotone-filter-post-one">
                <feColorMatrix
                  type="matrix"
                  attr.values="{{ getColorMatrix([[255, 157, 0], [8, 19, 119]]) }}"
                ></feColorMatrix>
              </filter>
            </defs>
            <image
              class="image"
              attr.xlink:href="{{ (currentTrack$ | async).cover }}"
              filter="url(#duotone-filter-post-one)"
              preserveAspectRatio="xMidYMin slice"
              width="400"
              height="400"
            ></image>
          </svg>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-between none" class="main">
          <div
            class="listening-header move-front"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="listeners">
              <div *ngIf="(isCustomer$ | async) || (hasStatisticsRole() | async)">
                <mat-icon fontSet="fa" fontIcon="fa-headphones"></mat-icon>
                <span class="sidebar-listener-count-fold">{{
                  listeners$ | async | shortNumber
                }}</span>
                <span
                  class="sidebar-listener-count-unfold"
                  data-cy="siebar-listener-count"
                  >{{ listeners$ | async }}</span
                >
              </div>
            </div>
            <rk-icon-button
              data-cy="button-next-tracks"
              svgIcon="next-titles"
              color="white"
              class="button-next-tracks"
              [matMenuTriggerFor]="nextSongsMenu"
              [size]="24"
              [style]="{ padding: '5px' }"
            ></rk-icon-button>
          </div>

          <div
            class="main-listening move-front"
            fxLayout="row"
            fxLayoutAlign="start center"
            data-tour="play-zone"
          >
            <button
              mat-icon-button
              *ngIf="!(isListening$ | async) && !(isAudioLoading$ | async)"
              (click)="playRadio()"
              class="button-play"
            >
              <mat-icon fontSet="fa" fontIcon="fa-play"></mat-icon>
            </button>
            <div *ngIf="(isAudioLoading$ | async)" class="loader">
              <rk-audio-loader color="white" [size]="30"></rk-audio-loader>
            </div>
            <button
              mat-icon-button
              *ngIf="(isListening$ | async)"
              (click)="stopRadio()"
              class="button-play"
            >
              <mat-icon fontSet="fa" fontIcon="fa-stop"></mat-icon>
            </button>

            <div class="current-info">
              <span data-cy="siebar-current-track-artist">{{
                (currentTrack$ | async).title
              }}</span>
              <span data-cy="siebar-current-track-title">{{
                (currentTrack$ | async).artist
              }}</span>
            </div>
            <div *ngIf="(isListening$ | async)" class="volume-container">
              <rk-icon-button
                [faIcon]="isMuted ? 'fa-volume-mute' : 'fa-volume-down'"
                color="white"
                class="volume-button"
                (click)="toggleVolume()"
                [disableHover]="true"
                [size]="22"
              ></rk-icon-button>
              <div
                class="volume-slider-container"
                [ngClass]="{ visible: isChangingVolume, live: isLive$ | async }"
              >
                <mat-slider
                  [vertical]="true"
                  color="primary"
                  [value]="getVolume()"
                  (input)="setVolme($event.value)"
                  (change)="finSetVolume()"
                  [max]="1"
                  [min]="0"
                  [step]="0.1"
                ></mat-slider>
              </div>
            </div>
          </div>

          <div
            *ngIf="(isLive$ | async); else noLive"
            class="live-banner move-front"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="row" fxLayoutAlign=" center">
              <div class="dot"></div>
              <span class="live-text" translate>SIDEBAR.LIVE_TEXT</span>
            </div>
            <span class="live-duration">{{ liveDuration$ | async | timeFormat }}</span>
            <div class="broadcaster">{{ (broadcaster$ | async).username }}</div>
          </div>

          <ng-template #noLive><div></div></ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<!-- Menu incomming tracks -->
<mat-menu
  #nextSongsMenu="matMenu"
  class="radio-incoming-songs-playing-menu"
  [overlapTrigger]="false"
>
  <div class="header-menu-nexts" fxLayout="row" fxLayoutAlign="space-between baseline">
    <span translate>SIDEBAR.UPCOMING.TITLE</span>
    <button
      mat-button
      color="primary"
      *ngIf="(nextTracks$ | async).length > 0"
      (click)="goToGeneration()"
    >
      {{ 'SIDEBAR.UPCOMING.SEE_MORE' | translate }}
    </button>
  </div>
  <div
    *ngFor="let next of (nextTracks$ | async)"
    class="titles-incoming"
    data-cy="incoming-item"
  >
    <div class="artist-and-title-incoming">
      <span data-cy="incoming-item-title">{{ next.title }}</span>
      <span data-cy="incoming-item-artist">{{ next.artist }}</span>
    </div>
    <div>
      <span>{{ next.started_at | toRadioTz | amDateFormat: 'LT' }}</span>
    </div>
  </div>
  <div
    *ngIf="(nextTracks$ | async).length === 0"
    class="no-titles-incoming"
    fxLayout="column"
  >
    <span translate>SIDEBAR.UPCOMING.NOTRACKS</span>
    <span>
      {{ 'SIDEBAR.UPCOMING.YOUCAN' | translate }}
      <span class="gene-link" (click)="goToGeneration()" translate
        >SIDEBAR.UPCOMING.GENERATE_DAY</span
      >
    </span>
  </div>
</mat-menu>
