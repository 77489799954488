import { Injectable, NgZone } from '@angular/core';

import { Howl } from 'howler';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { Logger } from '@radioking/shared/logger';
import { Store } from '@ngxs/store';
import { AuthState } from '@app/core/states/auth.state';
import { filter } from 'rxjs/operators';
import { PlayError } from '@app/core/states/audio.actions';

const log = new Logger('PlayerService');

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  private onEnd$ = new BehaviorSubject<void>(null);
  private onStop$ = new BehaviorSubject<void>(null);
  private onPlay$ = new BehaviorSubject<void>(null);
  private onPause$ = new BehaviorSubject<void>(null);
  private onDuration$ = new BehaviorSubject<number>(0);
  private onMaxDuration$ = new BehaviorSubject<number>(0);

  private playing$ = new BehaviorSubject<boolean>(false);

  private updateObservable$: Observable<number>;
  private updateSubscriber: Subscription;

  private isPLaying: boolean;

  private isStoping = false;

  private sound: Howl;

  constructor(private readonly ngZone: NgZone, private store: Store) {
    this.isPLaying = false;

    Howler.volume(1);
  }

  public listenToAudioFile(url: string): void {
    if (this.sound) {
      this.sound.stop();
      this.sound.unload();
    }
    const authToken = this.store.selectSnapshot(AuthState.getAccessToken);
    const playURL = `${url}?token=${authToken}`;
    this.sound = new Howl({
      src: [playURL],
      autoplay: true,
      html5: true,
    });

    this.setupListenersForSound();
    this.sound.play();
  }

  public listenToRadio(url: string): void {
    if (this.sound) {
      this.isStoping = true;
      this.sound.stop();
      this.sound.unload();
      this.isStoping = false;
    }
    this.sound = new Howl({
      src: [url],
      autoplay: true,
      html5: true,
      format: ['mp3', 'aac'],
    });

    this.setupListenersForSound();
  }

  private setupListenersForSound(): void {
    this.sound.on('end', () => {
      if (this.isStoping) {
        return;
      }
      this.isPLaying = false;
      this.ngZone.run(() => this.onEnd$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));
    });
    this.sound.on('stop', () => {
      if (this.isStoping) {
        return;
      }
      this.isPLaying = false;
      this.ngZone.run(() => this.onStop$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));
    });
    this.sound.on('play', () => {
      if (this.isStoping) {
        return;
      }
      this.isPLaying = true;
      this.ngZone.run(() => this.onPlay$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));

      if (this.sound.playing()) {
        this.subscribe();
      }
    });
    this.sound.on('pause', () => {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.isStoping) {
        return;
      }
      this.isPLaying = false;
      this.ngZone.run(() => this.onPause$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));
    });
    const onError = () => {
      this.store.dispatch(new PlayError());
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.isStoping) {
        return;
      }
      this.isPLaying = false;
      this.ngZone.run(() => this.onStop$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));
    };
    this.sound.on('loaderror', onError);
    this.sound.on('playerror', onError);
    this.setupUpdateObservable();
    log.debug('Added listeners');
  }

  private setupUpdateObservable(): void {
    this.onDuration$.next(0);
    this.onMaxDuration$.next(0);
    this.updateObservable$ = interval(300);
  }

  getCurrentDuration(): number {
    return Math.round(<number>this.sound.seek() * 100) / 100;
  }

  getMaxDuration(): number {
    return Math.round(<number>this.sound.duration() * 100) / 100;
  }

  private subscribe(): void {
    if (this.updateSubscriber) {
      this.updateSubscriber.unsubscribe();
    }
    this.updateSubscriber = this.updateObservable$.subscribe(() => {
      this.ngZone.run(() => this.onDuration$.next(this.getCurrentDuration()));
      this.ngZone.run(() => this.onMaxDuration$.next(this.getMaxDuration()));
    });
  }

  private unsubscribe(): void {
    if (this.updateSubscriber) {
      this.updateSubscriber.unsubscribe();
    }
  }

  public pauseMusic(): void {
    if (this.sound) {
      this.sound.pause();
    }
  }

  public resume(): void {
    if (this.sound) {
      this.sound.play();
    }
  }

  public getVolume(): number {
    return Howler.volume();
  }

  public setVolume(volume: number): void {
    Howler.volume(volume);
  }

  public seekTo(secs: number): void {
    if (this.sound) {
      this.sound.seek(secs);
    }
  }

  public onEnd(): Observable<void> {
    return this.onEnd$.asObservable();
  }

  public onStop(): Observable<void> {
    return this.onStop$.asObservable();
  }

  public onPlay(): Observable<void> {
    return this.onPlay$.asObservable();
  }

  public onPause(): Observable<void> {
    return this.onPause$.asObservable();
  }

  public playing(): Observable<boolean> {
    return this.playing$.asObservable();
  }

  public onDuration(): Observable<number> {
    return this.onDuration$.asObservable().pipe(filter(data => !isNaN(data)));
  }

  public onMaxDuration(): Observable<number> {
    return this.onMaxDuration$.asObservable().pipe(filter(data => !isNaN(data)));
  }
}
