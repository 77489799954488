import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Tag, Track, TrackTags } from '../../core/models/Track';
import { convertToApiSearch, FilterObject } from '@app/library/models/filters.model';
import { PLACEHOLDER } from '@app/shared/constants';
import * as moment from 'moment';
import { convertToTag } from '@app/library/services/tags.service';

export interface TrackApiModel {
  idtrack: number;
  title: string;
  artist: string;
  album: string;
  year: number;
  idbox: number;
  bpm: number;
  upload_date: string;
  bitrate: number;
  playtime: number;
  locked: boolean;
  cover_url: string;
  audio_url: string;
  buy_link: string;
  is_custom_cover: boolean;
  tags?: TagApi[];
}

export interface TagApi {
  idtag: number;
  color: string;
  name: string;
}

export interface TrackTagsApi {
  idtrack: number;
  tags: TagApi[];
}

export interface PlaylistTrackApiModel extends TrackApiModel {
  position: number;
}

export interface ImgUrlApi {
  idtrack: number;
  cover_url: string;
}

interface BuyLink {
  buy_link: string;
}

const libraryUrl = {
  getTracksV2: (id: number, box: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/track?idbox=${box}`,
  getTracksWithFilter: (id: number, box: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/search/track?idbox=${box}`,
  getTracksOfTag: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/search/track`,
  getTrackById: (trackId: number, radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/track/${trackId}`,
  deleteTracks: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/batch/track`,
  moveTracks: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/batch/track`,
  editTracks: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/batch/track`,
  editCoverSingle: (radioId: number, trackId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/track/${trackId}/cover`,
  editCoverMultiple: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/batch/track/cover`,
  deleteCover: (radioId: number, trackId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/track/${trackId}/cover`,
  lookupTrackCover: (radioId: number, trackId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/track/${trackId}/cover-api`,
  lookupTrackBuyLink: (radioId: number, trackId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/track/${trackId}/buy-link`,
  getPlaylistOfTrack: (radioId: number, trackId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/track/${trackId}/playlist`,
  addDefaultTracks: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/default/track`,
};

@Injectable({
  providedIn: 'root',
})
export class TrackService {
  constructor(private readonly httpClient: HttpClient) {}

  getRadioTracks(idRadio: number, idTrackbox: number): Observable<Track[]> {
    return this.httpClient
      .get<TrackApiModel[]>(libraryUrl.getTracksV2(idRadio, idTrackbox))
      .pipe(
        map(data => {
          return this.convertMultipleToTrack(data);
        }),
      );
  }

  getRadioTracksWithFilter(
    idRadio: number,
    idTrackbox: number,
    filters: FilterObject,
  ): Observable<Track[]> {
    return this.httpClient
      .post<TrackApiModel[]>(
        libraryUrl.getTracksWithFilter(idRadio, idTrackbox),
        convertToApiSearch(filters),
      )
      .pipe(map(data => this.convertMultipleToTrack(data)));
  }

  getTagTracks(idRadio: number, filters: FilterObject): Observable<Track[]> {
    return this.httpClient
      .post<TrackApiModel[]>(
        libraryUrl.getTracksOfTag(idRadio),
        convertToApiSearch(filters),
      )
      .pipe(map(data => this.convertMultipleToTrack(data)));
  }

  getTrackById(idRadio: number, idTrack: number): Observable<Track> {
    return this.httpClient
      .get<TrackApiModel>(libraryUrl.getTrackById(idTrack, idRadio))
      .pipe(
        map(data => {
          return this.convertToTrack(data);
        }),
      );
  }

  lookupTrackCover(idRadio: number, idTrack: number): Observable<string> {
    return this.httpClient
      .post<ImgUrlApi>(libraryUrl.lookupTrackCover(idRadio, idTrack), null)
      .pipe(map(item => item.cover_url));
  }

  deleteTrackCover(idRadio: number, idTrack: number): Observable<string> {
    return this.httpClient
      .delete<ImgUrlApi>(libraryUrl.deleteCover(idRadio, idTrack))
      .pipe(
        map(data => {
          return data.cover_url;
        }),
      );
  }

  setSingleCover(idRadio: number, idTrack: number, file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient
      .post<ImgUrlApi>(libraryUrl.editCoverSingle(idRadio, idTrack), formData)
      .pipe(
        map(item => {
          return item.cover_url;
        }),
      );
  }

  setMultipleCover(
    idRadio: number,
    idTracks: number[],
    file: File,
  ): Observable<ImgUrlApi[]> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('data', JSON.stringify({ tracks: idTracks }));
    return this.httpClient
      .post<ImgUrlApi[]>(libraryUrl.editCoverMultiple(idRadio), formData)
      .pipe(
        map(item => {
          return item;
        }),
      );
  }

  lookupBuyLink(idRadio: number, idTrack: number): Observable<string> {
    return this.httpClient
      .post<BuyLink>(libraryUrl.lookupTrackBuyLink(idRadio, idTrack), null)
      .pipe(map(val => val.buy_link));
  }

  editTracks(
    idRadio: number,
    idTracks: number[],
    data: Partial<TrackApiModel>,
  ): Observable<TrackApiModel[]> {
    return this.httpClient.put<TrackApiModel[]>(libraryUrl.editTracks(idRadio), {
      tracks: idTracks,
      ...data,
    });
  }

  deleteTracks(idRadio: number, idTracks: number[]): Observable<any> {
    // Needed in order to pass a body to the delete method
    return this.httpClient.request('DELETE', libraryUrl.deleteTracks(idRadio), {
      body: {
        tracks: idTracks,
      },
    });
  }

  moveTracks(idRadio: number, idTracks: number[], idTrackbox: number): Observable<any> {
    return this.httpClient.request('PUT', libraryUrl.moveTracks(idRadio), {
      body: {
        idtrackbox: idTrackbox,
        tracks: idTracks,
      },
    });
  }

  getPlaylistOfTracks(idRadio: number, idTrack: number): Observable<number[]> {
    return this.httpClient.get<number[]>(libraryUrl.getPlaylistOfTrack(idRadio, idTrack));
  }

  addDefaultTracks(idRadio: number): Observable<any> {
    return this.httpClient.request('POST', libraryUrl.addDefaultTracks(idRadio));
  }

  convertMultipleToTrack(apiTrack: TrackApiModel[]): Track[] {
    return apiTrack.map(data => this.convertToTrack(data));
  }

  convertToTrack(track: TrackApiModel): Track {
    return {
      idTrackbox: track.idbox,
      id: track.idtrack,
      title: track.title || '',
      artist: track.artist || '',
      album: track.album || '',
      cover: track.cover_url || PLACEHOLDER,
      isCustomCover: track.is_custom_cover,
      file: track.audio_url,
      year: track.year,
      buyLink: track.buy_link,
      bpm: track.bpm,
      uploadDate: moment(track.upload_date),
      bitrate: track.bitrate,
      timeSeconds: track.playtime,
      locked: track.locked,
      tags: track.tags.map(data => {
        return {
          id: data.idtag,
          name: data.name,
          color: data.color,
        };
      }),
    };
  }
}
