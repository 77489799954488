import { Track } from '@app/core/models/Track';

const tag = '[audio]';

export class PlayEvent {
  static readonly type = `${tag} play event`;
  constructor() {}
}

export class Stop {
  static readonly type = `${tag} stop`;
  constructor() {}
}

export class End {
  static readonly type = `${tag} end`;
  constructor() {}
}

export class Resume {
  static readonly type = `${tag} resume`;
  constructor() {}
}

export class PlayAudio {
  static readonly type = `${tag} play audio`;
  constructor(public track: Track) {}
}

export class PlayError {
  static readonly type = `${tag} play error`;
  constructor() {}
}

export class PlayRadio {
  static readonly type = `${tag} play radio`;
  constructor(public radioUrl: string) {}
}
