import { SugestionInput } from '@radioking/ui/input';

export interface Op {
  operator: string;
}

export interface EqTest extends Op {
  operator: 'equals' | 'not_equals';
  value: string;
}

export interface NoneTest extends Op {
  operator: 'none';
}

export interface NumberTest extends Op {
  operator: 'lower' | 'lower_equals' | 'greater' | 'greater_equals';
  value: string;
}

export interface BetweenTest extends Op {
  operator: 'between';
  start: string;
  end: string;
}

export interface NotSetTest extends Op {
  operator: 'not_set';
}

export interface MatchOneTest extends Op {
  operator: 'match_one' | 'match_all' | 'all';
  value: SugestionInput[];
}

export type OperatorsForNonSettableItem =
  | EqTest
  | NumberTest
  | BetweenTest
  | NotSetTest
  | NoneTest;

export type OperatorsForNonVoidItems = EqTest | NumberTest | BetweenTest | NoneTest;

export interface FilterObject {
  bpm?: OperatorsForNonSettableItem;
  bitrate?: OperatorsForNonSettableItem;
  year?: OperatorsForNonSettableItem;
  playtime?: OperatorsForNonVoidItems;
  createdDays?: OperatorsForNonVoidItems;
  inPlaylist?: EqTest | NoneTest;
  tags?: MatchOneTest | NotSetTest;
}

export const defaultFilters: FilterObject = {
  bpm: {
    operator: 'none',
  },
  bitrate: {
    operator: 'none',
  },
  year: {
    operator: 'none',
  },
  playtime: {
    operator: 'none',
  },
  createdDays: {
    operator: 'none',
  },
  inPlaylist: {
    operator: 'none',
  },
  tags: {
    operator: 'all',
    value: [],
  },
};

export function isFilterObjectFiltering(filter: FilterObject): boolean {
  if (!filter) {
    return false;
  }
  for (const key of ['bpm', 'bitrate', 'year', 'playtime', 'createdDays', 'inPlaylist']) {
    const val: Op = filter[key];
    if (!val) {
      continue;
    }
    if (val.operator !== 'none') {
      return true;
    }
  }
  const opTags = filter.tags;
  if (!opTags) {
    return false;
  }
  return opTags.operator === 'not_set' || opTags.value.length !== 0;
}

const mapField = (val: string) => {
  if (val === 'inPlaylist') {
    return 'in_playlist';
  }
  if (val === 'createdDays') {
    return 'created_days';
  }
  return val;
};

export function convertToApiSearch(filter: FilterObject): any {
  const rules: any[] = [];

  Object.keys(filter).forEach(key => {
    const { operator } = filter[key];
    let value = filter[key].value;
    let start = filter[key].start;
    let end = filter[key].end;
    if (operator === 'none') {
      return;
    }
    if (key === 'playtime') {
      value = value ? value * 60 : 0;
      start = start ? start * 60 : 0;
      end = end ? end * 60 : 0;
    }
    switch (operator) {
      case 'equals':
      case 'not_equals':
      case 'lower_equals':
      case 'lower':
      case 'greater':
      case 'greater_equals':
        rules.push({
          operator,
          field: mapField(key),
          value: `${value}`,
        });
        return;

      case 'between':
        rules.push({
          operator,
          field: mapField(key),
          start: `${start}`,
          end: `${end}`,
        });
        return;

      case 'match_one':
      case 'match_all':
        if (filter[key].value.length === 0) {
          return;
        }
        rules.push({
          operator,
          field: mapField(key),
          value: filter[key].value.map((item: SugestionInput) => item.name).join('-/-'),
        });
        return;
      case 'not_set':
        rules.push({
          operator,
          field: mapField(key),
        });
        return;
    }
  });

  return {
    filter: {
      rules,
      match: 'all',
    },
  };
}

/*
type NotSetableItemTestable = (EqTest & NumberTest & BetweenTest & NotSetTest) & {
  field: 'bpm' | 'bitrate' | 'year';
};
*/
