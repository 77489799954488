import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ClipboardService, ToasterService } from '@radioking/shared/common-services';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'rk-input-with-copy',
  templateUrl: './input-with-copy.component.html',
  styleUrls: ['./input-with-copy.component.scss'],
})
export class InputWithCopyComponent implements OnInit {
  @Input()
  hintQuestionMark: string;
  @Input()
  label: string;
  @Input()
  value: string;

  @HostBinding('class.disable-open')
  @Input()
  disableOpen = false;

  @HostBinding('class.keep-width')
  @Input()
  keepWidth = false;

  constructor(
    private readonly clipboard: ClipboardService,
    private readonly toaster: ToasterService,
  ) {}

  ngOnInit() {}

  openURL() {
    window.open(this.value, '_blank');
  }

  copyLink() {
    this.clipboard.copyMessage(this.value);
    this.toaster.success(marker('TOAST.COPY_SUCCESS'));
  }
}
