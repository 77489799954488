import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedTooltipComponent } from './advanced-tooltip.component';
import { UiButtonModule } from '@radioking/ui/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, UiButtonModule, TranslateModule],
  declarations: [AdvancedTooltipComponent],
  exports: [AdvancedTooltipComponent],
})
export class UiAdvancedTooltipModule {}
