import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rk-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input()
  isLoading = false;

  @Input()
  size = 20;

  @Input()
  center = false;

  @Input()
  inline = false;

  constructor() {}

  ngOnInit() {}

  getLoaderClasses(): any {
    return {
      center: this.center,
      inline: this.inline && this.isLoading,
    };
  }
}
