import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  ForceRefethingData,
  MyRadiosFailure,
  MyRadiosRequest,
  MyRadiosSuccess,
  RadioRestartRequest,
  RadioStartFailure,
  RadioStartRequest,
  RadioStartSuccess,
  RadioStopFailure,
  RadioStopRequest,
  RadioStopSuccess,
  SpecificRadioFailure,
  SpecificRadioRequest,
  SpecificRadioSuccessWithSuperUser,
  StartupRadioFailure,
  StartupRadioRequest,
  StartupRadioSuccess,
  SwitchToRadioRequest,
  SwitchToRadioRequestWithoutToast,
  UseSpecificRadio,
} from './radio.actions';
import { Logger } from '@radioking/shared/logger';
import { Radio, ShortRadio } from '@app/core/models/Radio';
import { RadioService, Readyable } from '@app/core/services/radio.service';
import {
  catchError,
  delay,
  filter,
  flatMap,
  map,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { environment } from '@env/environment';
import {
  StartLiveTrack,
  StartSoftLiveTrack,
  StopSoftLiveTrack,
} from '@app/core/states/live-tracking.actions';
import { interval, merge } from 'rxjs';
import { Stop } from '@app/core/states/audio.actions';
import {
  GetRolesForRadioRequest,
  SpecificRadioSuccess,
} from '@app/core/states/authorization.actions';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { SettingsSuccess } from '@app/settings/states/settings.actions';
import * as amplitude from 'amplitude-js';
import { Injectable } from '@angular/core';

const log = new Logger('radio store');

export class RadioStateModel {
  radioList: ShortRadio[];
  currentRadio: Radio;
  currentRadioId: number;
  isRadioReady: boolean;
  hasRadiosBeenFetched: boolean;
}

@State<RadioStateModel>({
  name: 'radio',
  defaults: {
    currentRadioId: 0,
    isRadioReady: true,
    radioList: [],
    currentRadio: undefined,
    hasRadiosBeenFetched: false,
  },
})
@Injectable()
export class RadioState {
  @Selector()
  static radios(state: RadioStateModel): ShortRadio[] {
    return state.radioList || [];
  }

  @Selector()
  static firstRadioOwned(state: RadioStateModel): ShortRadio {
    return state.radioList.find(r => r.role === 'customer');
  }

  @Selector()
  static getCurrentStreamUrl(state: RadioStateModel): string {
    const current = RadioState.currentRadio(state);
    return `${environment.listenUrl}/${current.slug}`;
  }

  @Selector()
  static hasRadiosBeenFetched(state: RadioStateModel): boolean {
    return state.hasRadiosBeenFetched;
  }

  @Selector()
  static currentRadio(state: RadioStateModel): Radio {
    return state.currentRadio;
  }

  @Selector()
  static currentRadioId(state: RadioStateModel): number {
    return state.currentRadioId || undefined;
  }

  @Selector()
  static currentRadioLogo(state: RadioStateModel): string {
    const current = RadioState.currentRadio(state);
    if (current && current.logo) {
      return current.logo;
    }
    return 'https://picsum.photos/200/300';
  }

  @Selector()
  static currentRadioName(state: RadioStateModel): string {
    return RadioState.currentRadio(state).name;
  }

  @Selector()
  static isOneOfMineRadios(state: RadioStateModel): boolean {
    return !state.radioList
      ? true
      : state.radioList.reduce((a, b) => a || b.id === state.currentRadioId, false);
  }

  @Selector()
  static currentRadioExceeded(state: RadioStateModel): boolean {
    return RadioState.currentRadio(state).exceededListening;
  }

  @Selector()
  static currentPlanId(state: RadioStateModel): number {
    return RadioState.currentRadio(state).plan.idPlan;
  }

  @Selector()
  static canDownloadChronics(state: RadioStateModel): boolean {
    const currentRadio: Radio = RadioState.currentRadio(state);
    return (
      currentRadio.limits.downloadChronics === 1 ||
      (currentRadio.plan.downloadChronics === 1 &&
        currentRadio.limits.downloadChronics === null)
    );
  }

  @Selector()
  static currentSlug(state: RadioStateModel): string {
    return RadioState.currentRadio(state).slug;
  }

  @Selector()
  static hasMoreThanOneRadio(state: RadioStateModel): boolean {
    return state.radioList && state.radioList.length > 1;
  }

  @Selector()
  static isRadioReady(state: RadioStateModel): boolean {
    return state.isRadioReady;
  }

  @Selector()
  static updateOfferUrl(state: RadioStateModel): string {
    return `${environment.urls.WHMCS}/upgrade.php?type=package&id=${state.currentRadio.serviceId}`;
  }

  @Selector()
  static increaseDiskSpaceUrl(state: RadioStateModel): string {
    return `${environment.urls.WHMCS}/upgrade.php?type=configoptions&id=${state.currentRadio.serviceId}`;
  }

  @Selector()
  static isCustomer(state: RadioStateModel): boolean {
    return (
      state.radioList.find(radio => radio.id === state.currentRadio.id).role ===
      'customer'
    );
  }

  @Selector()
  static timezone(state: RadioStateModel): string {
    return state.currentRadio.timezone;
  }

  @Selector()
  static radioRanking(state: RadioStateModel): number {
    return state.currentRadio.ranking;
  }

  @Selector()
  static radioFollowers(state: RadioStateModel): number {
    return state.currentRadio.followers;
  }

  constructor(
    private readonly radioService: RadioService,
    private readonly store: Store,
  ) {}

  @Action(MyRadiosRequest)
  getMyRadios(ctx: StateContext<RadioStateModel>, { userId }: MyRadiosRequest) {
    return this.radioService.getAllMyRadios(userId).pipe(
      flatMap(data => ctx.dispatch(new MyRadiosSuccess(data))),
      catchError(err => ctx.dispatch(new MyRadiosFailure(err))),
    );
  }

  @Action(MyRadiosSuccess)
  getMyRadioSuccess(ctx: StateContext<RadioStateModel>, { radios }: MyRadiosSuccess) {
    ctx.patchState({
      radioList: radios.sort(this.sortRadios),
      hasRadiosBeenFetched: true,
    });
    if (!radios || radios.length === 0) {
      ctx.dispatch(new Navigate(['/no-radio'], {}, { replaceUrl: true }));
    }
  }

  @Action(SettingsSuccess)
  updateSettingsSuccess(
    ctx: StateContext<RadioStateModel>,
    { settings }: SettingsSuccess,
  ) {
    ctx.patchState({
      currentRadio: {
        ...ctx.getState().currentRadio,
        streams: settings.streams,
      },
    });
  }

  @Action(StartupRadioRequest)
  startupRadio(ctx: StateContext<RadioStateModel>, { radio }: StartupRadioRequest) {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
      flatMap(id => this.radioService.updateStartupRadio(id, radio)),
      flatMap(data => ctx.dispatch(new StartupRadioSuccess(data))),
      catchError(err => ctx.dispatch(new StartupRadioFailure(err))),
    );
  }

  @Action(StartupRadioSuccess)
  startupRadioSuccess(
    ctx: StateContext<RadioStateModel>,
    { radio }: StartupRadioSuccess,
  ) {
    ctx.patchState({
      currentRadio: radio,
      radioList: ctx.getState().radioList.map(shortRadio => {
        shortRadio.name = radio.id === shortRadio.id ? radio.name : shortRadio.name;
        return shortRadio;
      }),
    });
    if (radio) {
      ctx.dispatch(new Navigate([`/radio/${radio.id}/media`], {}, { replaceUrl: true }));
    }
  }

  @Action([SwitchToRadioRequest, SwitchToRadioRequestWithoutToast])
  switchToRadio(ctx: StateContext<RadioStateModel>, { idRadio }: SwitchToRadioRequest) {
    ctx.patchState({ currentRadioId: idRadio });
    ctx.dispatch(new ForceRefethingData());
    return ctx.dispatch(new UseSpecificRadio(idRadio));
  }

  @Action(UseSpecificRadio)
  setCurrentRadio(ctx: StateContext<RadioStateModel>, { idRadio }: UseSpecificRadio) {
    ctx.patchState({ currentRadioId: idRadio });
    ctx.dispatch(new Stop());
    // ctx.dispatch(new Navigate(['/'], {}, { replaceUrl: true }));
    return ctx
      .dispatch(new SpecificRadioRequest(idRadio))
      .pipe(tap(() => ctx.dispatch(new StartLiveTrack(idRadio))));
  }

  @Action(SpecificRadioRequest, { cancelUncompleted: true })
  getRadioIdRequest(
    ctx: StateContext<RadioStateModel>,
    { radioId }: SpecificRadioRequest,
  ) {
    return this.radioService.getSpecificRadio(radioId).pipe(
      flatMap(data =>
        merge(
          ctx.dispatch(new SpecificRadioSuccess(data.radio)),
          ctx.dispatch(new SettingsSuccess(data.settings)),
        ),
      ),
      catchError(err => ctx.dispatch(new SpecificRadioFailure(err))),
    );
  }

  @Action(SpecificRadioSuccessWithSuperUser)
  updateRadioNameAndLogoIfNeeded(
    ctx: StateContext<RadioStateModel>,
    { radio }: SpecificRadioSuccessWithSuperUser,
  ) {
    const updatedShorts = ctx.getState().radioList.map(shortRadio => {
      if (shortRadio.id === radio.id) {
        return new ShortRadio(
          radio.id,
          radio.name,
          radio.status,
          radio.logo,
          shortRadio.role,
        );
      }
      return shortRadio;
    });
    ctx.patchState({
      radioList: updatedShorts,
    });
  }

  @Action(SpecificRadioSuccess)
  specificRadioSuccess(
    ctx: StateContext<RadioStateModel>,
    { radio }: SpecificRadioSuccess,
  ) {
    ctx.patchState({
      currentRadio: radio,
      isRadioReady: true,
    });
  }

  @Action(SpecificRadioSuccessWithSuperUser)
  getRadioIdSuccess(
    ctx: StateContext<RadioStateModel>,
    { radio }: SpecificRadioSuccessWithSuperUser,
  ) {
    ctx.patchState({
      currentRadio: radio,
      isRadioReady: true,
    });
    const currUrl = this.store.selectSnapshot(RouterState.url) || '';
    if (
      (this.store.selectSnapshot(RouterState.url) === '/disabled' &&
        radio.status !== 'inactive') ||
      (this.store.selectSnapshot(RouterState.url) === '/configure-radio' && radio.name)
    ) {
      ctx.dispatch(new Navigate([`/radio/${radio.id}/`]));
      return;
    }

    if (
      (currUrl.endsWith('/disabled') && radio.status !== 'inactive') ||
      (currUrl.endsWith('/configure-radio') && radio.name)
    ) {
      ctx.dispatch(new Navigate([`/radio/${radio.id}/`]));
      return;
    }
    if (!currUrl) {
      this.store
        .select(RouterState.url)
        .pipe(
          filter(val => !!val),
          take(1),
        )
        .subscribe(val => {
          ctx.dispatch(new Navigate([this.replaceRadioIdInUrl(val, radio.id)]));
        });
      return;
    }
    ctx.dispatch(new Navigate([this.replaceRadioIdInUrl(currUrl, radio.id)]));
  }

  @Action(SpecificRadioSuccessWithSuperUser)
  getRadioSuccessLookupRoles(
    ctx: StateContext<RadioStateModel>,
    { radio, superUser }: SpecificRadioSuccessWithSuperUser,
  ) {
    const radioProperties = {
      radioId: radio.id,
      radioPlan: radio.plan.idPlan,
      demo: radio.demoStatus.isDemo,
    };
    amplitude.getInstance().setUserProperties(radioProperties);

    if (superUser) {
      return;
    }
    const radioInStore = ctx.getState().radioList.find(rdx => rdx.id === radio.id);
    if (radioInStore && radioInStore.role === 'user') {
      return ctx.dispatch(new GetRolesForRadioRequest(radio.id));
    }
  }

  replaceRadioIdInUrl(url: string, radioId: number): string {
    const returnedVal = url.replace(/radio\/\d+/, `/radio/${radioId}`);
    // console.log('[RADIO ID RESOLVER ]', { returnedVal, url, radioId });
    return returnedVal;
  }

  @Action(RadioStartRequest)
  startRadioRequest(ctx: StateContext<RadioStateModel>, {}: RadioStartRequest) {
    const radioId = ctx.getState().currentRadioId;
    ctx.patchState({ isRadioReady: false });
    return this.radioService.startRadio(radioId).pipe(
      tap(() => {
        this.checkReady(ctx, radioId, 1);
      }),
      catchError(err => ctx.dispatch(new RadioStartFailure(err))),
    );
  }

  checkReady(ctx: StateContext<RadioStateModel>, radioID: number, attempt: number) {
    if (attempt > 10) {
      ctx.dispatch(new RadioStartSuccess(radioID));
      return;
    }
    this.radioService
      .isRadioReady(radioID)
      .pipe(delay(2000), take(1))
      .subscribe(val => {
        if (val.ready) {
          ctx.dispatch(new RadioStartSuccess(radioID));
        } else {
          this.checkReady(ctx, radioID, attempt + 1);
        }
      });
  }

  @Action(RadioStartSuccess)
  startRadioSuccess(ctx: StateContext<RadioStateModel>, { idRadio }: RadioStartSuccess) {
    ctx.patchState({ isRadioReady: true });
    ctx.dispatch(new StartLiveTrack(idRadio));
  }

  @Action(RadioStopRequest)
  stopRadio(ctx: StateContext<RadioStateModel>, {}: RadioStopRequest) {
    ctx.dispatch(new Stop());
    const radioId = ctx.getState().currentRadioId;

    return this.radioService.stopRadio(radioId).pipe(
      flatMap(() => {
        return ctx.dispatch(new RadioStopSuccess());
      }),
      catchError(err => ctx.dispatch(new RadioStopFailure(err))),
    );
  }

  @Action(RadioStopSuccess)
  stopRadioSuccess(ctx: StateContext<RadioStateModel>, {}: RadioStopSuccess) {
    ctx.dispatch(new StartSoftLiveTrack(ctx.getState().currentRadioId));
  }

  @Action(RadioRestartRequest)
  restartRadio(ctx: StateContext<RadioStateModel>, {}: RadioRestartRequest) {
    let isActive = false;
    let tries = 0;
    const radioId = ctx.getState().currentRadioId;
    ctx.patchState({ isRadioReady: false });
    ctx.dispatch(new StopSoftLiveTrack());
    return this.radioService.restartRadio(radioId).pipe(
      flatMap(() => {
        return interval(2000);
      }),
      takeWhile(() => !isActive),
      flatMap(() => this.radioService.isRadioReady(radioId)),
      map((val: Readyable) => {
        tries = tries + 1;
        if (tries > 10) {
          return true;
        }

        return val.ready;
      }),
      filter(val => val),
      tap(() => {
        isActive = true;
      }),
      flatMap(() =>
        ctx.dispatch([new RadioStartSuccess(radioId), new StartSoftLiveTrack(radioId)]),
      ),
      catchError(err => ctx.dispatch(new RadioStartFailure(err))),
    );
  }

  sortRadios(next: ShortRadio, prev: ShortRadio) {
    if (prev.role !== 'customer' && next.role === 'customer') {
      return -1;
    }
    if (prev.role === 'customer' && next.role !== 'customer') {
      return 1;
    }
    return 0;
  }
}
