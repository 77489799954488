import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'rk-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input()
  disabled: boolean;

  @Input()
  fakeHover = false;

  @Input()
  style: any;

  @Input()
  label: string;

  @Input()
  faIcon: string;

  @Input()
  faFont = 'fa';

  @Input()
  svgIcon: string;

  @Input()
  iconSize = '15px';

  @Input()
  loading = false;

  @Input()
  btnStyle: 'main' | 'orange' | 'flat' | 'link' | 'playlist' = 'main';

  @Input()
  btnBig = false;

  @Input()
  noPadding = false;

  @Input()
  type = 'button';

  @Output()
  click: EventEmitter<any> = new EventEmitter();

  @Output()
  focus: EventEmitter<any> = new EventEmitter();

  @Output()
  blur: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.click.emit(event);
  }

  isDisabled(): boolean {
    if (this.loading) {
      return true;
    }
    return this.disabled;
  }

  getBtnClasses(): any {
    return {
      orange: this.btnStyle === 'orange',
      flat: this.btnStyle === 'flat',
      main: this.btnStyle === 'main',
      link: this.btnStyle === 'link',
      playlist: this.btnStyle === 'playlist',
      big: this.btnBig,
      disabled: this.isDisabled(),
      loading: this.loading,
      'no-padding': this.noPadding,
      'only-icon': !this.label || this.label.length === 0,
      'fake-hover': this.fakeHover,
    };
  }

  getSvgColorClass(): string {
    if (this.btnStyle === 'orange') {
      return 'svg-brand';
    }
    if (this.btnStyle === 'main') {
      return '';
    }
    return 'svg-black';
  }

  getStyleIcon() {
    if (this.faIcon || this.svgIcon) {
      if (!this.label) {
        return {
          'font-size': this.iconSize,
          'line-height': this.iconSize,
          'min-height': this.iconSize,
          'min-width': this.iconSize,
          height: this.iconSize,
          width: this.iconSize,
          'margin-right': 0,
        };
      }
    }
    return {
      'font-size': this.iconSize,
      'line-height': this.iconSize,
      'min-height': this.iconSize,
      'min-width': this.iconSize,
      height: this.iconSize,
      width: 'initial',
    };
  }
}
