export const tokenPath = 'auth.auth.token';
export const tokenRefreshToken = 'auth.auth.refreshToken';

export const SavedToLocalstorage = [
  tokenPath,
  tokenRefreshToken,
  'library.columns',
  'library.bacs.bacsOpen',
  'library.filters',
  'library.trackMixPoints',
  'playlists.playlistColumns',
  'playlists.process',
  'share.player',
  'share.playedTracks',
  'share.currentTrack',
  'share.topTracks',
  'share.broadcastedArtists',
];
