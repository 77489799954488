import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FuseNavigationService } from '../../navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { slideInOutBlock } from '@radioking/ui/animations';
import { MainSideNavService } from '@app/core/services/main-side-nav.service';

@Component({
  selector: 'rk-nav-vertical-collapse',
  templateUrl: './nav-vertical-collapse.component.html',
  styleUrls: ['./nav-vertical-collapse.component.scss'],
  animations: [slideInOutBlock],
})
export class RkNavVerticalCollapseComponent implements OnInit {
  @Input()
  item: any;

  @HostBinding('class')
  classes = 'nav-collapse nav-item';

  @HostBinding('class.open')
  public isOpen = false;

  @HostBinding('class.has-child-open')
  public hasChildOpen = false;

  @Output()
  clickNavigation: EventEmitter<any> = new EventEmitter();

  @Input()
  radioId: number;

  constructor(
    private navigationService: FuseNavigationService,
    private router: Router,
    private readonly mainSideNavService: MainSideNavService,
  ) {
    // Listen for route changes
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, event.urlAfterRedirects)) {
          this.expand();
          this.hasChildOpen = true;
        } else {
          this.collapse();
          this.hasChildOpen = false;
        }
      }
    });

    // Listen for collapsing of any navigation item
    this.navigationService.onItemCollapsed.subscribe((clickedItem: any) => {
      if (clickedItem && clickedItem.children) {
        // Check if the clicked item is one
        // of the children of this item
        if (this.isChildrenOf(this.item, clickedItem)) {
          return;
        }

        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, this.router.url)) {
          return;
        }

        // If the clicked item is not this item, collapse...
        if (this.item !== clickedItem) {
          this.collapse();
        }
      }
    });
  }

  ngOnInit() {
    // Check if the url can be found in
    // one of the children of this item
    if (this.isUrlInChildren(this.item, this.router.url)) {
      this.hasChildOpen = true;
      this.expand();
    } else {
      this.hasChildOpen = false;
      this.collapse();
    }
  }

  /**
   * Toggle collapse
   *
   * @param ev
   */
  toggleOpen(ev: Event) {
    ev.preventDefault();

    this.isOpen = !this.isOpen;

    // Navigation collapse toggled...
    this.navigationService.onItemCollapsed.next(this.item);
    this.navigationService.onItemCollapseToggled.next();
  }

  /**
   * Expand the collapsable navigation
   */
  expand() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.navigationService.onItemCollapseToggled.next();
  }

  /**
   * Collapse the collapsable navigation
   */
  collapse() {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;
    this.navigationService.onItemCollapseToggled.next();
  }

  /**
   * Check if the given parent has the
   * given item in one of its children
   *
   * @param parent
   * @param item
   * @return {any}
   */
  isChildrenOf(parent: any, item: any): boolean {
    if (!parent.children) {
      return false;
    }

    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    for (const children of parent.children) {
      if (children.children) {
        return this.isChildrenOf(children, item);
      }
    }
  }

  /**
   * Check if the given url can be found
   * in one of the given parent's children
   *
   * @param parent
   * @param url
   * @returns {any}
   */
  isUrlInChildren(parent: any, url: string) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i = i + 1) {
      if (parent.children[i].children) {
        if (this.isUrlInChildren(parent.children[i], url)) {
          return true;
        }
      }

      if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
        return true;
      }
    }

    return false;
  }
}
