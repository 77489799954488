<!--
<button (click)="fakeEvent(0)">CLICK ME</button>
<button (click)="fakeEvent(1)">setModaly</button>
<button (click)="fakeEvent(2)">closeModal</button>
<button (click)="fakeEvent(3)">setLoaded</button>
-->
<div class="modaly" [@modalBackDrop]="isModalOpen"></div>

<ng-container *ngIf="(url$ | async) as url">
  <div class="iframe-container" [@iframeZdx]="isModalOpen">
    <iframe #iframe [attr.src]="url" data-iframe></iframe>
    <div class="loading-overlay" *ngIf="isLoading">
      <rk-loader [size]="60" [isLoading]="true"></rk-loader>
    </div>
  </div>
</ng-container>
