import { Radio, RadioNameTimezone, ShortRadio } from '@app/core/models/Radio';

const tag = '[Radio]';

export class MyRadiosRequest {
  static readonly type = `${tag} request my radios`;
  constructor(public userId: number) {}
}

export class MyRadiosSuccess {
  static readonly type = `${tag} success my radios`;
  constructor(public radios: ShortRadio[]) {}
}

export class MyRadiosFailure {
  static readonly type = `${tag} error my radios`;
  constructor(public error: Error) {}
}

export class StartupRadioRequest {
  static readonly type = `${tag} request startup radio`;
  constructor(public radio: RadioNameTimezone) {}
}

export class StartupRadioSuccess {
  static readonly type = `${tag} success startup radio`;
  constructor(public radio: Radio) {}
}

export class StartupRadioFailure {
  static readonly type = `${tag} error startup radio`;
  constructor(public error: Error) {}
}

export class UseSpecificRadio {
  static readonly type = `${tag} set specific radio`;
  constructor(public idRadio: number) {}
}

export class SwitchToRadioRequest {
  static readonly type = `${tag} request switch to radio`;
  constructor(public idRadio: number) {}
}

export class SwitchToRadioRequestWithoutToast {
  static readonly type = `${tag} request switch to radio without a toast`;

  constructor(public idRadio: number) {}
}

export class ForceRefethingData {
  static readonly type = `${tag} request to refetch all radio specific data`;
  constructor() {}
}

export class SpecificRadioRequest {
  static readonly type = `${tag} request specific radio`;
  constructor(public radioId: number) {}
}

export class SpecificRadioSuccessWithSuperUser {
  static readonly type = `${tag} success specific radio no superuser`;

  constructor(public radio: Radio, public superUser: boolean) {}
}

export class SpecificRadioFailure {
  static readonly type = `${tag} error specific radio`;
  constructor(public error: Error) {}
}

export class RadioStopRequest {
  static readonly type = `${tag} request stop radio`;
  constructor() {}
}

export class RadioStopSuccess {
  static readonly type = `${tag} success stop radio`;
  constructor() {}
}

export class RadioStopFailure {
  static readonly type = `${tag} error stop radio`;
  constructor(public error: Error) {}
}

export class RadioStartRequest {
  static readonly type = `${tag} request start radio`;
  constructor() {}
}

export class RadioStartSuccess {
  static readonly type = `${tag} success start radio`;
  constructor(public idRadio: number) {}
}

export class RadioStartFailure {
  static readonly type = `${tag} error start radio`;
  constructor(public error: Error) {}
}

export class RadioRestartRequest {
  static readonly type = `${tag} request restart radio`;
  constructor() {}
}

export class RadioRestartSuccess {
  static readonly type = `${tag} success restart radio`;
  constructor() {}
}

export class RadioRestartFailure {
  static readonly type = `${tag} error restart radio`;
  constructor(public error: Error) {}
}
