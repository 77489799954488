import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SwUpdate } from '@angular/service-worker';
import { UpdateAvailable } from '@app/core/states/app.actions';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SwloggerService {
  constructor(private readonly updates: SwUpdate, private readonly store: Store) {
    interval(10 * 60 * 60).subscribe(async () => {
      try {
        await updates.checkForUpdate();
      } catch (e) {}
    });
  }

  public watchForUpdate() {
    this.updates.available.subscribe(e => this.store.dispatch(new UpdateAvailable()));
  }
}
