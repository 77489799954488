import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SugestionInput } from '@radioking/ui/input';

@Component({
  selector: 'rk-dropdown-autocomplete',
  templateUrl: './dropdown-autocomplete.component.html',
  styleUrls: ['./dropdown-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownAutocompleteComponent),
      multi: true,
    },
  ],
})
export class DropdownAutocompleteComponent implements OnInit, ControlValueAccessor {
  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  hintQuestionMark: string;

  @Input()
  modal = false;

  @Input()
  canAddNew = false;

  @Input()
  dataIncludedForWarning: number[];

  @Input()
  dropdownWidth: number;

  private _data: SugestionInput[];

  @Input()
  set data(data: SugestionInput[]) {
    this._data = data;
    if (this.inputVal) {
      this.searchData(this.inputVal);
    } else {
      this.filteredData = data;
    }
  }

  get data() {
    return this._data;
  }

  filteredData: SugestionInput[] = [];

  value: SugestionInput;

  inputVal: string;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit() {}

  shouldShowWarning(val: number): boolean {
    return this.dataIncludedForWarning
      ? this.dataIncludedForWarning.includes(val)
      : false;
  }

  searchData(val: string | Event) {
    // Fix for en event, I don't know where it's from
    if (val instanceof Event) {
      return;
    }
    this.inputVal = val;
    this.filteredData = [...this.data].filter(data => {
      return data.translation
        ? this.translateService
            .instant(data.translation)
            .toLowerCase()
            .includes(val.toLowerCase())
        : data.name.toLowerCase().includes(val.toLowerCase());
    });

    if (this.filteredData.length < 2) {
      const maybe = this.filteredData.find(data => {
        return data.translation
          ? this.translateService
              .instant(data.translation)
              .toLowerCase()
              .includes(val.toLowerCase())
          : data.name.toLowerCase().includes(val.toLowerCase());
      });

      if (maybe) {
        this.setData(maybe);
      }
    }
  }

  setData(val: SugestionInput) {
    this.value = val;
    this.propagateChange(this.value);
    this.filteredData = this.data;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  writeValue(value: SugestionInput): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
}
