<div class="no-mobile-manager" *ngIf="!(isRadioStarted$ | async); else started">
  <img src="assets/logo-rk.png" />
  <img src="assets/cat-computer.jpg" />
  <div class="title" translate>GLOBAL.NO_MOBILE.OUPS</div>
  <div class="content" translate>GLOBAL.NO_MOBILE.NOT_ADAPTED</div>
  <div class="content" translate>GLOBAL.NO_MOBILE.USE_COMPUTER</div>
  <a href="https://www.radioking.com" class="btn btn-block" translate
    >GLOBAL.NO_MOBILE.BACK_TO_RK</a
  >
</div>

<ng-template #started>
  <div class="no-mobile-dashboard">
    <div class="radio-list"></div>
    <mat-menu #radiosMenu="matMenu" class="radio-mat-menu">
      <button
        mat-menu-item
        class="radio"
        *ngFor="let radio of (radios$ | async)"
        (click)="setRadio(radio.id)"
      >
        <img [src]="radio.logo" alt="" />
        {{ radio.name }}
      </button>
    </mat-menu>
    <div class="bg">
      <div class="radio-line">
        <div
          class="radio-name"
          *ngIf="(radio$ | async); let radio"
          [matMenuTriggerFor]="radiosMenu"
        >
          <img [src]="radio.logo" alt="radio-logo" />
          {{ radio.name }}
          <mat-icon fontSet="fa" fontIcon="fa-chevron-down"></mat-icon>
        </div>
        <img
          *ngIf="(profilePic$ | async); else letterAvatar"
          [src]="profilePic$ | async"
          alt="user-avatar"
          [matMenuTriggerFor]="logoutMenu"
        />
      </div>
      <div class="listeners-block">
        <div class="listeners">
          {{ listeners$ | async }}
          <div>
            {{
              'GLOBAL.NO_MOBILE.LISTENERS_NOW' | translate: { count: listeners$ | async }
            }}
          </div>
        </div>
        <img src="/assets/headphones-alt.svg" alt="headphones" />
      </div>
      <div class="tracks-block">
        <div translate>GLOBAL.NO_MOBILE.TRACKS_TITLE</div>
        <div class="current-tracks">
          <diV class="track previous-track" *ngIf="(previousTrack$ | async); let prev">
            <div class="track-infos">
              <div class="track-cover">
                <img [src]="prev.cover" alt="" />
              </div>
              <div>
                <div class="track-title">{{ prev.title }}</div>
                <div class="track-artist">{{ prev.artist }}</div>
              </div>
            </div>
            <div class="track-hour">
              <mat-icon fontSet="far" fontIcon="fa-clock"></mat-icon>
              {{ prev.started_at | toRadioTz | amDateFormat: 'LT' }}
            </div>
          </diV>
          <div class="track current-track" *ngIf="(currentTrack$ | async); let current">
            <div class="track-infos">
              <div class="track-cover">
                <img [src]="current.cover" alt="" />
              </div>
              <div>
                <div class="track-title">{{ current.title }}</div>
                <div class="track-artist">{{ current.artist }}</div>
              </div>
            </div>
            <div class="track-hour bars">
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
            </div>
          </div>
          <div class="track next-track" *ngIf="(nextTrack$ | async); let next">
            <div class="track-infos">
              <div class="track-cover">
                <img [src]="next.cover" alt="" />
              </div>
              <div>
                <div class="track-title">{{ next.title }}</div>
                <div class="track-artist">{{ next.artist }}</div>
              </div>
            </div>
            <div class="track-hour">
              <mat-icon fontSet="far" fontIcon="fa-clock"></mat-icon>
              {{ next.started_at | toRadioTz | amDateFormat: 'LT' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="title" translate>GLOBAL.NO_MOBILE.NOT_AVAILABLE</div>
    <div class="content" translate>GLOBAL.NO_MOBILE.THANKS_USE_COMPUTER</div>
  </div>
</ng-template>

<ng-template #letterAvatar>
  <ngx-avatar
    [name]="username$ | async"
    [size]="40"
    [matMenuTriggerFor]="logoutMenu"
  ></ngx-avatar>
</ng-template>

<mat-menu #logoutMenu="matMenu" class="logout-mat-menu">
  <button mat-menu-item (click)="logout()">
    <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt"></mat-icon>
    {{ 'GLOBAL.NO_MOBILE.LOGOUT' | translate }}
  </button>
</mat-menu>
