import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rk-blurry-background',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./blurry-background.component.scss'],
})
export class BlurryBackgroundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
