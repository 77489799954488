<rk-blurry-background>
  <ng-container *ngIf="!(isLoading$ | async)">
    <div class="disabled-modal" *ngIf="radios$ | async as radios">
      <mat-icon
        class="icon-mic"
        fontSet="fa"
        fontIcon="fa-microphone-alt-slash"
      ></mat-icon>
      <h1>
        {{ 'GLOBAL.DISABLED.TITLE' | translate: { name: (currentRadio$ | async).name } }}
      </h1>

      <div class="modal-line mb-20" translate>GLOBAL.DISABLED.INACTIVE</div>
      <div *ngIf="(radioPlan$ | async) < 2" class="modal-line mb-20" translate>
        GLOBAL.DISABLED.EXPIRED
      </div>
      <rk-button
        [label]="'GLOBAL.NOACCESS.UPGRADE' | translate"
        [style]="{ 'margin-bottom': radios.length ? '40px' : '0' }"
        [btnBig]="true"
        (click)="updateOffer()"
      ></rk-button>
      <br />
      <div class="modal-line mb-20" translate *ngIf="radios.length">
        GLOBAL.DISABLED.OTHER_RADIO
      </div>

      <ng-container>
        <div class="other-radios-container">
          <div class="other-radios">
            <span
              *ngIf="radios.length > 5"
              class="arrow arrow-left"
              [class.disabled]="
                componentRef && componentRef.directiveRef.getIndex() === 0
              "
            >
              <mat-icon fontSet="fa" fontIcon="fa-angle-left"></mat-icon>
            </span>
            <swiper [config]="swiperConfig" (indexChange)="indexChange($event)">
              <div
                *ngFor="let radio of radios"
                (click)="switchToRadio(radio.id)"
                class="other-radios-zone"
                [ngClass]="{ curr: (currentRadio$ | async).id === radio.id }"
              >
                <ng-container *ngIf="(currentRadio$ | async).id !== radio.id">
                  <img [src]="radio.logo" />
                  <div>
                    {{ radio.name || ('GLOBAL.MODAL.CHANGE_RADIO.NONAME' | translate) }}
                  </div>
                </ng-container>
              </div>
            </swiper>
            <span
              *ngIf="radios.length > 5"
              class="arrow arrow-right"
              [class.disabled]="
                componentRef && componentRef.directiveRef.getIndex() >= radios.length - 5
              "
            >
              <mat-icon fontSet="fa" fontIcon="fa-angle-right"></mat-icon>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</rk-blurry-background>
