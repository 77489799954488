import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TimezoneState } from '@app/core/states/timezone.state';
import { Observable, Subscription } from 'rxjs';
import { Timezone } from '@app/core/models/Timezone';
import { TimezonesRequest } from '@app/core/states/timezone.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ErrorFormFormat,
  FormHelperService,
  UiStoreHelperService,
} from '@radioking/shared/common-services';
import { filter } from 'rxjs/operators';
import {
  StartupRadioFailure,
  StartupRadioRequest,
  StartupRadioSuccess,
} from '@app/core/states/radio.actions';
import { Intercom } from 'ng-intercom';
import * as amplitude from 'amplitude-js';
import * as moment from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { RadioNameTimezone } from '@app/core/models/Radio';

@Component({
  selector: 'rk-startup-modal',
  templateUrl: './startup-modal.component.html',
  styleUrls: ['./startup-modal.component.scss'],
})
export class StartupModalComponent implements OnInit, OnDestroy {
  @ViewChild('tzModal') tzModal: TemplateRef<any>;

  startupForm: FormGroup;
  startupErrors: ErrorFormFormat;

  @Select(TimezoneState.timezoneList)
  timezones$: Observable<Timezone[]>;

  userTimezone: Timezone;
  isSaving$: Observable<boolean>;

  subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly formHelper: FormHelperService,
    private intercom: Intercom,
    private dialog: MatDialog,
    private readonly uiHelper: UiStoreHelperService,
  ) {}

  ngOnInit() {
    this.intercom.trackEvent('open radio name modal');
    amplitude.getInstance().logEvent('open radio name modal');
    this.store.dispatch(new TimezonesRequest());

    this.isSaving$ = this.uiHelper.getLoadingState(
      [StartupRadioRequest],
      [StartupRadioSuccess, StartupRadioFailure],
    );
    this.subscription.add(
      this.timezones$.pipe(filter(data => !!data)).subscribe(timezones => {
        const tzGuess = moment.tz.guess();
        this.userTimezone = timezones.find(({ name }) => name.includes(tzGuess));
        this.createForm(timezones);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm(timezones: Timezone[]) {
    this.startupErrors = {
      name: {},
    };

    const tz = timezones.find(timezone => timezone.code === this.userTimezone.code);

    this.startupForm = new FormBuilder().group({
      name: ['', Validators.required],
      timezone: [tz || timezones[0]],
    });

    this.startupForm.valueChanges.subscribe(() => {
      this.formHelper.updateErrorMapping(this.startupForm, this.startupErrors);
    });
  }

  editTimezoneModal() {
    this.dialog.open(this.tzModal);
  }

  closeDialog() {
    this.startupForm.patchValue({ timezone: this.userTimezone });
    this.dialog.closeAll();
  }

  editTimezone() {
    this.userTimezone = this.startupForm.value['timezone'];
    this.dialog.closeAll();
  }

  submit() {
    this.formHelper.forceValidationTrigger(this.startupForm);
    if (this.startupForm.valid) {
      this.intercom.trackEvent('set radio name');
      amplitude.getInstance().logEvent('set radio name');
      const radioData: RadioNameTimezone = {
        name: this.startupForm.value.name,
        timezone: this.startupForm.value.timezone,
      };
      this.store.dispatch(new StartupRadioRequest(radioData));
    }
  }
}
