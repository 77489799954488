import { Preset } from '@app/core/models/Preset';
import { Radio } from '@app/core/models/Radio';

const tag = '[Authorization]';

export class GetRolesForRadioRequest {
  static readonly type = `${tag} request radio authorization capabilitys`;

  constructor(public radioId: number) {}
}

export class GetRolesForRadioSuccess {
  static readonly type = `${tag} success radio authorization capabilitys`;

  constructor(public roles: Preset[]) {}
}

export class GetRolesForRadioFailure {
  static readonly type = `${tag} error radio authorization capabilitys`;

  constructor(public error: Error) {}
}

export class SpecificRadioSuccess {
  static readonly type = `${tag} success specific radio`;

  constructor(public radio: Radio) {}
}
