import { RadioSettings } from '@app/core/models/Radio';
import {
  BroadcastingSettings,
  CoversSettings,
  DirectorySettings,
  GeneralSettings,
  IPBlacklist,
  PlatformSettings,
  SecuritySettings,
  SettingsV2,
  SocialSettings,
  StreamSettings,
} from '@app/core/models/Settings';

const tag = '[Settings]';

export class SettingsRequest {
  static readonly type = `${tag} request settings`;
  constructor() {}
}

export class SettingsSuccess {
  static readonly type = `${tag} success settings`;

  constructor(public settings: RadioSettings) {}
}

export class SettingsFailure {
  static readonly type = `${tag} error settings`;

  constructor(public error: Error) {}
}

export class SettingsV2Request {
  static readonly type = `${tag} request settings V2`;
  constructor() {}
}

export class SettingsV2Success {
  static readonly type = `${tag} success settings V2`;

  constructor(public settingsV2: SettingsV2) {}
}

export class SettingsV2Failure {
  static readonly type = `${tag} error settings V2`;

  constructor(public error: Error) {}
}

export class UpdateSettingsGeneralRequest {
  static readonly type = `${tag} request update general settings`;
  constructor(public settings: GeneralSettings, public file?: File) {}
}

export class UpdateSettingsBroadcastingRequest {
  static readonly type = `${tag} request update broadcasting settings`;
  constructor(public settings: BroadcastingSettings) {}
}

export class UpdateSettingsStreamRequest {
  static readonly type = `${tag} request update stream settings`;
  constructor(public settings: StreamSettings) {}
}

export class UpdateSettingsPlatformRequest {
  static readonly type = `${tag} request update platform settings`;
  constructor(public settings: PlatformSettings, public file?: File | false) {}
}

export class UpdateSettingsDirectoryRequest {
  static readonly type = `${tag} request update directory settings`;
  constructor(public settings: DirectorySettings) {}
}

export class UpdateSettingsSocialRequest {
  static readonly type = `${tag} request update social settings`;
  constructor(public settings: SocialSettings) {}
}

export class LoginTwitterRequest {
  static readonly type = `${tag} request login Twitter`;
  constructor() {}
}

export class LogoutTwitterRequest {
  static readonly type = `${tag} request logout Twitter`;
  constructor() {}
}

export class LogoutTwitterSuccess {
  static readonly type = `${tag} success logout Twitter`;
  constructor() {}
}

export class LogoutTwitterFailure {
  static readonly type = `${tag} error logout Twitter`;
  constructor(error: Error) {}
}

export class UpdateSettingsSecurityRequest {
  static readonly type = `${tag} request update security settings`;
  constructor(public settings: SecuritySettings) {}
}

export class UpdateSettingsV2Request {
  static readonly type = `${tag} request update settings V2`;
  constructor(public settings: Partial<SettingsV2>) {}
}

export class UpdateSettingsV2Success {
  static readonly type = `${tag} success update settings V2`;
  constructor(public settings: SettingsV2) {}
}

export class UpdateSettingsV2Failure {
  static readonly type = `${tag} error update settings V2`;
  constructor(public error: Error) {}
}

export class UpdateSettingsCoverRequest {
  static readonly type = `${tag} request update cover settings`;

  constructor(public settings: CoversSettings) {}
}

export class IPBlacklistRequest {
  static readonly type = `${tag} request IP blacklist`;
  constructor() {}
}

export class IPBlacklistSuccess {
  static readonly type = `${tag} success IP blacklist`;
  constructor(public ipBlacklist: IPBlacklist[]) {}
}

export class UpdateSettingsSuccess {
  static readonly type = `${tag} success update settings`;
  constructor() {}
}

export class UpdateNeedRestartRadio {
  static readonly type = `${tag} update need a restart modal`;
  constructor() {}
}

export class UpdateSettingsFailure {
  static readonly type = `${tag} error update settings`;

  constructor(public error: Error) {}
}
