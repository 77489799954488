import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { of } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { InputDirective } from '../../directive/input.directive';

@Component({
  selector: 'rk-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldComponent implements OnInit, AfterViewInit {
  @ContentChild(InputDirective)
  child: InputDirective;

  @Input()
  label: string;

  @Input()
  hintRight: string;

  @Input()
  hintQuestionMark: string;

  @Input()
  hintLabel: string;

  @Input()
  @HostBinding('class.has-bottom-margin')
  hasBottomMargin = false;

  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Input()
  hideCharLimit = false;

  chars: string;

  constructor(private readonly renerer: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.child) {
      of(this.child.el)
        .pipe(
          delay(0),
          filter(data => !this.hideCharLimit),
          filter(el => !!el),
          map(
            (el: ElementRef<HTMLInputElement | HTMLTextAreaElement>) =>
              el.nativeElement.maxLength,
          ),
          filter(val => val > 0),
        )
        .subscribe((data: number) => {
          this.chars = `${data}`;
        });
    }
  }
  /*
  ngAfterViewInit(): void {
    this.onlyShowOneError();
    this.contentChildren.changes.subscribe(() => {
      this.onlyShowOneError();
    });
  }

  onlyShowOneError() {
    setTimeout(() => {
      this.contentChildren.forEach((item, i) => {
        item.setVisible();
        if (i === 0) {
          item.setVisible();
          return;
        }
        item.setHide();

      });
    });
  }
*/
}
