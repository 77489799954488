import {
  Action,
  Actions,
  ofActionDispatched,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  CancelUploadFile,
  ClearUploadList,
  PendingUploadFile,
  RemoveUploadFile,
  RetryUploadFile,
  StartUploadFile,
  UploadFileFailure,
  UploadFileList,
  UploadFileRequest,
  UploadFileSuccess,
  UploadFileWrongFormat,
  UploadRequestTracking,
  UploadTracking,
} from './upload.actions';
import { Logger } from '@radioking/shared/logger';
import { RootBac } from '@app/library/models/bac.model';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { catchError, filter, flatMap, last, map, takeUntil, tap } from 'rxjs/operators';
import { UploadService } from '../services/upload.service';
import { TracksUploaded, UpdateTrackFilter } from '@app/library/states/tracks.actions';
import { TrackService } from '@app/library/services/track.service';
import { ProcessApiService } from '../services/process-api.service';
import { of } from 'rxjs';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { Intercom } from 'ng-intercom';
import * as amplitude from 'amplitude-js';
import { Playlist } from '@app/library/models/playlist.model';
import { Injectable } from '@angular/core';

const log = new Logger('upload store');

export enum UploadFileState {
  READY = 'ready',
  UPLOADING = 'uploading',
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  ERROR = 'error',
  DUPLICATE = 'duplicate',
  WRONG_FORMAT = 'wrong_format',
  FILE_TOO_LARGE = 'file_too_large',
}
export interface UploadTrack {
  id: number;
  file: File;
  progress: number;
  group: number;
  box: RootBac;
  playlist: Playlist;
  state: UploadFileState;
  process: string;
  duplicateBox?: number;
}

export interface UploadedTrackApiModel {
  process: string;
  track: {
    idtrack: number;
    idbox: number;
    title: string;
    artist: string;
    album: string;
    year: number;
    bitrate: number;
    cover_url: string;
    is_custom_cover: boolean;
    audio_url: string;
    upload_date: string;
    bpm: number;
    locked: boolean;
    playtime: number;
    buy_link: string;
  };
}

export class UploadStateModel {
  [key: string]: UploadTrack;
}

@State<UploadStateModel>({
  name: 'upload',
  defaults: {},
})
@Injectable()
export class UploadState {
  private static UPLOAD_KEY = 'track_upload_';
  private static lastId = 0;
  private static lastGroupId = 0;

  constructor(
    private readonly uploadService: UploadService,
    private readonly trackService: TrackService,
    private readonly store: Store,
    private actions$: Actions,
    private readonly processApiService: ProcessApiService,
    private readonly intercom: Intercom,
  ) {
    this.actions$
      .pipe(
        ofActionDispatched(PendingUploadFile),
        flatMap(
          (action: PendingUploadFile) =>
            this.store.dispatch(
              new StartUploadFile(action.file, action.box, action.id, action.playlist),
            ),
          3,
        ),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionDispatched(UploadRequestTracking),
        flatMap(
          (action: UploadRequestTracking) =>
            this.store.dispatch(
              new UploadTracking(
                action.track,
                action.fileID,
                action.box,
                action.playlist,
              ),
            ),
          5,
        ),
      )
      .subscribe();
  }

  @Selector()
  static uploads(state: UploadStateModel): any {
    return state;
  }

  @Selector()
  static uploadsArray(state: UploadStateModel): any[] {
    return Object.values(state).sort((a, b) => {
      if (a.group < b.group) {
        return 1;
      }
      if (a.group > b.group) {
        return -1;
      }
      return 0;
    });
  }

  @Selector()
  static hasUploads(state: UploadStateModel): boolean {
    return UploadState.uploadsArray(state).length > 0;
  }

  @Selector()
  static uploadPercentage(state: UploadStateModel): (group: number) => number {
    return group => {
      const uploadsOfGroup = Object.values(state).filter(data => data.group === group);
      if (!uploadsOfGroup.length) {
        return 100;
      }
      const totalValues = uploadsOfGroup
        .map(data => data.progress)
        .reduce((a, b) => a + b, 0);
      const percentage = totalValues / uploadsOfGroup.length;
      return Math.round(percentage || 0);
    };
  }

  @Selector()
  static uploadPercentageGroup(state: UploadStateModel): number {
    return UploadState.uploadPercentage(state)(UploadState.lastGroupId);
  }

  @Selector()
  static allCompleted(state: UploadStateModel): boolean {
    return (
      Object.values(state)
        .map(data => data.state)
        .filter(data => data === UploadFileState.COMPLETE).length ===
      Object.keys(state).length
    );
  }

  @Selector()
  static remainingFiles(state: UploadStateModel): boolean {
    const remaining =
      Object.values(state)
        .map(data => data.state)
        .filter(
          data => data === UploadFileState.READY || data === UploadFileState.UPLOADING,
        ).length > 0;
    return remaining;
  }

  @Selector()
  static hasErrors(state: UploadStateModel): boolean {
    return (
      Object.values(state)
        .map(data => data.state)
        .filter(
          data => data === UploadFileState.DUPLICATE || data === UploadFileState.ERROR,
        ).length > 0
    );
  }

  @Action(UploadFileList)
  uploadFileList(
    ctx: StateContext<UploadStateModel>,
    { fileList, box, playlist }: UploadFileList,
  ) {
    if (!UploadState.remainingFiles(ctx.getState())) {
      UploadState.lastGroupId += 1;
    }
    Array.from(fileList).forEach(file => {
      const mp3Type = new RegExp(/(mp3|mpeg)$/);
      if (mp3Type.test(file.type)) {
        return ctx.dispatch(
          new UploadFileRequest(file, (UploadState.lastId += 1), box, playlist),
        );
      }
      const shouldTruncFileName = file.name.length > 20;
      const truncateFileName =
        file.name.substr(0, 20) + (shouldTruncFileName ? '...' : '');
      return ctx.dispatch(new UploadFileWrongFormat({ file: truncateFileName }));
    });
  }

  @Action(SwitchToRadioRequest)
  cleanState(ctx: StateContext<UploadStateModel>) {
    ctx.setState({});
  }

  @Action(CancelUploadFile)
  cancelFile(ctx: StateContext<UploadStateModel>, { id }: CancelUploadFile) {
    const fileID = UploadState.UPLOAD_KEY + id;
    ctx.patchState({
      [fileID]: {
        ...ctx.getState()[fileID],
        progress: 100,
        state: UploadFileState.CANCELED,
      },
    });
  }

  @Action(StartUploadFile)
  startUpload(
    ctx: StateContext<UploadStateModel>,
    { file, box, id, playlist }: StartUploadFile,
  ) {
    const fileID = UploadState.UPLOAD_KEY + id;
    if (!ctx.getState()[fileID]) {
      return;
    }
    if (ctx.getState()[fileID].state === UploadFileState.CANCELED) {
      return;
    }
    const stopCondition$ = this.actions$.pipe(
      ofActionDispatched(CancelUploadFile),
      filter(canceledID => {
        return canceledID.id === id;
      }),
      tap(() => {
        throw new Error('CANCELED');
      }),
    );
    const stopCondition2$ = this.actions$.pipe(
      ofActionDispatched(SwitchToRadioRequest),
      tap(() => {
        throw new Error('STOPPED');
      }),
    );

    return this.uploadService
      .uploadFile(
        file,
        this.store.selectSnapshot(RadioState.currentRadioId),
        box.id,
        playlist ? playlist.id : null,
      )
      .pipe(
        takeUntil(stopCondition$),
        takeUntil(stopCondition2$),
        map(data => data),
        tap(message => this.updateProgressState(message, file, ctx, fileID)),
        last(),
        map(data => {
          return ctx.dispatch(
            new UploadFileSuccess(data.body, fileID, box.name, playlist),
          );
        }),
        catchError(err => {
          return ctx.dispatch(new UploadFileFailure(err, fileID));
        }),
      );
  }

  @Action([UploadFileRequest, RetryUploadFile])
  uploadFile(
    ctx: StateContext<UploadStateModel>,
    { file, box, id, playlist }: UploadFileRequest,
  ) {
    const fileID = UploadState.UPLOAD_KEY + id;
    ctx.patchState({
      [fileID]: {
        file,
        box,
        playlist,
        id,
        group: UploadState.lastGroupId,
        progress: 0,
        state: UploadFileState.READY,
        process: null,
      },
    });
    return ctx.dispatch(new PendingUploadFile(file, box, id, playlist));
  }

  @Action(UploadFileSuccess)
  uploadFileSuccess(
    ctx: StateContext<UploadStateModel>,
    { track, fileID, box, playlist }: UploadFileSuccess,
  ) {
    this.intercom.trackEvent('add track');
    amplitude.getInstance().logEvent('add track');
    ctx.patchState({
      [fileID]: {
        ...ctx.getState()[fileID],
        process: track.process,
      },
    });

    return ctx.dispatch(new UploadRequestTracking(track, fileID, box, playlist));
  }

  @Action(UploadTracking)
  uploadTracking(
    ctx: StateContext<UploadStateModel>,
    { track, fileID, box, playlist }: UploadTracking,
  ) {
    const trackObject = this.trackService.convertToTrack(track.track);

    return this.processApiService
      .waitForTrackProcess(
        track.process,
        this.store.selectSnapshot(RadioState.currentRadioId),
        trackObject.id,
      )
      .pipe(
        catchError(() => of('')),
        flatMap(() =>
          this.trackService.getTrackById(
            this.store.selectSnapshot(RadioState.currentRadioId),
            trackObject.id,
          ),
        ),
        flatMap(data => {
          this.updateFileState(ctx, fileID, UploadFileState.COMPLETE);
          return ctx
            .dispatch(new TracksUploaded(data, box, playlist))
            .pipe(flatMap(() => ctx.dispatch(new UpdateTrackFilter(data, box))));
        }),
        catchError(() => {
          this.updateFileState(ctx, fileID, UploadFileState.COMPLETE);
          trackObject.processing = false;
          return ctx
            .dispatch(new TracksUploaded(trackObject, box, playlist))
            .pipe(flatMap(() => ctx.dispatch(new UpdateTrackFilter(trackObject, box))));
        }),
      );
  }

  @Action(UploadFileFailure)
  uploadFileFailure(
    ctx: StateContext<UploadStateModel>,
    { error, fileID }: UploadFileFailure,
  ) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 409) {
        this.updateFileState(ctx, fileID, UploadFileState.DUPLICATE, error.error);
      } else if (error.status === 413) {
        this.updateFileState(ctx, fileID, UploadFileState.FILE_TOO_LARGE);
      } else if (error.status === 415) {
        this.updateFileState(ctx, fileID, UploadFileState.WRONG_FORMAT);
      } else {
        this.updateFileState(ctx, fileID, UploadFileState.ERROR);
      }
    }
  }

  @Action(RemoveUploadFile)
  removeUploadFile(ctx: StateContext<UploadStateModel>, { id }: RemoveUploadFile) {
    const fileID = UploadState.UPLOAD_KEY + id;
    const state = ctx.getState();
    delete state[fileID];
    ctx.patchState(state);
  }

  @Action(ClearUploadList)
  clearUploadList(ctx: StateContext<UploadStateModel>) {
    const state = ctx.getState();
    Object.values(state).forEach(track => {
      if (
        track.state === UploadFileState.COMPLETE ||
        track.state === UploadFileState.ERROR ||
        track.state === UploadFileState.DUPLICATE ||
        track.state === UploadFileState.CANCELED
      ) {
        delete state[UploadState.UPLOAD_KEY + track.id];
      }
    });
    ctx.patchState(state);
  }

  private updateProgressState(
    event: HttpEvent<any>,
    file: File,
    ctx: StateContext<UploadStateModel>,
    fileID: string,
  ) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        ctx.patchState({
          [fileID]: {
            ...ctx.getState()[fileID],
            progress: percentDone,
            state: UploadFileState.UPLOADING,
          },
        });
        break;

      case HttpEventType.Response:
        this.updateFileState(ctx, fileID, UploadFileState.PROCESSING);
        break;
      default:
        return event;
    }
  }

  private updateFileState(
    ctx: StateContext<UploadStateModel>,
    fileID: string,
    fileState: UploadFileState,
    error?: object,
  ) {
    if (fileState === UploadFileState.ERROR) {
      ctx.patchState({
        [fileID]: {
          ...ctx.getState()[fileID],
          state: fileState,
          progress: 100,
        },
      });
    } else if (fileState === UploadFileState.DUPLICATE) {
      ctx.patchState({
        [fileID]: {
          ...ctx.getState()[fileID],
          state: fileState,
          duplicateBox: error && error['idbox'] ? error['idbox'] : null,
        },
      });
    } else {
      ctx.patchState({
        [fileID]: {
          ...ctx.getState()[fileID],
          state: fileState,
        },
      });
    }
  }
}
