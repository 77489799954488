import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export interface PresetApi {
  idpreset: number;
  name: string;
}

export interface Preset {
  id: number;
  keyString: string;
  translationKey: string;
}

interface TranslationPresetMapping {
  [key: string]: string;
}

export const translationsMaps: TranslationPresetMapping = {
  __dashboard__: marker('PRESET.TAG.DASHBOARD'),
  __library__: marker('PRESET.TAG.LIBRARY'),
  __programs__: marker('PRESET.TAG.PROGRAMS'),
  __breaks__: marker('PRESET.TAG.BREAKS'),
  __schedule__: marker('PRESET.TAG.SCHEDULE'),
  __planning__: marker('PRESET.TAG.PLANNING'),
  __statistics__: marker('PRESET.TAG.STATISTICS'),
  __widgets__: marker('PRESET.TAG.WIDGETS'),
  __settings__: marker('PRESET.TAG.SETTINGS'),
  __users__: marker('PRESET.TAG.USERS'),
  __radio__: marker('PRESET.TAG.RADIO'),
  __comments__: marker('PRESET.TAG.COMMENTS'),
  __livefeed__: marker('PRESET.TAG.LIVE_FEED'),
  __live__: marker('PRESET.TAG.LIVE'),
};

export function presetApiToPreset(pressetApi: PresetApi): Preset {
  return {
    id: pressetApi.idpreset,
    keyString: pressetApi.name,
    translationKey: translationsMaps[pressetApi.name],
  };
}

export const Roles = {
  DASHBOARD: '__dashboard__',
  LIBRARY: '__library__',
  PROGRAMS: '__programs__',
  BREAKS: '__breaks__',
  SCHEDULE: '__schedule__',
  PLANNING: '__planning__',
  STATISTICS: '__statistics__',
  WIDGETS: '__widgets__',
  SETTINGS: '__settings__',
  USERS: '__users__',
  RADIO: '__radio__',
  COMMENTS: '__comments__',
  LIVE_FEED: '__livefeed__',
  LIVE: '__live__',
  SUPER_USER_FAKE_ROLE: '__super_user_fake_role__',
};
