import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { AuthState } from '@app/core/states/auth.state';
import { filter, flatMap, take } from 'rxjs/operators';
import { SwitchToRadioRequestWithoutToast } from '@app/core/states/radio.actions';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root',
})
export class RadioIdResolver implements Resolve<any> {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly action$: Actions,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const radioId = +route.paramMap.get('radioId');
    const endURL =
      '/' +
      window.location.href
        .split('/')
        .slice(3)
        .join('/');

    return combineLatest([
      this.store.select(RadioState.radios),
      this.store.select(AuthState.isAuthenticated).pipe(
        filter(val => val),
        flatMap(() => this.store.select(AuthState.isSuperUser)),
      ),
      this.store.select(RadioState.currentRadioId).pipe(filter(val => !!val)),
      this.store.select(RadioState.hasRadiosBeenFetched),
    ]).pipe(
      filter(([d, a, b, hasRadioBeenFetched]) => hasRadioBeenFetched),
      take(1),
      flatMap(([radios, isSuperUser, currentRadioId]) => {
        const foundRadioUrl = radios.find(val => val.id === radioId);
        const foundRadioState = radios.find(val => val.id === currentRadioId);

        if (radioId === 0) {
          if (isSuperUser && !foundRadioState) {
            const radioIdToGo = radios[0].id;
            this.goToUrlCheaty(radioIdToGo, endURL);
            return EMPTY;
          }

          if (!foundRadioState) {
            const radioIdToGo = radios[0].id;
            this.goToUrlCheaty(radioIdToGo, endURL);
            return EMPTY;
          }

          if (!foundRadioState.name || !foundRadioState.name.length) {
            this.goToUrlCheaty(
              currentRadioId,
              `/radio/${currentRadioId}/configure-radio`,
            );
            return EMPTY;
          }

          this.goToUrlCheaty(currentRadioId, endURL === '/' ? '/radio/0' : endURL);
          return EMPTY;
        }

        if (radioId === currentRadioId) {
          if (!foundRadioState && !isSuperUser) {
            const radioIdToGo = radios[0].id;
            this.goToUrlCheaty(radioIdToGo, endURL);
            return EMPTY;
          }

          if (
            foundRadioState &&
            (!foundRadioState.name || !foundRadioState.name.length)
          ) {
            this.goToUrlCheaty(
              currentRadioId,
              `/radio/${currentRadioId}/configure-radio`,
            );
            return EMPTY;
          }
          return EMPTY;
        }

        if (isSuperUser) {
          // console.log('[RADIO ID RESOLVER ] SUPER USER DISPATCHING STUFF');
          return this.store.dispatch(new SwitchToRadioRequestWithoutToast(radioId));
        }

        if (foundRadioUrl) {
          // console.log('[RADIO ID RESOLVER ] FOUND DISPATCHING STUFF');
          return this.store.dispatch(new SwitchToRadioRequestWithoutToast(radioId));
        }

        this.goToUrlCheaty(currentRadioId, endURL);
        return EMPTY;
      }),
    );
  }

  goToUrlCheaty(radioId: number, urlToGo: string): void {
    let finalURL = urlToGo;
    if (urlToGo === '/login') {
      finalURL = '/';
    }
    this.action$
      .pipe(
        ofActionDispatched(Navigate),
        take(1),
      )
      .subscribe(() => {
        this.router.navigateByUrl(this.replaceRadioIdInUrl(finalURL, radioId));
      });
  }

  replaceRadioIdInUrl(url: string, radioId: number): string {
    return url.replace(/radio\/\d+/, `/radio/${radioId}`);
  }
}
