import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url && request.url.startsWith('http')) {
      return next.handle(request);
    }
    if (request.url && request.url.startsWith('assets')) {
      return next.handle(request);
    }
    const newRequest = request.clone({ url: '' + request.url });
    return next.handle(newRequest);
  }
}
