const tag = '[Iframe]';

export class IframeStoppingRadio {
  static readonly type = `${tag} stopping radio`;
  constructor() {}
}

export class IframeStoppedRadio {
  static readonly type = `${tag} stopped radio`;
  constructor() {}
}

export class IframeStartingRadio {
  static readonly type = `${tag} starting radio`;
  constructor() {}
}

export class IframeStartedRadio {
  static readonly type = `${tag} started radio`;
  constructor() {}
}
