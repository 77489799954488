import { ModelNav } from './navigation.service';
import { RoleOperatorActions } from '@app/core/services/role-helper.service';
import { Roles } from '@app/core/models/Preset';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const baseNav: ModelNav[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: marker('SIDENAV.MENU.DASHBOARD'),
    type: 'item',
    icon: 'fa-tachometer-alt',
    roles: {
      type: RoleOperatorActions.ALL,
      roleIds: [Roles.DASHBOARD],
    },
    url: '/dashboard',
    exactMatch: true,
  },
  {
    id: 'library',
    title: 'Library',
    translate: marker('SIDENAV.MENU.MEDIACENTER'),
    type: 'item',
    icon: 'fa-music',
    url: '/media',
    roles: Roles.LIBRARY,
  },
  {
    id: 'scheduling',
    translate: marker('SIDENAV.MENU.SCHEDULING'),
    type: 'collapse',
    icon: 'fa-calendar-alt',
    roles: {
      type: RoleOperatorActions.OR,
      operators: [
        {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PROGRAMS, Roles.LIBRARY],
        },
        {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PROGRAMS, Roles.LIBRARY, Roles.BREAKS],
        },
        {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PROGRAMS, Roles.LIBRARY, Roles.SCHEDULE],
        },
        {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PLANNING, Roles.LIBRARY],
        },
        {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PLANNING],
        },
      ],
    },
    children: [
      {
        id: 'Programs',
        translate: marker('SIDENAV.MENU.PROGRAMS'),
        type: 'item',
        url: '/scheduler/programs',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PROGRAMS, Roles.LIBRARY],
        },
      },
      {
        id: 'breaks',
        translate: marker('SIDENAV.MENU.BREAKS'),
        type: 'item',
        url: '/scheduler/break',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PROGRAMS, Roles.LIBRARY, Roles.BREAKS],
        },
      },
      {
        id: 'planning',
        translate: marker('SIDENAV.MENU.PLANNING'),
        type: 'item',
        url: '/scheduler/planning',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PROGRAMS, Roles.LIBRARY, Roles.SCHEDULE],
        },
      },
      {
        id: 'generation',
        translate: marker('SIDENAV.MENU.DAILY_GENERATION'),
        type: 'item',
        url: '/scheduler/generated-planning',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PLANNING, Roles.LIBRARY],
        },
      },
      {
        id: 'history',
        translate: marker('SIDENAV.MENU.HISTORY'),
        type: 'item',
        url: '/history',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [Roles.PLANNING],
        },
      },
    ],
  },
  {
    id: 'statistics',
    translate: marker('SIDENAV.MENU.STATISTICS_ITEM'),
    type: 'collapse',
    icon: 'fa-chart-bar',
    roles: Roles.STATISTICS,
    children: [
      {
        id: 'stats_direct',
        translate: marker('SIDENAV.MENU.STATISTICS.DIRECT'),
        type: 'item',
        url: '/statistics/live',
      },
      {
        id: 'general',
        translate: marker('SIDENAV.MENU.STATISTICS.GENERAL'),
        type: 'item',
        url: '/statistics/general',
      },
      {
        id: 'musique',
        translate: marker('SIDENAV.MENU.STATISTICS.MUSIQUE'),
        type: 'item',
        url: '/statistics/musics',
      },
      {
        id: 'auditeurs',
        translate: marker('SIDENAV.MENU.STATISTICS.AUDITEURS'),
        type: 'item',
        url: '/statistics/listeners',
      },
    ],
  },
  {
    id: 'direct',
    title: 'Page Layouts',
    type: 'item',
    translate: marker('SIDENAV.MENU.DIRECT'),
    icon: 'fa-microphone',
    url: '/live',
    roles: Roles.LIVE,
  },
  {
    id: 'djs',
    translate: marker('SIDENAV.MENU.DJS'),
    type: 'item',
    url: '/team',
    icon: 'fa-user-friends',
    roles: Roles.USERS,
  },
  {
    id: 'widget',
    title: 'Widget',
    translate: marker('SIDENAV.MENU.SHARE'),
    type: 'item',
    icon: 'fa-window-restore',
    url: '/widgets',
    roles: Roles.WIDGETS,
  },
  {
    id: 'admin',
    translate: marker('SIDENAV.MENU.ADMIN_ITEM'),
    type: 'collapse',
    icon: 'fa-screwdriver',
    isAdminRoute: true,
    children: [
      {
        id: 'admin_radio',
        translate: marker('SIDENAV.MENU.ADMIN.RADIO'),
        type: 'item',
        url: '/admin/radios',
      },
      {
        id: 'admin_users',
        translate: marker('SIDENAV.MENU.ADMIN.USERS'),
        type: 'item',
        url: '/admin/users',
      },
      {
        id: 'admin_consumption',
        translate: marker('SIDENAV.MENU.ADMIN.CONSUMPTION'),
        type: 'item',
        url: '/admin/consumption',
      },
      {
        id: 'admin_chronics',
        translate: marker('SIDENAV.MENU.ADMIN.CHRONICS'),
        type: 'item',
        url: '/admin/chronics',
      },
      {
        id: 'admin_alert',
        translate: marker('SIDENAV.MENU.ADMIN.ALERT'),
        type: 'item',
        url: '/admin/alert',
      },
      {
        id: 'admin_changelog',
        translate: marker('SIDENAV.MENU.ADMIN.CHANGELOG'),
        type: 'item',
        url: '/admin/changelog',
      },
    ],
  },
];
