<div fxFlex>
  <div *ngIf="(this.radioPlan$ | async) === 1" class="top-bar">
    <span>{{
      'RADIO.DEMO_DAYS_LEFT'
        | translate: { days: (currentRadio$ | async).demoStatus.expireDays }
    }}</span>
    | {{ 'RADIO.DEMO_UPDATE' | translate }}
    <a [href]="updateOfferUrl$ | async" target="_blank">{{
      'RADIO.DEMO_UPDATE_OFFER' | translate
    }}</a>
  </div>
  <mat-sidenav-container
    fxFill
    fullscreen
    [ngClass]="{ 'top-bar-shrinked': (radioPlan$ | async) === 1 }"
  >
    <mat-sidenav
      #sidenav
      [mode]="isOnMobile ? 'over' : 'side'"
      [disableClose]="!isOnMobile"
      ngClass.gt-sm="has-border"
      fxLayout="column"
      (openedChange)="sidenavStateChanged($event)"
    >
      <rk-fold-container-sidebar
        #sidebar
        [lockedOpen]="true"
        (foldedChanged)="changeFold($event)"
        [class.closed]="menuClosed"
      >
        <rk-radio-sidebar></rk-radio-sidebar>

        <div
          class="navbar-content"
          rkPerfectScrollbar
          [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
        >
          <rk-navigation [navigation]="navigation" layout="vertical"></rk-navigation>
        </div>

        <div class="logo-container" fxFlexAlign="center">
          <img class="logo" src="assets/rk-rect-gris.svg" alt="RadioKing logo" />
        </div>
        <div
          *ngIf="!isOnMobile"
          class="fold-menu"
          (click)="toggleSideNav($event)"
          (touchend)="onTouchFold()"
        >
          <mat-icon fontSet="fa" fontIcon="fa-angle-left"></mat-icon>
          <span translate *ngIf="!menuClosed">SIDEBAR.MENU.CLOSE_MENU</span>
          <span translate *ngIf="menuClosed">SIDEBAR.MENU.OPEN_MENU</span>
        </div>
      </rk-fold-container-sidebar>
    </mat-sidenav>

    <mat-sidenav-content>
      <rk-panel-container>
        <rk-panel-outside-content>
          <!-- app content -->
          <rk-header></rk-header>
          <!--
            <div class="navbar-content" rkPerfectScrollbar>
              <rk-navigation [navigation]="navigation" layout="vertical"></rk-navigation>
            </div>
          -->
          <div
            class="filler"
            [ngClass]="{ 'top-bar-shrinked': (radioPlan$ | async) === 1 }"
          >
            <router-outlet></router-outlet>
          </div>
        </rk-panel-outside-content>
      </rk-panel-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<rk-no-mobile></rk-no-mobile>
