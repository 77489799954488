import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortByDirective } from './sort-by.directive';
import { SortDirective } from './sort.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [SortByDirective, SortDirective],
  exports: [SortByDirective, SortDirective],
})
export class UiSortByModule {}
