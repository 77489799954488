import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UiStoreHelperService } from '@radioking/shared/common-services';
import {
  CreatePlaylistManualFailure,
  CreatePlaylistManualSuccess,
  EditPlaylistManualSuccess,
  EditPlaylistManualFailure,
  CreatePlaylistManualRequest,
  CreatePlaylistDynamicRequest,
  EditPlaylistManualRequest,
  CreatePlaylistDynamicSuccess,
  CreatePlaylistDynamicFailure,
  EditPlaylistDynamicRequest,
  EditPlaylistDynamicSuccess,
  EditPlaylistDynamicFailure,
} from '@app/library/states/playlists.actions';
import {
  TrackEditFailure,
  TrackEditRequest,
  TrackEditSuccess,
  TrackTagsSaveFailure,
  TrackTagsSaveRequest,
  TrackTagsSaveSuccess,
} from '@app/library/states/tracks.actions';
import { Actions, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RadioState } from '@app/core/states/radio.state';

@Component({
  selector: 'rk-sidenav-action-wrapper',
  templateUrl: './sidenav-action-wrapper.component.html',
  styleUrls: ['./sidenav-action-wrapper.component.scss'],
})
export class SidenavActionWrapperComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  cancelText: string;

  @Input()
  submitText: string;

  @Input()
  disabledSubmit = false;

  @Input()
  showLinkForCancel = false;

  @Input()
  hideBottom = false;

  @Input()
  private isContentLoading$: Observable<boolean>;

  @Input()
  enableScrollbar = true;

  @Input()
  actionRequest: any[] = [];

  @Input()
  actionDone: any[] = [];

  @Output()
  close = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  @Output()
  submit = new EventEmitter();

  @ViewChild('mainContent', { static: true })
  mainContent: ElementRef;

  @Input()
  isSaving$: Observable<boolean>;

  @Select(RadioState.currentPlanId)
  radioPlan$: Observable<number>;

  constructor(
    private readonly action$: Actions,
    private readonly uiHelper: UiStoreHelperService,
  ) {}

  ngOnInit() {
    // Leave this there as a compatibility reason.
    // Do not use anymore
    if (!this.isSaving$) {
      this.isSaving$ = this.uiHelper.getLoadingState(
        [
          TrackEditRequest,
          TrackTagsSaveRequest,
          CreatePlaylistManualRequest,
          CreatePlaylistDynamicRequest,
          EditPlaylistManualRequest,
          EditPlaylistDynamicRequest,
          ...this.actionRequest,
        ],
        [
          TrackEditSuccess,
          TrackEditFailure,
          TrackTagsSaveSuccess,
          TrackTagsSaveFailure,
          CreatePlaylistManualSuccess,
          CreatePlaylistManualFailure,
          CreatePlaylistDynamicSuccess,
          CreatePlaylistDynamicFailure,
          EditPlaylistManualSuccess,
          EditPlaylistManualFailure,
          EditPlaylistDynamicSuccess,
          EditPlaylistDynamicFailure,
          ...this.actionDone,
        ],
      );
    }

    if (this.isContentLoading$) {
      this.isContentLoading$.subscribe(data => {
        if (data && this.mainContent) {
          this.mainContent.nativeElement.scrollTop = 0;
        }
      });
    }
  }
}
