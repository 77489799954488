import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import init from 'helphero';
import { Intercom } from 'ng-intercom';
import * as amplitude from 'amplitude-js';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { AddDefaultTracksRequest } from '@app/library/states/tracks.actions';

const tours = {
  emptyMediaCenter: {
    en: 'pQ7NWN5MxFC',
    fr: 'dRHkwFwcLSm',
  },
  radioNotStarted: {
    en: 'xuWCoo2AI0l',
    fr: '179al2uJjy1',
    stepFinishedTracking: 'main quest step 5: share page',
  },
  addDefaultTracks: {
    en: '0hlWu90suUC',
    fr: '0hlWu90suUC',
    stepFinishedTracking: 'main quest step 1: upload tracks',
  },
  uploadTracks: {
    en: 'pQ7NWN5MxFC',
    fr: 'dRHkwFwcLSm',
    stepFinishedTracking: 'main quest step 1: upload tracks',
  },
  tutorialLibrary: {
    en: '31umwj9f9gN',
    fr: 'WulouPfLAsl',
    stepFinishedTracking: 'library quest step 3: playlists',
  },
  tutorialChronics: {
    en: '',
    fr: 'EImLxFuknHT',
    stepFinishedTracking: 'chronics quest step 2: schedule',
  },
};

const toursToFinishAllQuest = {
  fr: { library: 0, chronics: 0 },
  en: { library: 0 },
};

const questCompleteKey = 'QUESTS.COMPLETED';

const toursSteps = {
  startRadio: {
    id: 'cjxd0jkzx00va315hnll0jpe4',
    tracking: 'main quest step 2: start radio',
  },
  listenRadio: {
    id: 'cjxd0ksdg01fh315hyb55ks8v',
    tracking: 'main quest step 3: listen radio',
  },
  radioSettings: {
    id: 'ck5wfndrv00g43f5jl6z8qgr8',
    tracking: 'main quest step 4: radio settings',
  },
  libraryBoxes: {
    id: 'cjxehcq8r002c315hkqdd4xgq',
    tracking: 'library quest step 1: boxes',
  },
  libraryFilter: {
    id: 'cjxeehpq7000k315hfjuahr44',
    tracking: 'library quest step 2: filters',
  },
  chronicsExplanation: {
    id: 'cjxk6arjk000d315hl62hrcyd',
    tracking: 'chronics quest step 1: explanation',
  },
};

declare type TourEventKind =
  | 'tour_started'
  | 'tour_completed'
  | 'tour_advanced'
  | 'tour_cancelled'
  | 'tour_interrupted'
  | 'error';
declare interface TourEvent {
  kind: TourEventKind;
  details?: string;
  tourId?: string;
  stepId?: string;
}
declare interface Step {
  id: string;
  name: string;
}
declare interface Tour {
  id: string;
  name: string;
  steps: Step[];
}
declare interface TourEventInfo {
  tour?: Tour;
  step?: Step;
}
declare interface Data {
  [key: string]: boolean | number | string | undefined | null;
}
declare type ChecklistEventKind = 'checklist_completed' | 'checklist_item_completed';
declare interface ChecklistEvent {
  kind: ChecklistEventKind;
  checklistId: string;
  itemId?: string;
}
declare interface ChecklistItem {
  id: string;
  name: string;
}
declare interface Checklist {
  id: string;
  name: string;
  items: ChecklistItem[];
}
declare interface ChecklistEventInfo {
  checklist: Checklist;
  item?: ChecklistItem;
}
declare interface HelpHero {
  startTour: (
    id: string,
    options?: {
      skipIfAlreadySeen: boolean;
    },
  ) => void;
  advanceTour: () => void;
  cancelTour: () => void;
  identify: (id: string | number, data?: Data) => void;
  update: (data: Data | ((data: Data) => Data | null | undefined)) => void;
  anonymous: () => void;
  openChecklist: () => void;
  closeChecklist: () => void;
  startChecklist: (id: string) => void;
  showBeacon: () => void;
  hideBeacon: () => void;
  on(kind: TourEventKind, fn: (ev: TourEvent, info: TourEventInfo) => void): void;
  off(kind: TourEventKind, fn: (ev: TourEvent, info: TourEventInfo) => void): void;
  on(
    kind: ChecklistEventKind,
    fn: (ev: ChecklistEvent, info: ChecklistEventInfo) => void,
  ): void;
  off(
    kind: ChecklistEventKind,
    fn: (ev: ChecklistEvent, info: ChecklistEventInfo) => void,
  ): void;
}

const ofType = (type: TourEventKind) => (
  obs$: Observable<EventType>,
): Observable<{ event: TourEvent; info: TourEventInfo }> =>
  obs$.pipe(
    filter(op => op.type === type),
    map(({ event, info }) => ({ event, info })),
  );

const ofTour = (id: string[]) => (
  obs$: Observable<EventType>,
): Observable<{ event: TourEvent; info: TourEventInfo }> =>
  obs$.pipe(
    filter(op => id.includes(op.event.tourId)),
    map(({ event, info }) => ({ event, info })),
  );

interface EventType {
  type: TourEventKind;
  event: TourEvent;
  info: TourEventInfo;
}
@Injectable({
  providedIn: 'root',
})
export class HelpHeroService {
  private hero: HelpHero;
  private lang = 'en';

  private events = new Subject<EventType>();

  private hasRegisterStarted = false;
  private isDoingFirstQuest = false;

  constructor(private readonly intercom: Intercom) {}

  private registerEvents() {
    if (this.hero) {
      const listenTo = (type: TourEventKind) => (
        event: TourEvent,
        info: TourEventInfo,
      ) => {
        this.events.next({ event, info, type });
      };
      const events: TourEventKind[] = [
        'tour_started',
        'tour_completed',
        'tour_advanced',
        'tour_cancelled',
        'tour_interrupted',
        'error',
      ];
      for (const event of events) {
        this.hero.on(event, listenTo(event));
      }
      this.trackEvents();
    }
  }

  trackEvents() {
    if (this.hero) {
      this.event$.pipe(ofType('tour_completed'), debounceTime(100)).subscribe(tour => {
        switch (tour.event.tourId) {
          case tours.uploadTracks.en:
          case tours.uploadTracks.fr:
            const eventProperties = {
              tracks: 'upload tracks',
            };
            this.intercom.trackEvent(
              tours.uploadTracks.stepFinishedTracking,
              eventProperties,
            );
            amplitude
              .getInstance()
              .logEvent(tours.uploadTracks.stepFinishedTracking, eventProperties);
            break;
          case tours.tutorialLibrary.en:
          case tours.tutorialLibrary.fr:
            this.intercom.trackEvent(tours.tutorialLibrary.stepFinishedTracking);
            amplitude.getInstance().logEvent(tours.tutorialLibrary.stepFinishedTracking);
            this.questProgression('library');
            break;
          case tours.tutorialChronics.fr:
            this.intercom.trackEvent(tours.tutorialChronics.stepFinishedTracking);
            amplitude.getInstance().logEvent(tours.tutorialChronics.stepFinishedTracking);
            this.questProgression('chronics');
            break;
          case tours.radioNotStarted.en:
          case tours.radioNotStarted.fr:
            this.intercom.trackEvent(tours.radioNotStarted.stepFinishedTracking);
            amplitude.getInstance().logEvent(tours.radioNotStarted.stepFinishedTracking);
            break;
        }
      });

      this.event$
        .pipe(
          ofType('tour_advanced'),
          ofTour(Object.values(tours.radioNotStarted)),
          debounceTime(100),
        )
        .subscribe(({ event }) => {
          if (event.stepId === toursSteps.startRadio.id) {
            this.intercom.trackEvent(toursSteps.startRadio.tracking);
            amplitude.getInstance().logEvent(toursSteps.startRadio.tracking);
          } else if (event.stepId === toursSteps.radioSettings.id) {
            this.intercom.trackEvent(toursSteps.radioSettings.tracking);
            amplitude.getInstance().logEvent(toursSteps.radioSettings.tracking);
          } else if (event.stepId === toursSteps.listenRadio.id) {
            this.intercom.trackEvent(toursSteps.listenRadio.tracking);
            amplitude.getInstance().logEvent(toursSteps.listenRadio.tracking);
            this.isDoingFirstQuest = false;
          }
        });

      this.event$
        .pipe(
          ofType('tour_advanced'),
          ofTour(Object.values(tours.tutorialLibrary)),
          debounceTime(100),
        )
        .subscribe(({ event }) => {
          if (event.stepId === toursSteps.libraryBoxes.id) {
            this.intercom.trackEvent(toursSteps.libraryBoxes.tracking);
            amplitude.getInstance().logEvent(toursSteps.libraryBoxes.tracking);
          } else if (event.stepId === toursSteps.libraryFilter.id) {
            this.intercom.trackEvent(toursSteps.libraryFilter.tracking);
            amplitude.getInstance().logEvent(toursSteps.libraryFilter.tracking);
          }
        });
      this.event$
        .pipe(
          ofType('tour_advanced'),
          ofTour(Object.values(tours.tutorialChronics)),
          debounceTime(100),
        )
        .subscribe(({ event }) => {
          if (event.stepId === toursSteps.chronicsExplanation.id) {
            this.intercom.trackEvent(toursSteps.chronicsExplanation.tracking);
            amplitude.getInstance().logEvent(toursSteps.chronicsExplanation.tracking);
          }
        });
    }
  }

  questProgression(quest: string) {
    const currentCompleted = localStorage.getItem(questCompleteKey)
      ? JSON.parse(localStorage.getItem(questCompleteKey))
      : toursToFinishAllQuest;
    currentCompleted[this.lang][quest] = 1;
    localStorage.setItem(questCompleteKey, JSON.stringify(currentCompleted));
  }

  @Dispatch()
  addDefaultTracks() {
    return new AddDefaultTracksRequest();
  }

  registerUser(user: object, idRadio: number) {
    this.lang = user['lang'];
    const isSuperUser = user['isSuperUser'] || false;

    const userProgression = JSON.parse(localStorage.getItem(questCompleteKey));
    const questDone = userProgression
      ? Object.values(userProgression[this.lang]).reduce((prev, next) => {
          return prev && next === 1;
        }, true)
      : false;

    if (!isSuperUser && !questDone && user['whmcsId']) {
      this.hero = init(environment.helphero.id);
      this.registerEvents();
      const helpHeroId = `${environment.helphero.userIdPrefix}_${user['id']}_${idRadio}`;
      this.hero.identify(helpHeroId, {
        lang: user['lang'],
      });
    }
  }

  private getTourId(data: { fr: string; en: string }): string {
    if (this.lang === 'fr') {
      return data.fr;
    } else {
      return data.en;
    }
  }

  startTourEmptyMediacenter() {
    this.isDoingFirstQuest = true;
    if (this.hero) {
      this.hero.startTour(this.getTourId(tours.emptyMediaCenter), {
        skipIfAlreadySeen: false,
      });

      this.event$
        .pipe(
          ofType('tour_started'),
          filter(({ event }) => event.tourId === tours.addDefaultTracks.en),
          take(1),
        )
        .subscribe(() => {
          const eventProperties = {
            tracks: 'default tracks',
          };
          this.intercom.trackEvent(
            tours.addDefaultTracks.stepFinishedTracking,
            eventProperties,
          );
          amplitude
            .getInstance()
            .logEvent(tours.addDefaultTracks.stepFinishedTracking, eventProperties);
          this.addDefaultTracks();
        });
    }
  }
  
  startTourRadioNotStarted() {
    if (this.hero) {
      const tourId = this.getTourId(tours.radioNotStarted);
      this.hero.startTour(tourId, {
        skipIfAlreadySeen: true,
      });

      this.event$
        .pipe(
          ofType('tour_completed'),
          filter(({ event }) => event.tourId === tourId),
          take(1),
        )
        .subscribe(() => {
          this.registerAsRadioStarted();
        });
    }
  }

  startTourTutorialLibrary() {
    if (this.hero && !this.isDoingFirstQuest) {
      if (this.lang === 'fr') {
        this.hero.startTour(tours.tutorialLibrary.fr, { skipIfAlreadySeen: true });
      } else {
        this.hero.startTour(tours.tutorialLibrary.en, { skipIfAlreadySeen: true });
      }
      this.questProgression('library');
    }
  }

  startTourTutorialChronics() {
    if (this.hero) {
      this.hero.startTour(tours.tutorialChronics.fr, { skipIfAlreadySeen: true });
      this.questProgression('chronics');
    }
  }

  registerAsRadioStarted() {
    if (this.hero) {
      if (this.hasRegisterStarted) {
        return;
      }
      this.hasRegisterStarted = true;
      this.hero.update(data => ({
        ...data,
        isStartupQuestDone: true,
      }));
    }
  }

  private get event$(): Observable<EventType> {
    return this.events.asObservable();
  }
}
