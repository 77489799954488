import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  GetRolesForRadioFailure,
  GetRolesForRadioRequest,
  GetRolesForRadioSuccess,
  SpecificRadioSuccess,
} from './authorization.actions';
import { Logger } from '@radioking/shared/logger';
import { RadioService } from '@app/core/services/radio.service';
import { catchError, flatMap } from 'rxjs/operators';
import { AuthState, AuthStateModel } from '@app/core/states/auth.state';
import { RadioState, RadioStateModel } from '@app/core/states/radio.state';
import { Preset } from '@app/core/models/Preset';
import { Navigate } from '@ngxs/router-plugin';
import { SpecificRadioSuccessWithSuperUser } from '@app/core/states/radio.actions';
import { Injectable } from '@angular/core';

const log = new Logger('authorization store');

export class AuthorizationStateModel {
  presets?: Preset[];
}

@State<AuthorizationStateModel>({
  name: 'authorization',
  defaults: {},
})
@Injectable()
export class AuthorizationState {
  @Selector()
  static roles(state: AuthorizationStateModel): Preset[] {
    return state.presets || [];
  }

  @Selector([RadioState, AuthState])
  static isRoleApplicable(
    state: AuthorizationStateModel,
    radioState: RadioStateModel,
    authState: AuthStateModel,
  ): boolean {
    if (AuthState.isSuperUser(authState)) {
      return false;
    }
    const currRadioId = RadioState.currentRadioId(radioState);
    return (
      RadioState.radios(radioState).find(rdx => rdx.id === currRadioId).role !==
      'customer'
    );
  }

  constructor(
    private readonly radioService: RadioService,
    private readonly store: Store,
  ) {}

  @Action(GetRolesForRadioRequest)
  getRoles(
    ctx: StateContext<AuthorizationStateModel>,
    { radioId }: GetRolesForRadioRequest,
  ) {
    const userId = this.store.selectSnapshot(AuthState.userId);
    return this.radioService.getPresetForRadio(radioId, userId).pipe(
      flatMap(data => ctx.dispatch(new GetRolesForRadioSuccess(data))),
      catchError(err => ctx.dispatch(new GetRolesForRadioFailure(err))),
    );
  }

  @Action(GetRolesForRadioSuccess)
  getRolesSuccess(
    ctx: StateContext<AuthorizationStateModel>,
    { roles }: GetRolesForRadioSuccess,
  ) {
    if (roles) {
      ctx.patchState({
        presets: roles,
      });
    } else {
      ctx.patchState({
        presets: [
          {
            id: -1,
            keyString: '__noroles__',
            translationKey: 'NOROLES',
          },
        ],
      });
    }
  }

  @Action(SpecificRadioSuccess)
  specificRadioSuccess(
    ctx: StateContext<AuthorizationStateModel>,
    { radio }: SpecificRadioSuccess,
  ) {
    const isSuperUser = this.store.selectSnapshot(AuthState.isSuperUser);
    if (!isSuperUser && radio.status === 'inactive') {
      ctx.dispatch(
        new Navigate([`/radio/${radio.id}/disabled`], {}, { replaceUrl: true }),
      );
      return;
    } else {
      ctx.dispatch(new SpecificRadioSuccessWithSuperUser(radio, isSuperUser));
    }
  }
}
