import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rk-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {
  @Input()
  errorMessage: string;

  @Input()
  classes: string;

  constructor() {}

  ngOnInit() {}
}
