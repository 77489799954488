import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'rk-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  @ViewChild('inputRef', { static: true })
  inputRef: ElementRef<HTMLInputElement>;

  @Input()
  disabled: boolean;

  @Input()
  type = 'media';

  @Output()
  focus: EventEmitter<any> = new EventEmitter();

  @Output()
  blur: EventEmitter<any> = new EventEmitter();

  @Output()
  input: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  close: EventEmitter<any> = new EventEmitter();

  showCross = false;
  focused = false;

  @Input()
  autoFocus = false;

  @Input()
  set value(val: string) {
    if (this.inputRef) {
      this.inputRef.nativeElement.value = val;
    }
  }
  get value() {
    if (this.inputRef) {
      return this.inputRef.nativeElement.value;
    }
    return '';
  }
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.inputRef && this.autoFocus) {
      this.inputRef.nativeElement.focus();
    }
  }

  onClear() {
    this.inputRef.nativeElement.value = '';
    this.onChange(undefined);
    if (this.type !== 'media') {
      this.close.emit();
    }
  }

  onFocus(event: Event) {
    this.focused = true;
    this.focus.emit(event);
    this.showCross = true;
  }

  onFocusOut() {
    this.showCross = false;
  }

  onBlur(event: Event) {
    this.focused = false;
    this.blur.emit(event);
  }

  onChange(event: Event): void {
    const str = this.inputRef.nativeElement.value;
    this.input.emit(str);
  }

  getClasses(): any {
    return {
      [this.type]: true,
      focused: this.focused,
    };
  }
}
