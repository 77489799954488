<div class="question-mark" [class.no-mark]="aroundContent">
  <mat-icon
    *ngIf="!aroundContent; else noQuestionMark"
    fontSet="fa"
    fontIcon="fa-question"
  ></mat-icon>
  <div class="tooltip" [class.disabled]="disabled">
    <span [style.margin-top.px]="customMarginTop"> {{ text }} </span>
  </div>
</div>

<ng-template #noQuestionMark>
  <ng-content></ng-content>
</ng-template>
