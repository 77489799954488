import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { interval, Observable } from 'rxjs';
import { RadioState } from '@app/core/states/radio.state';
import { Radio, ShortRadio } from '@app/core/models/Radio';
import { LightApiTrack } from '@app/core/models/LightTrack';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Logout } from '@app/core/states/auth.actions';
import { AuthState } from '@app/core/states/auth.state';
import { filter } from 'rxjs/operators';
import { GetPreviousTrack } from '@app/core/states/live-tracking.actions';

@Component({
  selector: 'rk-no-mobile',
  templateUrl: './no-mobile.component.html',
  styleUrls: ['./no-mobile.component.scss'],
})
export class NoMobileComponent implements OnInit {
  @Select(LiveTrackingState.isBroadcasting)
  isRadioStarted$: Observable<boolean>;

  @Select(RadioState.currentRadio)
  radio$: Observable<Radio>;

  @Select(RadioState.radios)
  radios$: Observable<ShortRadio[]>;

  @Select(LiveTrackingState.listeners)
  listeners$: Observable<number>;

  @Select(LiveTrackingState.previousTrack)
  previousTrack$: Observable<LightApiTrack>;

  @Select(LiveTrackingState.currentTrack)
  currentTrack$: Observable<LightApiTrack>;

  @Select(LiveTrackingState.nextTrack)
  nextTrack$: Observable<LightApiTrack>;

  @Select(AuthState.currentProfilePic)
  profilePic$: Observable<string>;

  @Select(AuthState.currentUsername)
  username$: Observable<string>;

  constructor() {}

  ngOnInit() {
    if (window.innerWidth < 767) {
      this.previousTrack();
    }
    interval(30000)
      .pipe(filter(() => window.innerWidth < 767))
      .subscribe(() => this.previousTrack());
  }

  @Dispatch()
  previousTrack() {
    return new GetPreviousTrack();
  }

  @Dispatch()
  setRadio(idRadio: number) {
    return new SwitchToRadioRequest(idRadio);
  }

  @Dispatch()
  logout() {
    return new Logout();
  }
}
