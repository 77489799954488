<rk-input-with-suggestions
  [label]="label"
  (submit)="addTag($event)"
  (change)="searchData($event)"
  [isLoadingData]="isLoadingData"
  [debounceTime]="debounceTime"
  [canAddNew]="canAddNew"
  [addNewCaseSensitive]="addNewCaseSensitive"
  [minLengthTrigger]="minLengthTrigger"
  [dataFetched]="filteredData"
  [placeholder]="placeholder || ''"
  [disabled]="!canAddNewChips() || isDisabled"
  [inModal]="modal"
  [maxInputLength]="chipMaxLength"
  [hintLabel]="hint"
  [isTag]="isTag"
></rk-input-with-suggestions>
<div class="tags">
  <rk-tag
    *ngFor="let tag of chips"
    [label]="tag.translation ? (tag.translation | translate) : tag.name"
    [color]="tag.color"
    (remove)="removeTag(tag)"
  ></rk-tag>
</div>
