import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rk-audio-loader',
  templateUrl: './audio-loader.component.html',
  styleUrls: ['./audio-loader.component.scss'],
})
export class AudioLoaderComponent {
  @Input()
  size = 20;

  @Input()
  color = 'black';
}
