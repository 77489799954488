import { State, Action, StateContext, Selector, NgxsOnInit, Store } from '@ngxs/store';
import { Logger } from '@radioking/shared/logger';
import { PlayerService } from '@app/core/services/player.service';
import {
  End,
  PlayAudio,
  PlayError,
  PlayEvent,
  PlayRadio,
  Resume,
  Stop,
} from '@app/core/states/audio.actions';
import { skip } from 'rxjs/operators';
import { Track } from '@app/core/models/Track';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';

export interface AudioStateModel {
  isPlaying: boolean;
  /*
  0: no audio
  1: radio
  2: audio file
   */
  audioType: 0 | 1 | 2;
  isLoadingAudio: boolean;
  currentFilePlaying?: string;
  currentTrackPlaying: Track;
}

const log = new Logger('audio store');

@State<AudioStateModel>({
  name: 'audio',
  defaults: {
    isPlaying: false,
    audioType: 0,
    currentFilePlaying: '',
    isLoadingAudio: false,
    currentTrackPlaying: null,
  },
})
@Injectable()
export class AudioState {
  @Selector()
  static isRadioPlayerCurrent(state: AudioStateModel): boolean {
    return state.audioType === 1;
  }

  @Selector()
  static isAudioPlayerCurrent(state: AudioStateModel): boolean {
    return state.audioType === 2;
  }

  @Selector()
  static isAudioLoading(state: AudioStateModel): boolean {
    return state.isLoadingAudio;
  }

  @Selector()
  static isPlaying(state: AudioStateModel): boolean {
    return state.isPlaying;
  }

  @Selector()
  static currentTrackPlaying(state: AudioStateModel): Track {
    return state.currentTrackPlaying;
  }

  constructor(private readonly player: PlayerService, private readonly store: Store) {
    this.player
      .onPlay()
      .pipe(skip(1))
      .subscribe(() => this.store.dispatch(new PlayEvent()));
    this.player
      .onPause()
      .pipe(skip(1))
      .subscribe(() => this.store.dispatch(new Stop()));
    this.player
      .onEnd()
      .pipe(skip(1))
      .subscribe(() => this.store.dispatch(new End()));
    /*
    this.player.onStop()
      .pipe(
        skip(1),
      ).subscribe(() => this.store.dispatch(new Stop()));
      */
  }

  @Action(PlayEvent)
  playEventLoaded(ctx: StateContext<AudioStateModel>, {}: PlayEvent) {
    ctx.patchState({
      isLoadingAudio: false,
      isPlaying: true,
    });
  }

  @Action(PlayRadio)
  playRadio(ctx: StateContext<AudioStateModel>, { radioUrl }: PlayRadio) {
    this.player.listenToRadio(radioUrl);
    ctx.patchState({
      audioType: 1,
      isPlaying: false,
      currentFilePlaying: radioUrl,
      isLoadingAudio: true,
      currentTrackPlaying: null,
    });
  }

  @Action(PlayAudio)
  playAudio(ctx: StateContext<AudioStateModel>, { track }: PlayAudio) {
    this.player.listenToAudioFile(track.file);
    ctx.patchState({
      audioType: 2,
      isPlaying: false,
      currentFilePlaying: track.file,
      currentTrackPlaying: track,
      isLoadingAudio: true,
    });
  }

  @Action(Stop)
  stopRadio(ctx: StateContext<AudioStateModel>, {}: Stop) {
    log.debug('Hey, stop radio');
    if (ctx.getState().audioType !== 1) {
      return;
    }
    this.player.pauseMusic();
    ctx.patchState({
      isPlaying: false,
      audioType: 0,
      currentFilePlaying: '',
    });
  }

  @Action(PlayError)
  onError(ctx: StateContext<AudioStateModel>) {
    ctx.patchState({
      isPlaying: false,
      audioType: 0,
      currentFilePlaying: '',
    });
  }

  @Action(Stop)
  stopAudio(ctx: StateContext<AudioStateModel>, {}: Stop) {
    log.debug('Hey, stop audio');
    if (ctx.getState().audioType !== 2) {
      return;
    }
    if (!ctx.getState().isPlaying) {
      return;
    }
    this.player.pauseMusic();
    ctx.patchState({
      isPlaying: false,
    });
  }

  @Action(Resume)
  resumeAudio(ctx: StateContext<AudioStateModel>, {}: Resume) {
    if (ctx.getState().audioType !== 2) {
      return;
    }
    this.player.resume();
    ctx.patchState({
      isPlaying: true,
    });
  }

  @Action(End)
  endAudio(ctx: StateContext<AudioStateModel>) {
    ctx.patchState({
      isPlaying: false,
    });
  }
}
