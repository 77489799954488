import { Bac } from '@app/library/models/bac.model';
import { Tag, Track, TrackTags } from '@app/core/models/Track';
import { ImgUrlApi, TrackApiModel } from '@app/library/services/track.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Playlist } from '@app/library/models/playlist.model';

const tag = '[Tracks]';

export class TracksRequest {
  static readonly type = `${tag} request tracks`;
  constructor(public bac: Bac, public forceNoFilter: boolean = false) {}
}

export class TracksSuccess {
  static readonly type = `${tag} success tracks`;
  constructor(
    public tracks: Track[],
    public name: string,
    public isFiltered: boolean = false,
  ) {}
}

export class TracksFailure {
  static readonly type = `${tag} error tracks`;
  constructor(public error: Error) {}
}

export class TrackTagsRequest {
  static readonly type = `${tag} request track by ID`;
  constructor(public track: Track) {}
}

export class TrackTagsSuccess {
  static readonly type = `${tag} success track by ID`;
  constructor(public track: Track, public idBox: number) {}
}

export class TrackTagsFailure {
  static readonly type = `${tag} error track by ID`;
  constructor(public error: Error) {}
}

export class TracksUploaded {
  static readonly type = `${tag} track uploaded`;
  constructor(public track: Track, public box: string, public playlist: Playlist) {}
}

export class UpdateTrackFilter {
  static readonly type = `${tag} track updated`;
  constructor(public track: Track, public box: string) {}
}

export class TrackDeleteRequest {
  static readonly type = `${tag} request delete tracks`;

  constructor(public tracksId: number[], public bac: Bac) {}
}

export class TrackDeleteSuccess {
  static readonly type = `${tag} success delete tracks`;

  constructor(
    public tracksId: number[],
    public bac: Bac,
    public translateParams: object,
  ) {}
}

export class TrackDeleteMultiBacSuccess {
  static readonly type = `${tag} success delete tracks from multiple bac`;

  constructor(public tracksId: number[]) {}
}

export class TrackDeleteFailure {
  static readonly type = `${tag} error delete tracks`;

  constructor(public error: Error) {}
}

export class TrackMoveRequest {
  static readonly type = `${tag} request move tracks`;

  constructor(public tracksId: number[], public targetBac: Bac) {}
}

export class TrackMoveSuccess {
  static readonly type = `${tag} success move tracks`;

  constructor(
    public tracksId: number[],
    public targetBac: Bac,
    public translateParams: object,
  ) {}
}

export class TrackMoveFailure {
  static readonly type = `${tag} error move tracks`;
  constructor(public error: Error) {}
}

export class TrackEditRequest {
  static readonly type = `${tag} request edit track`;
  constructor(public tracksId: number[], public track: Partial<TrackApiModel>) {}
}

export class TrackEditSuccess {
  static readonly type = `${tag} success edit track`;
  constructor(public tracks: TrackApiModel[], public translateParams: object) {}
}

export class TrackEditFailure {
  static readonly type = `${tag} failure edit track`;
  constructor(public error: HttpErrorResponse) {}
}

export class BacNewCount {
  static readonly type = `${tag} new bac tracks count`;
  constructor(public trackNmb: number, public bac: Bac) {}
}

export class TrackCoverSingleSetRequest {
  static readonly type = `${tag} request setting cover for track`;

  constructor(public track: Track, public file: File) {}
}

export class TrackCoverSingleSetSuccess {
  static readonly type = `${tag} success setting cover for track`;

  constructor(public track: Track, public imgUrl: string) {}
}

export class TrackCoverSingleSetFailure {
  static readonly type = `${tag} error setting cover for track`;

  constructor(public error: Error) {}
}

export class TrackCoverMultipleSetRequest {
  static readonly type = `${tag} request settings cover for multiple tracks`;

  constructor(public tracks: Track[], public file: File) {}
}

export class TrackCoverMultipleSetSuccess {
  static readonly type = `${tag} success settings cover for multiple tracks`;

  constructor(public tracks: Track[], public tracksCover: ImgUrlApi[]) {}
}

export class TrackCoverMultipleSetFailure {
  static readonly type = `${tag} error settings cover for multiple tracks`;

  constructor(public error: Error) {}
}

export class TrackCoverDeleteRequest {
  static readonly type = `${tag} request deleting cover for track`;

  constructor(public track: Track) {}
}

export class TrackCoverDeleteSuccess {
  static readonly type = `${tag} success deleting cover for track`;

  constructor(public track: Track, public imgUrl: string) {}
}

export class TrackCoverDeleteFailure {
  static readonly type = `${tag} error deleting cover for track`;

  constructor(public error: Error) {}
}

export class TrackCoverLookupRequest {
  static readonly type = `${tag} request find a cover for the track`;

  constructor(public track: Track) {}
}

export class TrackCoverLookupSuccess {
  static readonly type = `${tag} success find a cover for the track`;

  constructor(public track: Track, public imgUrl: string) {}
}

export class TrackCoverLookupFailure {
  static readonly type = `${tag} error find a cover for the track`;

  constructor(public error: Error) {}
}

export class TrackTagsSaveRequest {
  static readonly type = `${tag} request save track tags`;

  constructor(public id: number, public tags: Tag[]) {}
}

export class TrackTagsSaveSuccess {
  static readonly type = `${tag} success save track tags`;

  constructor(public id: number, public tags: Tag[], public translateParams: object) {}
}

export class TrackTagsSaveFailure {
  static readonly type = `${tag} error save track tags`;

  constructor(public error: Error) {}
}

export class TracksTagsSaveRequest {
  static readonly type = `${tag} request set multiple tags to tracks`;

  constructor(public tracksId: number[], public tags: Tag[]) {}
}

export class TracksTagsSaveSuccess {
  static readonly type = `${tag} success set multiple tags to radio tags`;

  constructor(public tags: Tag[], public trackTags: TrackTags[]) {}
}

export class TrackMultipleSetTagsFailure {
  static readonly type = `${tag} error set multiple tags to tracks`;

  constructor(public error: Error) {}
}

export class TrackBuyLinkLookupRequest {
  static readonly type = `${tag} request buy link`;

  constructor(public track: Track) {}
}

export class TrackBuyLinkLookupSuccess {
  static readonly type = `${tag} success buy link`;

  constructor(public track: Track, public link: string) {}
}

export class TrackBuyLinkLookupFailure {
  static readonly type = `${tag} error buy link`;

  constructor(public error: Error) {}
}

export class AllTracksForPlaylistRequest {
  static readonly type = `${tag} request all tracks`;
  constructor() {}
}

export class PlaylistOfTrackRequest {
  static readonly type = `${tag} request playlist of track`;
  constructor(public idTrack: number) {}
}

export class PlaylistOfTrackSuccess {
  static readonly type = `${tag} success playlist of track`;
  constructor(public idPlaylist: number[]) {}
}

export class PlaylistOfTrackFailure {
  static readonly type = `${tag} error playlist of track`;
  constructor(public error: Error) {}
}

export class AddDefaultTracksRequest {
  static readonly type = `${tag} request add default tracks`;
  constructor() {}
}

export class AddDefaultTracksSuccess {
  static readonly type = `${tag} success add default tracks`;
  constructor() {}
}

export class AddDefaultTracksFailure {
  static readonly type = `${tag} error add default tracks`;
  constructor(public error: Error) {}
}
