import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataApiContainer } from '@app/core/models/DataApiContainer';
import { catchError, map } from 'rxjs/operators';
import { RadioApiModel, radiosUrl } from '@app/core/services/radio.service';
import {
  convertToRadio,
  convertToSettings,
  RadioAndSettings,
  RadioSettings,
  Stream,
} from '@app/core/models/Radio';
import {
  BroadcastingSettings,
  CoversSettings,
  DirectorySettings,
  GeneralSettings,
  IPBlacklist,
  PlatformSettings,
  ReportSettings,
  SecuritySettings,
  SettingsV2,
  SocialSettings,
  StreamSettings,
} from '@app/core/models/Settings';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { unwrapData } from '@app/core/rxjs/rxOperators';

export interface ApiStream {
  idstream: number;
  idradio: number;
  idprimary: number;
  format: string;
  bitrate: number;
  frequency: number;
  status: string;
  type: number;
}

export interface ApiConstraint {
  title_repeat: number;
  artist_repeat: number;
  album_repeat: number;
}

export interface ApiGeoblock {
  countries: string[];
  enabled: number;
  mode: 'allow' | 'deny';
}

interface SettingsV2ApiModel {
  weekly_report: boolean;
  public_listener_count: boolean;
}

interface IPBlacklistApiModel {
  ip: string;
}

const settingsUrl = {
  putById: (id: number) => `${environment.urls.MAIN_API}/radio/${id}`,
  settingsV2ById: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/setting`,
  logo: (id: number) => `${environment.urls.MAIN_API}/radio/${id}/logo`,
  defaultCover: (id: number) => `${environment.urls.MAIN_API}/radio/${id}/cover`,
  liveCover: (id: number) => `${environment.urls.MAIN_API}/radio/${id}/cover/live`,
  platformHeader: (id: number) => `${environment.urls.MAIN_API}/radio/${id}/header`,
  blacklist: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/blacklist`,
  loginTwitter: (id: number, bearer: string) =>
    `${environment.urls.MAIN_API}/radio/${id}/twitter/authorize?token=${bearer}`,
  logoutTwitter: (id: number) => `${environment.urls.MAIN_API}/radio/${id}/twitter`,
};

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly httpClient: HttpClient) {}

  getRadioSettings(idRadio: number): Observable<RadioAndSettings> {
    return this.httpClient
      .get<DataApiContainer<RadioApiModel>>(radiosUrl.getById(idRadio))
      .pipe(
        unwrapData(),
        map(data => ({
          settings: convertToSettings(data),
          radio: convertToRadio(data),
        })),
      );
  }

  getSettingsV2(idRadio: number): Observable<SettingsV2> {
    return this.httpClient
      .get<SettingsV2ApiModel>(settingsUrl.settingsV2ById(idRadio))
      .pipe(
        map(data => {
          return this.convertToSettingsV2(data);
        }),
      );
  }

  getIPBlacklist(idRadio: number): Observable<IPBlacklist[]> {
    return this.httpClient
      .get<IPBlacklistApiModel[]>(settingsUrl.blacklist(idRadio))
      .pipe(
        map(data => {
          return this.convertToIPBlacklistTab(data);
        }),
      );
  }

  updateRadioLogo(id: number, file: File): Observable<void> {
    return this.sendFile(settingsUrl.logo(id), file);
  }

  updateRadioPlatformHeader(id: number, file: File): Observable<void> {
    return this.sendFile(settingsUrl.platformHeader(id), file);
  }

  deleteRadioPlatformHeader(id: number): Observable<void> {
    return this.deleteFile(settingsUrl.platformHeader(id));
  }

  updateRadioDefaultCover(id: number, file: File): Observable<void> {
    return this.sendFile(settingsUrl.defaultCover(id), file);
  }

  deleteRadioDefaultCover(id: number): Observable<void> {
    return this.deleteFile(settingsUrl.defaultCover(id));
  }

  updateRadioLiveDefaultCover(id: number, file: File): Observable<void> {
    return this.sendFile(settingsUrl.liveCover(id), file);
  }

  deleteRadioLiveDefaultCover(id: number): Observable<void> {
    return this.deleteFile(settingsUrl.liveCover(id));
  }

  private sendFile(url: string, file: File): Observable<void> {
    if (typeof file === 'string') {
      return of();
    }
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(url, formData).pipe(map(() => {}));
  }

  private deleteFile(url: string): Observable<void> {
    return this.httpClient.delete(url).pipe(map(() => {}));
  }

  private doRequestAndGetSettings(
    idRadio: number,
    payload: any,
  ): Observable<RadioSettings> {
    return this.httpClient
      .put<DataApiContainer<RadioApiModel>>(settingsUrl.putById(idRadio), payload)
      .pipe(
        unwrapData(),
        map(data => convertToSettings(data)),
      );
  }
  private doRequestAndGetSettingsAndRadio(
    idRadio: number,
    payload: any,
  ): Observable<RadioAndSettings> {
    return this.httpClient
      .put<DataApiContainer<RadioApiModel>>(settingsUrl.putById(idRadio), payload)
      .pipe(
        unwrapData(),
        map(data => ({
          settings: convertToSettings(data),
          radio: convertToRadio(data),
        })),
      );
  }

  updateRadioCoverAndLiveSettings(
    idRadio: number,
    { coverConfig, liveTitle: { artist, buyLink, isEnabled, title } }: CoversSettings,
  ): Observable<RadioSettings> {
    const requestPayload = {
      coverconfig: coverConfig,
      live_title: {
        artist,
        title,
        buy_link: buyLink,
        status: isEnabled ? 'active' : 'inactive',
      },
    };
    return this.doRequestAndGetSettings(idRadio, requestPayload);
  }

  updateRadioGeneralSettings(
    idRadio: number,
    settings: GeneralSettings,
  ): Observable<RadioAndSettings> {
    const requestPayload = {
      name: settings.name,
      slogan: settings.slogan,
      description: settings.description,
      website: settings.website,
      lang: settings.lang.code,
      country: settings.country.code,
      timezone: settings.timezone.code,
      genres: settings.genres.map(genre => genre.id),
    };
    return this.doRequestAndGetSettingsAndRadio(idRadio, requestPayload);
  }

  updateRadioBroadcastingSettings(
    idRadio: number,
    settings: BroadcastingSettings,
  ): Observable<RadioSettings> {
    const requestPayload = {
      constraint: {
        artist_repeat: settings.constraints.artistRepeat,
        title_repeat: settings.constraints.titleRepeat,
      },
      fadeout_default: settings.fadeoutDefault,
      auto_cue_enabled: settings.autoCueEnabled,
      mix_threshold: settings.mixThreshold,
      norm_threshold: settings.normThreshold,
    };
    return this.doRequestAndGetSettings(idRadio, requestPayload);
  }

  updateRadioStreamSettings(
    idRadio: number,
    settings: StreamSettings,
  ): Observable<RadioSettings> {
    const streams = settings.streams.map(stream => {
      return this.convertToApiStream(
        stream.type === 2
          ? settings.lowQualityStream
          : stream.type === 3
          ? settings.highQualityStream
          : stream,
      );
    });
    const requestPayload = {
      streams,
    };
    return this.doRequestAndGetSettings(idRadio, requestPayload);
  }

  updateRadioPlatformSettings(
    idRadio: number,
    settings: PlatformSettings,
  ): Observable<RadioSettings> {
    const requestPayload = {
      public: settings.appearOnPlatform,
      comments_enabled: settings.commentsEnabled,
    };
    return this.doRequestAndGetSettings(idRadio, requestPayload);
  }

  updateRadioDirectorySettings(
    idRadio: number,
    settings: DirectorySettings,
  ): Observable<RadioSettings> {
    const requestPayload = {
      directory: {
        idradio: idRadio,
        icecast: settings.appearIcecast,
        orange: settings.appearOrange,
        radioline: settings.appearRadioline,
      },
    };
    return this.doRequestAndGetSettings(idRadio, requestPayload);
  }

  updateRadioSocialSettings(
    idRadio: number,
    settings: SocialSettings,
  ): Observable<RadioSettings> {
    const requestPayload = {
      facebook: settings.facebookPage,
      livefeed_settings: {
        facebook_enabled: settings.facebookLivefeed,
        twitter_enabled: settings.twitterLivefeed,
      },
      tweet: {
        enabled: settings.tweetEnabled,
        message: settings.tweetMessage,
        repeat: settings.tweetRepeat,
        cover: settings.tweetCover,
      },
      itunes_affiliate_id: settings.iTunesAffiliateID,
    };
    return this.doRequestAndGetSettings(idRadio, requestPayload);
  }

  loginTwitter(idRadio: number, bearer: string): Observable<any> {
    return this.httpClient.get(settingsUrl.loginTwitter(idRadio, bearer));
  }

  logoutTwitter(idRadio: number): Observable<boolean> {
    return this.httpClient
      .delete<DataApiContainer<RadioApiModel>>(settingsUrl.logoutTwitter(idRadio))
      .pipe(
        map(data => {
          if (data.status === 'success') {
            return true;
          }
          throw new Error('Error Logout Twitter');
        }),
      );
  }

  updateRadioSecuritySettings(
    idRadio: number,
    settings: SecuritySettings,
  ): Observable<RadioSettings> {
    const requestPayload = {
      disconnect_listeners_after: settings.disconnectAfter,
      geoblock: {
        countries: settings.countries,
        enabled: settings.geoblockEnabled,
        mode: settings.mode,
      },
    };
    return this.doRequestAndGetSettings(idRadio, requestPayload);
  }

  updateRadioIPBlacklistSettings(
    idRadio: number,
    blacklist: IPBlacklist[],
  ): Observable<boolean> {
    const requestPayload = { blacklist };
    return this.httpClient
      .post<DataApiContainer<IPBlacklistApiModel>>(
        settingsUrl.blacklist(idRadio),
        requestPayload,
      )
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          throw new Error('Error update IP Blacklist');
        }),
      );
  }

  updateSettingsV2(
    idRadio: number,
    settings: Partial<SettingsV2>,
  ): Observable<SettingsV2> {
    const requestPayload = {};
    if (typeof settings.weeklyReport !== 'undefined')
      requestPayload['weekly_report'] = settings.weeklyReport;
    if (typeof settings.publicListeners !== 'undefined')
      requestPayload['public_listener_count'] = settings.publicListeners;
    return this.httpClient
      .put<SettingsV2ApiModel>(settingsUrl.settingsV2ById(idRadio), requestPayload)
      .pipe(map(data => this.convertToSettingsV2(data)));
  }

  convertToSettingsV2(apiSettings: SettingsV2ApiModel): SettingsV2 {
    return {
      weeklyReport: apiSettings.weekly_report,
      publicListeners: apiSettings.public_listener_count,
    };
  }

  convertToIPBlacklistTab(apiBlacklist: IPBlacklistApiModel[]): IPBlacklist[] {
    return apiBlacklist.map(data => new IPBlacklist(data.ip));
  }

  convertToApiStream(stream: Stream): ApiStream {
    return {
      idstream: stream.idStream,
      idradio: stream.idRadio,
      idprimary: stream.idPrimary,
      format: stream.format,
      bitrate: stream.bitrate,
      frequency: stream.frequency,
      status: stream.status,
      type: stream.type,
    };
  }
}
