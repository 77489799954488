import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { RadioState } from '@app/core/states/radio.state';
import { Subscription } from 'rxjs';

interface InputCondition {
  id: number;
  condition: 'greater' | 'less' | 'equal';
}

@Directive({
  selector: '[rkHasPlan]',
})
export class HasPlanDirective implements OnInit, OnDestroy {
  @Input('rkHasPlan')
  inputCondition: InputCondition;

  get otherTemplate(): TemplateRef<any> {
    return this._otherTemplate;
  }

  @Input('rkHasPlanElse')
  set otherTemplate(value: TemplateRef<any>) {
    this._otherTemplate = value;
    this.hasNewElse = true;
  }

  private _otherTemplate: TemplateRef<any>;

  subscription: Subscription;

  isShown = false;

  hasNewElse = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.subscription = this.store.select(RadioState.currentPlanId).subscribe(id => {
      switch (this.inputCondition.condition || 'equal') {
        case 'equal': {
          if (this.inputCondition.id === id) {
            this.showContent();
          } else {
            this.hideContent();
          }
          break;
        }
        case 'greater': {
          if (id > this.inputCondition.id) {
            this.showContent();
          } else {
            this.hideContent();
          }
          break;
        }
        case 'less': {
          if (id < this.inputCondition.id) {
            this.showContent();
          } else {
            this.hideContent();
          }
          break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showContent() {
    if (this.isShown) {
      return;
    }
    this.isShown = true;
    if (this._otherTemplate) {
      this.viewContainer.clear();
    }
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  hideContent() {
    if (!this.isShown && !this.hasNewElse) {
      return;
    }
    this.isShown = false;
    this.viewContainer.clear();
    if (this._otherTemplate) {
      this.viewContainer.createEmbeddedView(this._otherTemplate);
    }
  }
}
