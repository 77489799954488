<ng-container *ngIf="!item.hidden">
  <ng-container *rkHasRole="item.roles || false">
    <ng-container *rkIsAdmin="item.isAdminRoute || false">
      <a class="nav-link" (click)="toggleOpen($event)" attr.data-navkey="{{ item.id }}">
        <!-- <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon> -->
        <mat-icon
          class="nav-link-icon"
          *ngIf="item.icon"
          fontSet="fa"
          [fontIcon]="item.icon"
        ></mat-icon>
        <span class="nav-link-title" [translate]="item.translate"></span>
        <span
          class="nav-link-badge"
          *ngIf="item.badge"
          [translate]="item.badge.translate"
          [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }"
        >
          {{ item.badge.title }}
        </span>
        <mat-icon
          class="collapse-arrow"
          fontSet="fa"
          fontIcon="fa-angle-right"
        ></mat-icon>
      </a>

      <div class="children" [@slideInOutBlock] *ngIf="isOpen">
        <ng-container *ngFor="let item of item.children">
          <rk-nav-vertical-item
            (click)="clickNavigation.emit()"
            *ngIf="item.type == 'item'"
            [item]="item"
            [radioId]="radioId"
          ></rk-nav-vertical-item>
          <rk-nav-vertical-collapse
            *ngIf="item.type == 'collapse'"
            [item]="item"
            [radioId]="radioId"
          ></rk-nav-vertical-collapse>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
