import { State, Action, StateContext, Selector } from '@ngxs/store';
import { UpdateAvailable } from './app.actions';
import { Logger } from '@radioking/shared/logger';
import { SwloggerService } from '../services/swlogger.service';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Injectable } from '@angular/core';

export interface AppStateModel {
  hasUpdateAvailable: boolean;
  historyStack: string[];
}

const log = new Logger('app store');

@State<AppStateModel>({
  name: 'app',
  defaults: {
    hasUpdateAvailable: false,
    historyStack: [],
  },
})
@Injectable()
export class AppState {
  @Selector()
  static history(state: AppStateModel): string[] {
    return state.historyStack;
  }

  @Selector()
  static hasUpdateAvailable(state: AppStateModel): boolean {
    return state.hasUpdateAvailable;
  }

  constructor(private readonly swLogger: SwloggerService) {
    this.swLogger.watchForUpdate();
  }

  @Action(UpdateAvailable)
  updateAveliable(ctx: StateContext<AppStateModel>, action: UpdateAvailable) {
    ctx.patchState({ hasUpdateAvailable: true });
  }

  @Action(RouterNavigation)
  handleNavigation(
    ctx: StateContext<AppStateModel>,
    { event, routerState }: RouterNavigation,
  ) {
    const newHistory = [...ctx.getState().historyStack, event.urlAfterRedirects];
    ctx.patchState({ historyStack: newHistory });
  }
}
