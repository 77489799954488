import { Timezone } from '@app/core/models/Timezone';

const tag = '[Timezone]';

export class TimezonesRequest {
  static readonly type = `${tag} request timezones`;

  constructor() {}
}

export class TimezonesSuccess {
  static readonly type = `${tag} success timezones`;
  constructor(public timezones: Timezone[]) {}
}

export class TimezonesFailure {
  static readonly type = `${tag} error timezones`;
  constructor(public error: Error) {}
}
