import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShoutOut } from '@app/core/models/ShoutOut';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

const shoutOutsUrls = {
  getShoutOuts: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/widget/dedication`,
  updateShoutOuts: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/widget/dedication`,
};

export interface ShoutOutApiModel {
  idradio: number;
  welcome_message: string;
  end_message: string;
  enabled: number;
  color: string;
  secondary_color: string;
  key: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShoutOutsService {
  constructor(private readonly httpClient: HttpClient) {}

  getShoutOutSettings(idRadio: number): Observable<ShoutOut> {
    return this.httpClient
      .get<ShoutOutApiModel>(shoutOutsUrls.getShoutOuts(idRadio))
      .pipe(map(data => this.convertToShoutOuts(data)));
  }

  updateShoutOutSettings(idRadio: number, shoutOut: ShoutOut): Observable<ShoutOut> {
    const payload: Partial<ShoutOutApiModel> = {
      color: shoutOut.primaryColor,
      secondary_color: shoutOut.secondaryColor,
      welcome_message: shoutOut.welcomeMessage,
      end_message: shoutOut.endMessage,
    };
    return this.httpClient
      .post<ShoutOutApiModel>(shoutOutsUrls.updateShoutOuts(idRadio), payload)
      .pipe(map(data => this.convertToShoutOuts(data)));
  }

  toggleShoutOut(idRadio: number, enabled: boolean): Observable<ShoutOut> {
    const payload = { enabled };
    return this.httpClient
      .post<ShoutOutApiModel>(shoutOutsUrls.getShoutOuts(idRadio), payload)
      .pipe(map(data => this.convertToShoutOuts(data)));
  }

  convertToShoutOuts(shoutouts: ShoutOutApiModel): ShoutOut {
    return {
      welcomeMessage: shoutouts.welcome_message,
      endMessage: shoutouts.end_message,
      enabled: shoutouts.enabled,
      primaryColor: shoutouts.color || '#1e7fcb',
      secondaryColor: shoutouts.secondary_color || '#ffffff',
      key: shoutouts.key,
    };
  }
}
