import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DataApiContainer } from '@app/core/models/DataApiContainer';

export const filterShouldBeDefined = () => <T>(source$: Observable<T>): Observable<T> =>
  source$.pipe(filter(val => typeof val !== 'undefined'));

const isArrayObjectsAndOrderSame = <T extends { id: any }>(a: T[], b: T[]): boolean => {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }
  if (a.length === 0) {
    return true;
  }
  for (let idx = 0; idx < a.length; idx++) {
    if (a[idx].id !== b[idx].id) {
      return false;
    }
  }
  return true;
};

export const distinctUntilChangedWithSideEffectOnLike = (onDifference?: () => void) => <
  T extends { id: any }
>(
  source$: Observable<T[]>,
): Observable<T[]> =>
  source$.pipe(
    distinctUntilChanged((old: T[], news: T[]) => {
      const isSame = isArrayObjectsAndOrderSame(old, news);

      if (!isSame) {
        try {
          onDifference();
        } catch (e) {}
      }
      return old === news;
    }),
  );

export const unwrapData = () => <T>(
  source$: Observable<DataApiContainer<T>>,
): Observable<T> => source$.pipe(map(data => data.data));
