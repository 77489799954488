import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, flatMap, map, switchMap, tap } from 'rxjs/operators';
import {
  AuthenticationService,
  TokenItem,
  UserData,
} from '../authentication/authentication.service';
import { Navigate } from '@ngxs/router-plugin';
import { Logger } from '@radioking/shared/logger';
import {
  LoginError,
  LoginRequest,
  Logout,
  RedirectToLogin,
  RequestUser,
  SetTokens,
  SetUser,
} from './auth.actions';
import { environment } from '@env/environment';
import { MyRadiosRequest, UseSpecificRadio } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { Observable, of } from 'rxjs';
import { I18nService } from '@app/core/services/i18n.service';
import { Intercom } from 'ng-intercom';
import { HelpHeroService, IntercomHiderService } from '@radioking/shared/common-services';
import * as Sentry from '@sentry/browser';
import * as amplitude from 'amplitude-js';
import { Injectable } from '@angular/core';

export interface AuthStateModel {
  isLoggingIn: boolean;
  user: {
    username: string;
    email: string;
    id: number;
    imageUrl?: string;
    lastRadioUsed: number;
    lang: string;
    isAdmin: boolean;
    isSuperUser: boolean;
    whmcsId: number;
    onlySocial: boolean;
  };
  auth: {
    token: string;
    expire: number;
    refreshToken: string;
  };
}
/*
  is_admin: boolean;
  is_superuser: boolean;
 */

const log = new Logger('auth store');

const defaultUser = {
  username: '',
  email: '',
  id: -1,
  lastRadioUsed: -1,
  lang: 'us-US',
  isSuperUser: false,
  isAdmin: false,
  whmcsId: -1,
  onlySocial: false,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    auth: {
      token: '',
      refreshToken: '',
      expire: -1,
    },
    user: defaultUser,
    isLoggingIn: false,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.auth.token;
  }

  @Selector()
  static isLogging(state: AuthStateModel): boolean {
    return state.isLoggingIn;
  }

  @Selector()
  static currentUsername(state: AuthStateModel): string {
    return state.user.username;
  }

  @Selector()
  static currentEmail(state: AuthStateModel): string {
    return state.user.email;
  }

  @Selector()
  static currentProfilePic(state: AuthStateModel): string {
    return state.user.imageUrl;
  }

  @Selector()
  static getAccessToken(state: AuthStateModel): string {
    return state.auth.token;
  }

  @Selector()
  static getRefreshToken(state: AuthStateModel): string {
    return state.auth.refreshToken;
  }

  @Selector()
  static isSuperUser(state: AuthStateModel): boolean {
    return state.user.isSuperUser;
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    return state.user.isAdmin;
  }

  @Selector()
  static userId(state: AuthStateModel): number {
    return state.user.id;
  }

  @Selector()
  static userWHMCSId(state: AuthStateModel): number {
    return state.user.whmcsId;
  }

  @Selector()
  static userOnlySocial(state: AuthStateModel): boolean {
    return state.user.onlySocial;
  }

  @Selector()
  static upgradeOfferLink(state: AuthStateModel): string {
    return `${environment.urls.WHMCS}/upgrade.php?type=package&id=${state.user.whmcsId}`;
  }

  @Selector()
  static userLang(state: AuthStateModel): string {
    return state.user.lang;
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
    private readonly i18nService: I18nService,
    private readonly intercom: Intercom,
    private readonly intercomHiderService: IntercomHiderService,
    private readonly helpHeroService: HelpHeroService,
  ) {}

  @Action(RequestUser)
  requestUser(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ isLoggingIn: true });
    return this.authenticationService.getMe().pipe(
      map((user: UserData) => {
        ctx.dispatch(new SetUser(user, false));
        return user;
      }),
      tap(user => {
        // This is a global script
        // @ts-ignore
        Userback.email = user.email;
        // @ts-ignore
        Userback.custom_data = {
          iduser: user.iduser,
          username: user.username,
          firstname: user.firstname,
          lastname: user.lastname,
          idradio: user.lastRadioUsed,
        };
        // @ts-ignore
        Userback.widget_settings = {
          language: user.lang.substr(0, 2),
        };
      }),
      switchMap(() => this.getUserRadio(ctx)),
      catchError(() => {
        return ctx.dispatch(new Logout());
      }),
    );
  }

  @Action(SetUser)
  setUsername(ctx: StateContext<AuthStateModel>, { user, isRedirection }: SetUser) {
    ctx.patchState({
      user: {
        username: user.username,
        email: user.email,
        id: user.iduser,
        imageUrl: user.idfile_avatar
          ? `${environment.urls.MAIN_API}/track/cover/${user.idfile_avatar}`
          : undefined,
        lastRadioUsed: user.lastRadioUsed,
        lang: user.lang,
        isSuperUser: user.is_superuser === 1,
        isAdmin: user.is_admin,
        whmcsId: user.idwhmcs,
        onlySocial: user.only_social,
      },
      isLoggingIn: false,
    });

    let name = user.username;

    if (user.firstname && user.lastname) {
      name = `${user.firstname} ${user.lastname}`;
    }

    amplitude.getInstance().init(environment.amplitudeApiKey, user.iduser);
    const userProperties = {
      name,
      email: user.email,
      whmcsId: user.idwhmcs,
      lang: user.lang,
    };
    amplitude.getInstance().setUserProperties(userProperties);

    this.intercom.boot({
      name,
      app_id: environment.intercomId,
      email: user.email,
      user_id: `${user.iduser}`,
      user_hash: user.intercom_hash,
      whmcs_id: user.idwhmcs,
      lang: user.lang,
      whmcs: `${environment.urls.WHMCS}/admin/clientssummary.php?userid=${user.idwhmcs}`,
    });
    this.intercomHiderService.initService();

    this.i18nService.language = user.lang;
    if (isRedirection) {
      ctx.dispatch(new Navigate(['radio', 0], {}, { replaceUrl: true }));
    }

    if (environment.sentry.isEnabled) {
      Sentry.setUser({
        id: `${user.iduser}`,
        email: user.email,
        username: user.username,
      });
    }
  }

  @Action(SetTokens)
  setTokens(
    ctx: StateContext<AuthStateModel>,
    { token, expire, refreshToken }: SetTokens,
  ) {
    ctx.patchState({ auth: { token, expire, refreshToken: refreshToken } });
  }

  @Action(LoginError)
  loginError(ctx: StateContext<AuthStateModel>, { error }: LoginError) {
    ctx.patchState({ isLoggingIn: false });
    log.warn('An error was found during loggin : ', error);
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, state: Logout) {
    return this.authenticationService.logout().pipe(
      catchError(() => of()),
      flatMap(() => ctx.dispatch(new RedirectToLogin())),
    );
  }

  @Action(RedirectToLogin)
  redirectToLogin(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      user: defaultUser,
      auth: {
        refreshToken: '',
        expire: 0,
        token: '',
      },
      isLoggingIn: false,
    });
    if (environment.auth.isOnManagerLoginEnable) {
      return ctx.dispatch(new Navigate(['/login'], {}, { replaceUrl: true }));
    }
    document.location.href = environment.auth.redirectUrl;
    return;
  }

  @Action(LoginRequest)
  loginRequest(ctx: StateContext<AuthStateModel>, { login, password }: LoginRequest) {
    ctx.patchState({ isLoggingIn: true });
    return this.authenticationService.login({ password, username: login }).pipe(
      map((tok: TokenItem) =>
        ctx.dispatch(new SetTokens(tok.access_token, tok.expire, tok.refresh_token)),
      ),
      switchMap(() => this.authenticationService.getMe()),
      switchMap((user: UserData) => ctx.dispatch(new SetUser(user, true))),
      switchMap(() => this.getUserRadio(ctx)),
      catchError(error => ctx.dispatch(new LoginError(error))),
    );
  }

  @Action(UseSpecificRadio)
  setCurrentRadio(ctx: StateContext<AuthStateModel>, { idRadio }: UseSpecificRadio) {
    this.helpHeroService.registerUser(ctx.getState().user, idRadio);
    return this.authenticationService.useSpecificRadio(idRadio);
  }

  getUserRadio(ctx: StateContext<AuthStateModel>): Observable<any> {
    const userId = ctx.getState().user.id;
    return of('').pipe(
      switchMap(() => ctx.dispatch(new MyRadiosRequest(userId))),
      switchMap(() => {
        const radios = this.store.selectSnapshot(RadioState.radios);
        if (radios.length === 0) {
          return ctx.dispatch(new Navigate(['/no-radio'], {}, { replaceUrl: true }));
        }
        let lastRadioId = ctx.getState().user.lastRadioUsed;
        const routeRadio = window.location.pathname.split('/');
        const radioIndex = routeRadio.indexOf('radio');
        if (radioIndex > -1 && routeRadio.length > radioIndex + 1) {
          lastRadioId = parseInt(routeRadio[radioIndex + 1], 10);
        }
        const foundRadio = radios.find(radio => radio.id === lastRadioId);
        if (foundRadio) {
          return ctx.dispatch(new UseSpecificRadio(foundRadio.id));
        } else {
          if (ctx.getState().user.isSuperUser && lastRadioId !== 0) {
            return ctx.dispatch(new UseSpecificRadio(lastRadioId));
          }
        }
        return ctx.dispatch(new UseSpecificRadio(radios[0].id));
      }),
    );
  }
}
