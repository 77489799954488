import { Country } from '@app/core/models/Country';
import { Timezone } from '@app/core/models/Timezone';
import { Genre } from '@app/core/models/Genre';
import { Constraints, Stream } from '@app/core/models/Radio';

export interface GeneralSettings {
  name: string;
  slogan?: string;
  description?: string;
  website?: string;
  lang: {
    name: string;
    code: string;
  };
  country: Country;
  timezone: Timezone;
  genres: Genre[];
}

export interface BroadcastingSettings {
  constraints: Constraints;
  fadeoutDefault: number;
  autoCueEnabled: boolean;
  mixThreshold: number;
  normThreshold: number;
}

export interface StreamSettings {
  lowQualityStream: Stream;
  highQualityStream: Stream;
  streams: Stream[];
}

export interface PlatformSettings {
  appearOnPlatform: boolean;
  commentsEnabled: boolean;
}

export interface DirectorySettings {
  appearOrange: boolean;
  appearIcecast: boolean;
  appearRadioline: boolean;
}

export interface SocialSettings {
  facebookPage: string;
  facebookLivefeed: boolean;
  twitterLivefeed: boolean;
  tweetEnabled: boolean;
  tweetRepeat: number;
  tweetMessage: string;
  tweetCover: boolean;
  iTunesAffiliateID: string;
}

export interface SecuritySettings {
  disconnectAfter: number;
  geoblockEnabled: boolean;
  mode: 'allow' | 'deny';
  countries: string[];
  blacklist: IPBlacklist[];
}

export interface CoversSettings {
  coverDefault?: string;
  coverConfig: string;
  liveTitle: {
    artist: string;
    isEnabled: boolean;
    title: string;
    buyLink: string;
    cover?: string;
  };
  fileCover?: File;
  fileCoverRemoved?: boolean;
  fileDefaultLive?: File;
  fileDefaultLiveRemoved?: boolean;
}

export interface ReportSettings {
  weeklyReport: number;
}

export interface SettingsV2 {
  weeklyReport: boolean;
  publicListeners: boolean;
}

export class IPBlacklist {
  public constructor(public ip: string) {}
}
