import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Directive({
  selector: '[rkSort]',
})
export class SortDirective {
  @Input()
  set predicate(predi: string) {
    this._predicate = predi;
    if (predi === 'none') {
      this.resetClasses();
    }
    this.predicateChildren.next(predi);
  }

  _predicate: string;

  get predicate(): string {
    return this._predicate;
  }

  @Input()
  ascending: boolean;

  @Input()
  callback: Function;

  sortIcon = 'nosort';
  sortAscIcon = 'fa-angle-up';
  sortDescIcon = 'fa-angle-down';
  sortIconSelector = 'span.fa';

  @Output()
  predicateChange: EventEmitter<any> = new EventEmitter();

  @Output()
  ascendingChange: EventEmitter<any> = new EventEmitter();

  private predicateChildren = new ReplaySubject<string>();

  element: any;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  getObservablePredicate(): Observable<string> {
    return this.predicateChildren.asObservable();
  }

  sort(field: any) {
    this.resetClasses();
    if (field !== this._predicate) {
      this.ascending = true;
    } else {
      this.ascending = !this.ascending;
    }
    this._predicate = field;
    this.predicateChange.emit(field);
    this.ascendingChange.emit(this.ascending);
    this.callback();
  }

  private resetClasses() {
    const allThIcons = this.element.querySelectorAll(this.sortIconSelector);
    // Use normal loop instead of forEach because IE does not support forEach on NodeList.
    for (let i = 0; i < allThIcons.length; i = i + 1) {
      allThIcons[i].classList.remove(this.sortAscIcon);
      allThIcons[i].classList.remove(this.sortDescIcon);
      allThIcons[i].classList.add(this.sortIcon);
    }
  }
}
